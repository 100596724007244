import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigurationService } from '../configuration.service';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class JurorprofileService {
  private _baseURL: string;

  constructor(private _http: HttpClient, private _configuration: ConfigurationService) {
    this._baseURL = _configuration.baseURL;
  }

  GetJurorProfileInfo(JurorID: any): Observable<any> {
    return this._http.get(this._baseURL + 'DashboardApi/GetJurorProfileInfo?jurorID=' + JurorID)
        .pipe(catchError(this.errorHandler));
  }

  UpdateAddress(model: any): Observable<any> {
    return this._http.post(this._baseURL + 'DashboardApi/JurorUpdateAddress', model)
        .pipe(catchError(this.errorHandler));
  }
  GetJurorQuestionnaire(JurorID: any): Observable<any> {
    return this._http.get(this._baseURL + 'JurorApi/GetJurorQuestionnaire?jurorAccountID=' + JurorID)
        .pipe(catchError(this.errorHandler));
  }
  
  errorHandler(error: Response) {
    return Observable.throw(error);
  }
}
