import { Component, OnInit } from '@angular/core';
import { NgForm, FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute, Data } from '@angular/router';
import { Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from './../../configuration.service';
import { Onboardingstep2Service } from './onboardingstep2.service';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';

@Component({
  selector: 'app-onboardingstep2',
  templateUrl: './onboardingstep2.component.html',
  styleUrls: ['./onboardingstep2.component.css']
})
export class Onboardingstep2Component implements OnInit {
public baseUrl=this.configurationservice.baseURL;
  updatedJurisID: number = 0;
  dataValues;
  step2Data;
  StagingTableName;
  CreateTableScript;
  dataParams;
  userName:any;
  index;
  isLoader: boolean;

  constructor(private _fb: FormBuilder,
      private _avRoute: ActivatedRoute, public http:HttpClient, private _router: Router,
    private _onboardingstep2Service: Onboardingstep2Service,
    private configurationservice: ConfigurationService, private adalSvc: MsAdalAngular6Service) {
     
  }
  ngOnInit() {
    this.isLoader =true;
      this.updatedJurisID = this._avRoute.snapshot.params['id'];
      if (this.updatedJurisID != 0 || this.updatedJurisID != null) {
         
          this.dataValues = {
              jurisdictionID: this.updatedJurisID,
              stepNo: 2
          };
          this.isLoader =true;
          this._onboardingstep2Service.GetJurisdictionDetails(this.dataValues).subscribe(response => {
              if (response.success == true) {
                this.isLoader =false;
                  this.step2Data = response.listJurisdictions[0];
                  this.StagingTableName = this.step2Data.stagingTableName;
                  this.CreateTableScript = this.step2Data.createTableScript;
              }
          });
      }
    //this.userName=localStorage.getItem("UserName");
    this.userName = this.adalSvc.LoggedInUserName;

  }
  addScript(val) {
     // console.log(val);
      this.CreateTableScript = "CREATE TABLE [" + val + "] (        ) ";
     // console.log(this.CreateTableScript);
  }

  CreateTable(tableName, scriptData) {

      this.dataParams = {
          CreateTableScript: scriptData,
          JurisdictionID: this.updatedJurisID,
          StagingTableName: tableName,
          LoggedInUser: this.userName
      };

      this._onboardingstep2Service.TableCreateScript(this.dataParams).subscribe(data => {
          if (data.success == true) {
              this._router.navigate(['/home/onboarding-step3', this.updatedJurisID]);
              //this.showStepOne = false;
              //this.ShowStepTwo = false;
              //this.ShowStepThree = true;
             
          }
      });

  }
  backtoStepOne() {
      this._router.navigate(['/home/onboarding-step1',this.updatedJurisID]);
  }
}
