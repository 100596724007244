import { Injectable, Inject } from '@angular/core';
import{HttpClient, HttpHeaders} from '@angular/common/http';
import { NgForm, FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import{ConfigurationService} from 'src/app/configuration.service'
import { inject } from '@angular/core/testing';
import { catchError } from 'rxjs/operators';
//import { getBaseUrl } from '../../app.browser.module';
//import { ApiService } from '../shared/api.service';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
    jurisdictionID:any; // number = 3;
  
  public baseURL=this.configurationservice.baseURL;
    constructor(private http:HttpClient,private configurationservice:ConfigurationService) {
    }    
    submitData(model: any): Observable<any> {
      this.jurisdictionID=localStorage.getItem("JurisdictionID");
        return this.http.get(this.baseURL+'AdminApi/Report?EventSearchStartDate=' + model.startdate + '&EventSearchEndDate=' + model.enddate + '&JurisdictionID=' + this.jurisdictionID);
          
    }
   
    errorHandler(error: Response) {
        console.log(error);
        return Observable.throw(error);
    }
}
