import { ChangeDetectorRef, Component, NgZone, OnInit, ViewRef } from "@angular/core";
import { DatePipe } from "@angular/common";
import { CalendarService } from "../calendar/calendar.service";
import { FormBuilder, FormGroup, Validators, FormControl, MinLengthValidator } from "@angular/forms";
declare var $: any;
import * as $ from "jquery";
import { Router } from "@angular/router";
import { min } from "rxjs/operators";

@Component({
  selector: "app-calendar",
  templateUrl: "./calendar.component.html",
  styleUrls: ["./calendar.component.css"]
})
export class CalendarComponent implements OnInit {
  evntsData: any;
  obj: any;
  selectedEvents = [];
  selectedBatch = [];
  duplicatesRecord = [];
  batchEvents = [];
  FormCalendarEvent: FormGroup;
  JurisdictionID;
  LoggedinUser;
  currentDate;
  currentDateValidation: boolean = false;
  isLoaderCalendar: boolean = false;
  errorMessageTime: boolean = false;
  successMessage: boolean = false;

  constructor(private _calendarService: CalendarService, 
    private cd: ChangeDetectorRef,
    private zone:NgZone ,private _FormBuilder: FormBuilder, private _datePipe: DatePipe, private router: Router) {
    this.LoggedinUser = localStorage.getItem("UserName");
    this.JurisdictionID = localStorage.getItem("JurisdictionID");
    localStorage.setItem('RouteURL', '/home/calendar');
  }

  ngOnInit() {
    this.getcalendar();
    this.currentDate = new Date();
    (this.currentDate).setDate((this.currentDate).getDate() + 1);
   

    this.FormCalendarEvent = this._FormBuilder.group({
      eventTitle: ["", Validators.required],
      eventStartDate: ["", Validators.required],
      eventStartTime: ["08:00"],
      eventEndTime: ["10:00"],
      judgeName: [""],
      roomNumber: [""],
      caseNumber: [""],
      defendant: [""]
    });
  }

  checkcurrentDate(date) {
    const todayDate = this._datePipe.transform(new Date(), "yyyy-MM-dd");
    if (date <= todayDate) {
      this.currentDateValidation = true;
    } else {
      this.currentDateValidation = false;
    }
  }

  getcalendar() {
    var duplicates = [];
    var self = this;
    $(function () {
      $("#scheduler").kendoScheduler({
        //  date: new Date("2019/02/20"),
        height: 600,
        views: [
          "day",
          "week",         
          "workWeek",
          { type: "month", selected: true },
          "month",
          "agenda",
        ],
        //disableDates: ['Saturday', 'Sunday'],
        editable: false,        
        eventTemplate: function (e) {
          var indicate = "";
          var val = self.batchEvents.indexOf(e.batchID);
          if (val !== -1) {
            // indicate = "B"
            indicate = '<span class="glyphicon glyphicon-link"></span>';
          }
          // indicate = '<span class="glyphicon glyphicon-bold" style="color:black; font-weight:bold; font-size:20px;">B</span>';
          return (
            '<div class="movie-template" id="evntTitle">' +
            e.title +
            "&nbsp;&nbsp;&nbsp;&nbsp;" +
            "<span>" +
            indicate +
            "</span>" +
            "</div>"
          );
        },
        dataSource: {
          sync: function () {
            this.read();
          },
          batch: true,
          transport: {
            read: function (options) {
              self.isLoaderCalendar = true;
              self._calendarService
                .getCalenarEvents(self.JurisdictionID)
                .subscribe(data => {
                  self.evntsData = data.calenderEventList;
                  //console.log(self.evntsData);
                  self.obj = self.evntsData.map(data => {
                    return {
                      eventID: data.eventID,
                      title: data.title,
                      start: data.startDateTime,
                      end: data.endDateTime,
                      summons: data.summonsIssuedCount,
                      room: data.roomNumberID,
                      JudgeID: data.judgeID,
                      recurrenceId: data.recurrenceId,
                      batchID: data.batchID,
                      panel: data.juryServedCount,
                      description: "data"
                    };
                  });

                  self.duplicatesRecord = self.obj.map(x => {
                    return x.batchID;
                  });
                  var sorted_arr = self.duplicatesRecord.slice().sort();
                  for (var i = 0; i < sorted_arr.length - 1; i++) {
                    if (sorted_arr[i + 1] == sorted_arr[i]) {
                      self.batchEvents.push(sorted_arr[i]);
                    }
                  }

                  //console.log("batchEvents:=" + self.batchEvents);
                  //  console.log(self.duplicatesRecord);
                  options.success(self.obj);
                  self.isLoaderCalendar = false;
                });
            }
          }
        },
        update: function (options) {
          return true;
        },
        create: function (options) {
          return true;
        },
        destroy: function (options) {
          return true;
        },
        parameterMap: function (options, operation) {
          if (operation !== "read" && options.models) {
            return { models: options.models };
          }
        },
        schema: {
          model: {
            id: "eventID",
            fields: {
              eventID: { from: "eventID" },
              title: {
                from: "title",
                defaultValue: "No title",
                validation: { required: true }
              },
              start: { type: "date", from: "start" },
              end: { type: "date", from: "end" },
              batchID: { from: "batchID" },
              recurrenceId: {
                from: "recurrenceId"
              },
              recurrenceRule: {
                from: "RecurrenceRule"
              },
              recurrenceException: {
                from: "RecurrenceException"
              },
              JudgeName: { from: "JudgeID" },
              room: { from: "room" },
              summons: { from: "summons" },
              juryServedCount: { from: "juryServedCount" }
            }
          }
        }
      });     
    });
    var scheduler = $("#scheduler").data("kendoScheduler");
    //$("#scheduler").kendoCalendar({
    //  disableDates: ['su']
    //});
    //scheduler.setOptions({
    //  disableDates: ['su']
    //});

    $(function (e) {
      $("#scheduler").kendoTooltip({
        filter: ".k-event",
        position: "top",
        width: 250,
        content: toolTip
      });
    });
    $(function (e) {
      $("#scheduler").kendoTooltip({
        filter: ".k-task",
        position: "top",
        width: 250,
        content: toolTip
      });
    });
    $("#scheduler").hover(toolTip);

    function toolTip(e) {
      var target = $(e.target);
      var uid = target.attr("data-uid");
      var scheduler = target
        .closest("[data-role=scheduler]")
        .data("kendoScheduler");
      var model = scheduler.occurrenceByUid(uid);
      if (model) {
       // console.log("model", model.title);
        const event = model.description;
      }
      else {
      }
      if (model != null || model != undefined) {
        return (
          "<div style='text-align:justify;line-height:25px' id='calendarTooltip'><b>Event Title:"+model.title+"<br>" +
          "<b>Event Start time &nbsp;:</b>&nbsp;&nbsp;" +
          self._datePipe.transform(model.start, "h:mm:ss a") +
          "</br>" +
          "<b>Event End time &nbsp:</b>&nbsp;&nbsp;" +
          self._datePipe.transform(model.end, "h:mm:ss a") +
          "</br>" +
          "<b>Judge Name &nbsp:</b>&nbsp;&nbsp;" +
          model.JudgeID +
          "</br>" +
          "<b>Court Room Number &nbsp:</b>&nbsp;&nbsp;" +
          model.room +
          "</br>" +
          "<b>Summonsed &nbsp:</b>&nbsp;&nbsp;" +
          model.summons +
          "</br>" +
          "<b>Panel &nbsp:</b>&nbsp;&nbsp;" +
          model.panel +
          "</br>" +
          "</div>"
        );
      }
    }
    var scheduler = $("#scheduler").data("kendoScheduler");
    // $("#scheduler").on("click", ".k-event", function(e){     
        
    $(document).on("click", ".k-event", function batch(e) {
      var scheduler = $("#scheduler").getKendoScheduler();
      var event = scheduler.occurrenceByUid($(this).data("uid"));
      // window.location.replace("/home/event/" + event.eventID);
      // self.router.navigate(["/home/event/" + event.eventID]);
      self.zone.run(()=>{
        self.router.navigateByUrl("/home/event/" + event.eventID);
      })
      setTimeout(() => {
        if (this.cdr && !(this.cdr as ViewRef).destroyed) {
          this.cdr.detectChanges();
        }
      });
      //window.location.href="/home/event/" + event.eventID;
      // self.RedirectToEvents(event.eventID);
      // location.replace("/home/event/" + event.eventID);
      // window.location.hash="/home/event/" + event.eventID;
      // window.history.pushState("", "", "/home/event/" + event.eventID);
    });

  }

  timeValidation(date, param) {
    if (param == "StartTime") {
      if (date.eventStartTime >= date.eventEndTime) {
        this.errorMessageTime = true;
      } else {
        this.errorMessageTime = false;
      }
    }
    if (param == "EndTime") {
      if (date.eventStartTime >= date.eventEndTime) {
        this.errorMessageTime = true;
      } else {
        this.errorMessageTime = false;
      }
    }
  }

  resetCreateEventForm() {
    this.errorMessageTime = false;
    this.FormCalendarEvent = this._FormBuilder.group({
      eventTitle: ["", Validators.required],
      eventStartDate: ["", Validators.required],
      eventStartTime: ["08:00"],
      eventEndTime: ["10:00"],
      judgeName: [""],
      roomNumber: [""],
      caseNumber: [""],
      defendant: [""]
    });
  }

  createCalendarEvent(details) {
    const startDate = this._datePipe.transform(
      details.eventStartDate,
      "MM-dd-yyyy"
    );
    const fullStartDate = startDate + " " + details.eventStartTime;
    const fullEndDate = startDate + " " + details.eventEndTime;
    const calendarEventDetails = {
      Title: details.eventTitle,
      StartDateTime: fullStartDate,
      EndDateTime: fullEndDate,
      JudgeID: details.judgeName,
      CaseNo: details.caseNumber,
      RoomNumberID: details.roomNumber,
      Defendant: details.defendant,
      JurisdictionID: this.JurisdictionID,
      LoggedInUser: this.LoggedinUser
    };
    this._calendarService
      .saveCalendarEventInfo(calendarEventDetails)
      .subscribe(data => {
        if (data.success === true) {
          alert("Event Created Successfully");
          $("#scheduler")
            .data("kendoScheduler")
            .dataSource.read();
          this.FormCalendarEvent.reset();
          $("#createEventModal").hide();
          $(".modal-backdrop").remove();
          this.resetCreateEventForm();
        } else {
        }
      });
  }
  RedirectToEvents(id) {
    this.router.navigate(["/home/event/" + id]);

  }
}
