import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  UserName: string;
  RoleId: any;
  adminsetting: any;
  allusers: any;
  showAbout; showJurorTab;
  superAdmin: any;
  juror: boolean = false;
  // superAdmin:boolean=true;
  currentURL: any;
  jurisdictionName: any;
  isLoading: Boolean = false;
  jurorAccountID;
   showJurisdiction: boolean = false;

  constructor(private adalSvc: MsAdalAngular6Service, private _router: Router) {
    this.currentURL = location.pathname;
  }

  ngOnInit() {    
    //this.UserName = localStorage.getItem("UserName");
    this.isLoading = true;
    if (this.adalSvc.userInfo == null || this.adalSvc.LoggedInUserName == "" || this.adalSvc.LoggedInUserName == 'undefined')
      this.UserName =localStorage.getItem("UName").trim();      
    else {
      this.UserName = this.adalSvc.LoggedInUserName;
    }
      this.RoleId = localStorage.getItem("RoleID");
      this.jurisdictionName = localStorage.getItem("JurisdictionName");
      this.jurorAccountID = localStorage.getItem("jurorAccountID");
    if (this.jurorAccountID != null || this.jurorAccountID != '' && this.adalSvc.userInfo == null && this.RoleId == null) {
        this.showAbout = true;
        this.showJurorTab = true;
        this.showJurisdiction = true;
        this._router.navigate(['/home/jurorPortal']);
      }
      else if (this.RoleId == null || this.RoleId == undefined) {
        this._router.navigate(['/']);
      }
      else {
        if (this.RoleId === "1") {
          // this.isLoading = true;
          this.adminsetting = false;
          this.allusers = false;
          this.superAdmin = true;
          this.showJurisdiction = true;
          this._router.navigate(['/home/devtools']);
        }
      if (this.RoleId === "2") {
       
          this.adminsetting = true;
          this.allusers = true;
         this.superAdmin = false;       
        if (this.currentURL == '/') {
          setTimeout(() => { this.isLoading = false; }, 5000);    
            this._router.navigate(['/home/dashboard']);          
           
            //this._router.navigate(['/dashboard']);
          }
        }
        if (this.RoleId === "3") {
          this.adminsetting = false;
          this.allusers = true;
          this.superAdmin = false;
          if (this.currentURL == '/') {
            this._router.navigate([this.currentURL]);
          }
        }

    }
    this.isLoading = false;
  }
  
  logout() {
    localStorage.clear();
    if (this.adalSvc.userInfo == null) {
      this._router.navigate(['/']);    
    }
    else
    this.adalSvc.logout();    
  }
}
