import { Component, ViewContainerRef, OnInit, Inject } from '@angular/core';
import { NgForm, FormBuilder, FormGroup, Validators, FormControl, ReactiveFormsModule } from '@angular/forms';
import { Router, ActivatedRoute, Data } from '@angular/router';
import { HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';
import { DevtoolsService } from '../devtools/devtools.service';
import { ConfigurationService } from './../configuration.service';
import { Http, Headers } from '@angular/http';
//import { ToastOptions } from 'ng2-toastr';
//import { ToastsManager } from 'ng2-toastr/ng2-toastr';
import { Observable } from 'rxjs'
declare var $: $;
import $ from "jquery";
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
@Component({
  selector: 'app-devtools',
  templateUrl: './devtools.component.html',
  styleUrls: ['./devtools.component.css'],
  //providers: [ToastsManager, ToastOptions]
})
export class DevtoolsComponent implements OnInit {
  public appUrl = this.configurationservice.baseURL;
  isCollapsed = true;
  isLoading: boolean = false;
  private step: number = 5;
  JurisdictionList: any[] = [];
  Developerdetails: any[] = [];
  JurisdictionNameResults: any[] = [];
  jurisdictionID: any;
  selectedValue: boolean = false;
  showviewjurisdictiondetail: boolean = false;
  JurisdictionDetails: any[] = [];
  JuriName;
  JuriCode;
  JuriAddress;
  JuriZipCode;
  JuriPhone;
  JuriFax;
  JuriEmail;
  dataParams;
  rolename: string = "";
  key: string = 'name';
  reverse: boolean = false;
  orderByField: string = "EmailID";
  reverseSort: boolean = false;
  JurisdictionRoleDetails: any[] = [];
  userID;
  showDeleteUserSuccessMsg: boolean = false;
  deleteUserSuccessMsg: string = "";
  showDeleteUserErrorMsg: boolean = false;
  deleteUserErrorMsg: string = "";
  selectedRoleName: string = "";
  selectedJurisdictionName: string = "";
  showDiv: boolean = false;
  DevUpdateMsg: string = "";
  alertupdatetdMessage: boolean = false;
  SelectedJurisdictionID;
  submitAlertMessage;
  CreateDeveloperform;
  roleID; DevplrData; UserName; EmailID; FirstName; LastName;
  acceptedAlertMessage: boolean = false;
  alertMessage: string = "";
  updatetdMessage: string = "";
  DevOperationFailureMsg: boolean = false;
  SubmitSuccessMsg: string = "";
  rejectedAlertMessage: boolean = false;
  rejectMessage: string = "";
  emailValidation: boolean = false;
  panelExpanded = false;
  isRoleNameEdit: boolean = false;
  order: string = 'firstName';
  p: number = 1;
  jurisdictionName = '';
  LoggedUserID = '';
  emailDuplicate: boolean;
  acceptedAlertDangerMessage: boolean;
  updateFailedMessage: string;
  updateFailed: boolean;
  isEdit: boolean = false;
  ToMailID: any; CcMailID: any;
  CourtAdminEmailAddress: any[] = [];
  IsEditOption: boolean = false; IsEditOptionValue: boolean = false; IsViewOptionSet: boolean = false;
  OptionsList: any[] = []; questionnaireList: any[] = []; optionSetList: any[] = [];
  optionSetName: string; listNames: any; names: any[] = [];
  optionSetValuesList: any[] = []; OptionValuesList: any[] = [];
  jurisID: number; optionId: number; questionName: string; optionSetID: number; questionId: number;
  selectedOptionSetId: number; editQuestionName: string; optionValueID: number;
  errorOptionSetMsg: boolean = false; errorOptionSetMessage: string = "";
  optionValue; selectedOptionId;

  constructor(private devtoolsservice: DevtoolsService, private _fb: FormBuilder,
    private _avRoute: ActivatedRoute, private router: Router,
    private configurationservice: ConfigurationService, private http: HttpClient
    , private adalSvc: MsAdalAngular6Service) {

    this.JurisdictionRoleDetails = [{ RoleName: 'UnAssigned' }, { RoleName: 'Admin' }, { RoleName: 'Court Admin' }, { RoleName: 'Court Clerk' }];
  }

  ngOnInit() {
    this.CreateDeveloperform = this._fb.group({
      devfirstName: new FormControl('', [Validators.required]),
      devlastName: new FormControl('', [Validators.required]),
      devEmail: new FormControl('', Validators.compose(
        [Validators.required, Validators.pattern("[^ @]*@[^ @]*")])),
      SelectedJurisdictionID: new FormControl('', [Validators.required])
    });
    this.jurisdictionName = localStorage.getItem("JurisdictionName");
    this.LoggedUserID = this.adalSvc.LoggedInUserName;  //localStorage.getItem("UserName");
    this.jurisdictionID = localStorage.getItem("JurisdictionID");
  }
  sortTableCols(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }
  getalljurisdiction() {
    //this.isLoading = true;
    this.devtoolsservice.getalljurisdiction(this.step).subscribe(data => {
      this.JurisdictionList = data.listJurisdictionUsers;
      //console.log("hello", this.JurisdictionList);
      //   this.isLoading = false;
    });
  }

  ViewAllDevelopers() {
    //this.isLoading = true;
    this.devtoolsservice.ViewAllDevelopers().subscribe(data => {
      this.Developerdetails = data.listJurisdictionUsers;
      // console.log(this.Developerdetails);
      // this.isLoading = false;
      this.devtoolsservice.GetAllJurisdictionData().subscribe(data => { //to bind Edit Jurisdiction name dropdown values
        this.JurisdictionNameResults = data.lstJurisdictionDetails;

      });
    });
  }
  selectJurisChange(jurisID) {
    this.selectedValue = true;
    this.jurisID = jurisID
    this.GetQuestionnaireData(jurisID);
  }

  AddNewDev() {
    //   this.isLoading = true;    
    this.devtoolsservice.GetAllJurisdictionData().subscribe(data => {
      this.JurisdictionNameResults = data.lstJurisdictionDetails;
      // this.isLoading = false;
    });
  }
  OnselectJuris(id) {
    //console.log(this.JurisdictionNameResults);
    this.SelectedJurisdictionID = id;
  }
  NewDeveloperSubmit(inputsdata) {
    this.dataParams = {
      JurisdictionID: inputsdata.SelectedJurisdictionID,
      FirstName: inputsdata.devfirstName,
      LastName: inputsdata.devlastName,
      EmailID: inputsdata.devEmail,
      RequestType: "INSERT",
      RoleID: 1,
      IsActive: true,
      LoggedUserID: this.LoggedUserID
    };
    this.devtoolsservice.AddEditDeveloper(this.dataParams).subscribe(data => {
      if (data.success == true) {
        this.getAllDevelopersDetails();
        //   this.isLoading = true;
        this.acceptedAlertMessage = true;
        this.alertMessage = "New Developer added sucessfully";
        this.CreateDeveloperform.reset();
        setTimeout(() => {
          this.acceptedAlertMessage = false;
          this.alertMessage = "";
        }, 3000);

      }
      else {
        this.rejectedAlertMessage = true;
        this.rejectMessage = "Failed";
        setTimeout(() => {
          this.rejectedAlertMessage = false;
          this.rejectMessage = "";
        }, 3000);
      }
    });
  }
  gotoStepOne(JurisdictionId) {
    this.router.navigate(['/home/onboarding-step1', JurisdictionId]);
  }
  showDetails(jurisdictionData) {
    this.showviewjurisdictiondetail = true;
    this.devtoolsservice.GetJurisdictionDetails(jurisdictionData.jurisdictionID).subscribe(data => {

      if (data.success == true) {

        this.JuriName = data.listJurisdictions[0].jurisdictionName;
        this.JuriCode = data.listJurisdictions[0].jurisdictionCode;
        this.JuriAddress = data.listJurisdictions[0].jurisdictionAddress;
        this.JuriZipCode = data.listJurisdictions[0].jurisdictionZipCode;
        this.JuriPhone = data.listJurisdictions[0].jurisdictionPhone;
        this.JuriFax = data.listJurisdictions[0].jurisdictionFax;
        this.JuriEmail = data.listJurisdictions[0].jurisdictionEmail;
      }
    });
  }
  startService(servicename, id) {
    this.dataParams = {
      jurisdictionId: id,
      serviceName: servicename,
      LoggedInUserName: this.LoggedUserID
    };
    this.devtoolsservice.startService(this.dataParams).subscribe(data => {
      if (data.success == true) {
        this.isLoading = true;
        this.devtoolsservice.getalljurisdiction(this.step).subscribe(data => {
          this.JurisdictionList = data.listJurisdictionUsers;
        });
        this.isLoading = false;
        alert("Service Started Successfully");
      }

    });

  }
  stopService(servicename, id) {
    this.dataParams = {
      jurisdictionId: id,
      serviceName: servicename,
      LoggedInUserName: this.LoggedUserID
    };
    this.devtoolsservice.startService(this.dataParams).subscribe(data => {
      if (data.success == true) {
        this.isLoading = true;
        this.devtoolsservice.getalljurisdiction(this.step).subscribe(data => {
          this.JurisdictionList = data.listJurisdictionUsers;
        });
        this.isLoading = false;
        alert("Service Stopped Successfully");
      }

    });
  }
  GetdropdownValues(data) {
    this.selectedRoleName = data.roleName;
    this.selectedJurisdictionName = data.jurisdictionID;
    this.UserName = data.userName;
    this.EmailID = data.emailID;
    this.FirstName = data.firstName;
    this.LastName = data.lastName;
    this.userID = data.userID;
  }
  DeveloperDelete(userID) {
    this.userID = userID;
  }
  DeveloperDeleteConfirm() {
    var uploadUrl = this.appUrl + "/OnboardApi/DeleteDeveloper?" + 'userID=' + this.userID;
    this.http.post(uploadUrl, this.userID).subscribe(data => {
      var response = data;
      if (data['success'] == true) {
        this.showDeleteUserSuccessMsg = true;
        this.deleteUserSuccessMsg = "Developer deleted successfully";
        setTimeout(() => {
          this.showDeleteUserSuccessMsg = false;
          this.deleteUserSuccessMsg = "";
        }, 3000);
        this.devtoolsservice.ViewAllDevelopers().subscribe(data => {
          this.Developerdetails = data.listJurisdictionUsers;
        });
      }
      else {
        this.showDeleteUserErrorMsg = true;
        this.deleteUserErrorMsg = "Error occured while deletion";
        setTimeout(() => {
          this.showDeleteUserErrorMsg = false;
          this.deleteUserErrorMsg = "";
        }, 3000);
      }
      setTimeout(() => {
        this.showDeleteUserSuccessMsg = false;
        this.deleteUserSuccessMsg = "";
        this.showDeleteUserErrorMsg = false;
        this.deleteUserErrorMsg = "";
      }, 3000);
    });

  }
  SaveDevData(role, jurisdictionID) {
    if (role == "UnAssigned") { this.roleID = 0 }
    if (role == "Admin") { this.roleID = 1 }
    if (role == "Court Admin") { this.roleID = 2 }
    if (role == "Court Clerk") { this.roleID = 3 }
    this.dataParams = {
      RoleID: this.roleID,
      IsActive: 1,
      UserID: this.userID,
      JurisdictionID: jurisdictionID,
      UserName: this.LoggedUserID,
      EmailID: this.EmailID,
      FirstName: this.FirstName,
      LastName: this.LastName,
      LoggedUserID: this.LoggedUserID,
    };

    this.devtoolsservice.UpdateDevplrData(this.dataParams).subscribe(data => {
      if (data.success == true) {
        this.updatetdMessage = "Role changed successfully";
        this.alertupdatetdMessage = true;
        setTimeout(() => {
          this.DevUpdateMsg = "";
          this.alertupdatetdMessage = false;
        }, 3000);
        this.isLoading = true;
        this.devtoolsservice.ViewAllDevelopers().subscribe(data => {
          this.Developerdetails = data.listJurisdictionUsers;
          this.isLoading = false;
        });
      }
      else {
        this.updateFailedMessage = "Update Failed";
        this.updateFailed = true;
        setTimeout(() => {
          this.updateFailedMessage = "";
          this.updateFailed = false;
        }, 3000);
      }
    });
  }
  DeActivateDev(UserId) {
    this.dataParams = {
      UserID: UserId,
      RequestType: "DEACTIVATE",
      LoggedUserID: this.LoggedUserID,
    };
    this.devtoolsservice.ManageDeveloper(this.dataParams).subscribe(data => {
      if (data.success == true) {
        this.acceptedAlertMessage = true;
        this.alertMessage = "Record DeActivated";
        setTimeout(() => {
          this.acceptedAlertMessage = false;
          this.alertMessage = "";
        }, 3000);
        this.devtoolsservice.ViewAllDevelopers().subscribe(data => {
          this.Developerdetails = data.listJurisdictionUsers;
        });
      }
      else {
        this.rejectedAlertMessage = true;
        this.rejectMessage = "Something went wrong..";
        setTimeout(() => {
          this.rejectedAlertMessage = false;
          this.rejectMessage = "";
        }, 3000);

      }
    });

    setTimeout(() => {
      this.acceptedAlertMessage = false;
      this.alertMessage = "";
    }, 3000);

  }
  //activate developer
  ActivateDev(UserId) {
    this.dataParams = {
      UserID: UserId,
      RequestType: "ACTIVATE",
      LoggedUserID: this.LoggedUserID,
    };
    this.devtoolsservice.ManageDeveloper(this.dataParams).subscribe(data => {
      if (data.success == true) {
        this.acceptedAlertMessage = true;
        this.alertMessage = "Record Activated";
        setTimeout(() => {
          this.acceptedAlertMessage = false;
          this.alertMessage = "";
        }, 3000);
        this.devtoolsservice.ViewAllDevelopers().subscribe(data => {
          this.Developerdetails = data.listJurisdictionUsers;
        });
      }
      else {
        this.rejectedAlertMessage = true;
        this.rejectMessage = "Something went wrong..";
        setTimeout(() => {
          this.rejectedAlertMessage = false;
          this.rejectMessage = "";
        }, 3000);

      }
    });
    setTimeout(() => {
      this.acceptedAlertMessage = false;
      this.alertMessage = "";
    }, 3000);

  }
  deleteJuridiction(jurisdictionID, userid) {
    //userid = 58;
    var uploadUrl = this.appUrl + "/OnboardApi/DeleteJurisdiction?" + 'jurisdictionId=' + jurisdictionID + '&userID=' + userid;
    //console.log(uploadUrl);
    // Note: Here inprogress jurisidictions we can't delete bcoz there is no user assigned to that jurisidiction.
    this.http.post(uploadUrl, this.userID).subscribe(data => {
      var response = data;
      if (response['success'] != true) {
        alert('Jurisdiction not Deleted');
      }
      else {
        this.devtoolsservice.getalljurisdiction(this.step).subscribe(data => {
          this.JurisdictionList = data.listJurisdictionUsers;
        });
        alert('Jurisdiction Deleted Successfully');
      }
    });
  }
  //Sorting ngx-order-pipe
  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.order = value;
  }

  /*********view all developers ********* */
  getAllDevelopersDetails() {
    this.devtoolsservice.ViewAllDevelopers().subscribe(data => {
      this.Developerdetails = data.listJurisdictionUsers;
      this.devtoolsservice.GetAllJurisdictionData().subscribe(data => { //to bind Edit Jurisdiction name dropdown values
        this.JurisdictionNameResults = data.lstJurisdictionDetails;
      });
    });
  }
  Back() {
    this.router.navigate(['/', 'red-pill']);

  }
  /********************Add new developer************* */
  checkDuplicateEmail(userData) {
    const userEmail = userData.devEmail;
    const jurisdictionId = this.jurisdictionID;

    if ((userEmail == "" || userEmail == undefined || userEmail == null) || (jurisdictionId == "" || jurisdictionId == undefined || jurisdictionId == null)) {
    } else {
      const userDetails = {
        Email: userEmail,
        JurisdictionID: jurisdictionId
      }

      this.devtoolsservice.checkDuplicateEmail(userDetails).subscribe(data => {
        if (data > 1) {
          this.emailDuplicate = true;
        }
        else {
          this.emailDuplicate = false;
        }
      });
    }
  }
  loadCourtAdminEmails() {
    this.isLoading = true;
    this.isEdit = false;
    this.devtoolsservice.GetCourtAdminDetails().subscribe(data => {
      this.CourtAdminEmailAddress = data.courtAdminEmailAddressDTOList;
      this.isLoading = false;
    });
  }
  editEmailAdress(data) {
    this.isEdit = true;

  }
  updateEmailDetails(val, toCourtEmailAddress, ccEmailAddress) {
    var dataParams = {
      'JurisdictionID': val.jurisdictionID,
      'ToCourtEmailAddress': toCourtEmailAddress,
      'CCEmailAddress': ccEmailAddress
    };
    this.devtoolsservice.UpdateCourtAdminEmail(dataParams).subscribe(data => {
      if (data.success === true)
        this.isEdit = false;
    });
  }
  cancelEdit() {
    this.isEdit = false;
  }
  //Manage Option  Set with Option Values
  GetOptionSetWithValues() {
    this.isLoading = true;
    var optionsetDetails = {
      optionSetID: 0,
      optionSetName: null,
      queryType: "Get"
    };
    this.devtoolsservice.GetQuestionnaireOptionSet(optionsetDetails).subscribe(data => {

      if (data.success == true) {
        this.optionSetList = [];
        this.optionSetValuesList = [];
        this.optionSetList = data.questionnaireOptionSetDTOList;
        this.optionSetValuesList = data.optionSetValuesDTOList;

      }
      else {
        //this._toastr.error(res._StatusMessage);

      }
    });
    this.isLoading = false;
  }
  selectOptionSet(optionSetID) {
    this.optionSetID = parseInt(optionSetID);
    this.IsViewOptionSet = true;
    this.OptionValuesList = [];
    this.OptionValuesList = this.optionSetValuesList.filter(
      x => x.questionnaireOptionSetID === optionSetID);
  }
  NewOptionSetSubmit(optionSetName) {

    //this.isLoading = true;
    var optionSetDetails =
    {
      optionSetID: 0,
      optionSetName: optionSetName,
      queryType: "ADD"
    };
    this.devtoolsservice.GetQuestionnaireOptionSet(optionSetDetails).subscribe(data => {
      if (data.success == true && data.errorMessage == null) {
        this.optionSetList = [];
        this.optionSetValuesList = [];
        this.optionSetList = data.questionnaireOptionSetDTOList;
        this.optionSetValuesList = data.optionSetValuesDTOList;
      }
      else if (data.success == true && data.errorMessage != null) {
        this.errorOptionSetMsg = true;
        this.errorOptionSetMessage = data.errorMessage;
      }
      else {
        this.errorOptionSetMsg = true;
        this.errorOptionSetMessage = data.errorMessage;
      }
      setTimeout(() => {
        this.errorOptionSetMsg = false;
        this.errorOptionSetMessage = "";
      }, 2500);
      this.optionSetName = "";
    });
  }
  editOptionSetName() {
    this.IsEditOption = true;
  }
  cancelOptionSet() {
    this.IsEditOption = false;
  }
  updateOptionSetName(data) {
    var optionSetDetails =
    {
      optionSetID: data.questionnaireOptionSetID,
      optionSetName: data.questionnaireOptionSetName,
      queryType: "EDIT"
    };
    this.devtoolsservice.GetQuestionnaireOptionSet(optionSetDetails).subscribe(data => {
      if (data.success == true && data.statusCode == 0) {
        this.IsEditOption = false;
        this.optionSetList = data.questionnaireOptionSetDTOList;
        this.optionSetValuesList = data.optionSetValuesDTOList;

      }
      else {

      }
      this.isLoading = false;
    });
  }
  DeleteOptionSet(data) {
    this.optionSetID = data.questionnaireOptionSetID;
  }
  ConfirmDeleteOptionSet() {
    var optionSetDetails =
    {
      optionSetID: this.optionSetID,
      optionSetName: null,
      queryType: "DELETE"
    };
    this.devtoolsservice.GetQuestionnaireOptionSet(optionSetDetails).subscribe(data => {
      if (data.success == true && data.statusCode == 0) {
        this.optionSetList = data.questionnaireOptionSetDTOList;
        this.optionSetValuesList = data.optionSetValuesDTOList;
      }
      else {
      }
      this.isLoading = false;
    });
  }
  NewOptionValueSubmit(optionValue) {
    var optionValueDetails =
    {
      optionSetID: this.optionSetID,
      optionValueID: 0,
      optionValueName: optionValue,
      queryType: "ADD"
    };
    this.devtoolsservice.ManageOptionValues(optionValueDetails).subscribe(data => {
      if (data.success == true && data.statusCode == 0) {
        this.IsViewOptionSet = false;
        //$('#radio_' + this.optionSetID).checked = true;        
        //this.selectOptionSet(this.optionSetID);
        this.optionValue = "";
        this.optionSetList = data.questionnaireOptionSetDTOList;
        this.optionSetValuesList = data.optionSetValuesDTOList;
        this.OptionValuesList = [];
        this.OptionValuesList = data.optionSetValuesList.filter(x => x.optionSetID === this.optionSetID);
      }
      else {

      }
      this.isLoading = false;
    });

  }
  editOptionValueName() {
    this.IsEditOptionValue = true;
  }
  cancelOptionValue() {
    this.IsEditOptionValue = false;
  }
  updateOptionValueName(data) {
    var optionValueDetails =
    {
      optionSetID: data.questionnaireOptionSetID,
      optionValueID: data.optionValueID,
      optionValueName: data.optionValueName,
      queryType: "EDIT"
    };
    this.devtoolsservice.ManageOptionValues(optionValueDetails).subscribe(res => {
      if (res.success == true && res.statusCode == 0) {
        this.IsEditOptionValue = false;
        this.IsViewOptionSet = false;
        this.optionSetList = res.questionnaireOptionSetDTOList;
        this.optionSetValuesList = res.optionSetValuesDTOList;
        this.OptionValuesList = [];
        this.OptionValuesList = res.optionSetValuesList.filter(x => x.optionSetID === data.questionnaireOptionSetID);

      }
      else {

      }
      this.isLoading = false;
    });
  }
  DeleteOptionValueName(data) {
    this.optionSetID = data.questionnaireOptionSetID;
    this.optionValueID = data.optionValueID;
  }
  ConfirmDeleteOptionValue() {
    var optionValueDetails =
    {
      optionSetID: this.optionSetID,
      optionValueID: this.optionValueID,
      optionValueName: null,
      queryType: "DELETE"
    };
    this.devtoolsservice.ManageOptionValues(optionValueDetails).subscribe(res => {
      if (res.success == true && res.statusCode == 0) {
        this.IsViewOptionSet = false;
        this.IsEditOptionValue = false;
        this.optionSetList = res.questionnaireOptionSetDTOList;
        this.optionSetValuesList = res.optionSetValuesDTOList;
        this.OptionValuesList = [];
        this.OptionValuesList = res.optionSetValuesList.filter(x => x.optionSetID === this.optionSetID);

      }
      else {

      }
      this.isLoading = false;
    });
  }
  GetQuestionnaireData(jurisID) {
    var questionnaireDetails = {
      QuestionID: 0,
      JurisdictionID: jurisID,
      QuestionName: null,
      OptionSet_ID: 0,
      Query_Type: 'GET'
    }
    this.devtoolsservice.ManageQuestionnaire(questionnaireDetails).subscribe(data => {
      if (data.success == true && data.statusCode == 0) {
        this.questionnaireList = [];
        this.optionSetList = [];
        this.OptionsList = [];

        this.questionnaireList = data.questionnaireDTOList.filter(x => x.jurisdictionID === jurisID);
        for (var val of this.questionnaireList) {
          this.listNames = {
            id: val.questionID,
            name: val.listNames.split('/')
          };
          this.OptionsList.push(this.listNames);

        }

        this.optionSetList = data.optionSetDTOList;
      }
    });
  }
  addQuestion(questionName, optionId) {
    this.questionName = questionName;
    this.optionId = optionId;
    if (optionId == 0 || optionId == undefined) {
      alert("Please select atleast one option set");
    }
    var questionnaireDetails = {
      QuestionID: 0,
      JurisdictionID: this.jurisID,
      QuestionName: questionName,
      OptionSet_ID: optionId,
      Query_Type: 'ADD'
    }
    this.devtoolsservice.ManageQuestionnaire(questionnaireDetails).subscribe(data => {
      if (data.success == true && data.statusCode == 0) {
        this.questionnaireList = [];
        this.optionSetList = [];
        this.questionnaireList = data.questionnaireDTOList.filter(x => x.jurisdictionID === this.jurisID);
        this.optionSetList = data.optionSetDTOList;
        this.clearValues(questionName, optionId);
      }
    });

  }
  clearValues(quesName, optionId) {
    this.questionName = '';
    this.optionId = 0;
    this.selectedOptionId = '';
  }
  EditQuestion(data) {
    this.selectedOptionSetId = data.optionSetID;
    this.editQuestionName = data.questionName;
    this.jurisID = data.jurisdictionID;
    this.questionId = data.questionID;
  }
  UpdateQuestion(questionName, optionSetID) {
    if (optionSetID == undefined) {
      optionSetID = this.selectedOptionSetId;
    }
    var questionnaireDetails = {
      QuestionID: this.questionId,
      JurisdictionID: this.jurisID,
      QuestionName: questionName,
      OptionSet_ID: optionSetID,
      Query_Type: 'EDIT'
    }
    this.devtoolsservice.ManageQuestionnaire(questionnaireDetails).subscribe(data => {
      if (data.success == true && data.statusCode == 0) {
        this.questionnaireList = [];
        this.optionSetList = [];
        this.questionnaireList = data.questionnaireDTOList.filter(x => x.jurisdictionID === this.jurisID);
        this.optionSetList = data.optionSetDTOList;

        // this.clearValues(this.editQuestionName, this.selectedOptionSetId );
      }
    });
  }
  deleteQuestion(data) {
    this.jurisID = data.jurisdictionID;
    this.questionId = data.questionID;
    this.optionSetID = data.optionSetID;
  }
  ConfirmDeleteQuestion() {
    var questionnaireDetails = {
      QuestionID: this.questionId,
      JurisdictionID: this.jurisID,
      QuestionName: null,
      OptionSet_ID: this.optionSetID,
      Query_Type: 'DELETE'
    }
    this.devtoolsservice.ManageQuestionnaire(questionnaireDetails).subscribe(res => {
      if (res.success == true && res.statusCode == 0) {
        this.questionnaireList = [];
        this.optionSetList = [];
        this.questionnaireList = res.questionnaireDTOList.filter(x => x.jurisdictionID === this.jurisID);
        this.optionSetList = res.optionSetDTOList;
        //this.clearValues(this.questionName, this.optionId);
      }
    });
  }

}
