import { Injectable } from '@angular/core';
import { ConfigurationService } from 'src/app/configuration.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class Onboardingstep4Service {
  public baseUrl=this.configurationservice.baseURL;
  constructor(private configurationservice: ConfigurationService,private http:HttpClient) {
  }
      Onboardingfinalstep(model: any): Observable < any > {
          return this.http.post(this.baseUrl+'OnboardApi/Onboardingfinalstep', model)
          .pipe(catchError(this.errorHandler));
      } 
      GetJurisdictionDetails(model: any): Observable<any> {
        return this.http.get(this.baseUrl+'OnboardApi/GetJurisdictionDetails?jurisdictionID=' + model.jurisdictionID + '&stepNo=' + model.stepNo)
        .pipe(catchError(this.errorHandler));
    } 
    checkDuplicateEmail(model: any): Observable<any> {
      return this.http.get(this.baseUrl + 'OnboardApi/CheckDuplicateUserEmail?UserEmail=' + model.Email + '&JurisdictionID=' + model.JurisdictionID)
        .pipe(catchError(this.errorHandler));
    }
    errorHandler(error: Response) {
      return Observable.throw(error);
    }
 
    }