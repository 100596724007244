import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigurationService } from 'src/app/configuration.service';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class Onboardstep1Service {
  [x: string]: any;
  private baseURL: string;

  constructor(private http: HttpClient, private configurationservice: ConfigurationService) {
    this.baseURL = this.configurationservice.baseURL;
  }

  AddNewJurisdiction(model: any): Observable<any> {
    return this.http.post(this.baseURL + 'OnboardApi/AddNewJurisdiction', model)
      .pipe(catchError(this.errorHandler));
  }

  GetAllStates(): Observable<any> {
    return this.http.get(this.baseURL + 'OnboardApi/GetAllStates')
      .pipe(catchError(this.errorHandler));
  }

  GetAllCounty(StateId: Number): Observable<any> {
    return this.http.get(this.baseURL + 'OnboardApi/GetAllCounty?StateId=' + StateId)
      .pipe(catchError(this.errorHandler));
  }

  GetAllCity(CountyId: Number): Observable<any> {
    return this.http.get(this.baseURL + 'OnboardApi/GetAllCity?CountyId=' + CountyId)
      .pipe(catchError(this.errorHandler));
  }

  GetJurisdictionDetails(model: any): Observable<any> {
    return this.http.get(this.baseURL + 'OnboardApi/GetJurisdictionDetails?jurisdictionID=' + model.jurisdictionID + '&stepNo=' + model.stepNo)
      .pipe(catchError(this.errorHandler));
  }

  GetJurisDetails(jurisdictionID: Number): Observable<any> {
    return this.http.get(this.baseURL + 'OnboardApi/GetJurisDetails?jurisdictionID=' + jurisdictionID)
      .pipe(catchError(this.errorHandler));
  }

  checkDuplicateEmail(model: any): Observable<any> {
    return this.http.get(this.baseURL + 'OnboardApi/CheckDuplicateEmail?UserEmail=' + model)
      .pipe(catchError(this.errorHandler));
  }

  checkDuplicateJurisdictionCode(model: any): Observable<any> {
    return this.http.get(this.baseURL + 'OnboardApi/CheckDuplicateJurisdictionCode?StateId=' + model.StateId + '&CountyId=' + model.CountyId + '&CityId=' + model.CityId + '&JurisdictionCode=' + model.JurisdictionCode)
      .pipe(catchError(this.errorHandler));
  }

  errorHandler(error: Response) {
    return Observable.throw(error);
  }
}
