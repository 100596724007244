/// <reference types="@types/googlemaps" />
import { Component, OnInit, NgZone, ViewChild, ElementRef } from '@angular/core';
import { NgForm, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Router, ActivatedRoute, Data } from '@angular/router';
import { EventService } from './event.service';
import { DatePipe } from '@angular/common';
import { ConfigurationService } from '../configuration.service';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import * as FileSaver from 'file-saver';
declare var $: any;

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.css'],
  providers: [DatePipe]
})

export class EventComponent implements OnInit {
  count = 1;
  splitNames = [];
  BatchEvent: any = [];
  EditEventParam;
  FormEditEvent;
  eventID;
  Title;
  JurisdictionID; JurisdictionName;
  requestjurors;
  eventDetails;
  EventDetails;
  
  eventResults: any = []; paymentResults: any = []; jurorEvents: any = [];
  eventName; eventDatedisplay; eventDate; eventStartTime; eventEndTime; judgename; caseno; roomno; defendant; MaxJurors; searchJurorText;
  havingJurors;
  displayList;
  showJurorDiv;
  showAddJurorDiv;
  eventTitle;
  showTextAlert; showSuccessAlert; showFailureAlert; showSuccessReschedule; InvaliddateMessage;
  showTextAlertt; showSuccessAlertt; showFailureAlertt;
  p: number = 1;
  isSingleCheck = false;
  filterText: string = '';
  key: string = 'name';
  reverse: boolean = false;
  totalJurors;
  noOfJurors;
  showRequestJurorAlert;
  displayallevent: any = [];
  batchid;
  jurordetails; summonsSentJurors; summonsNotSentJurors; potentialJurors; assignedJurors; paneledJurors; paidJurorList;
  hideRequest;
  summonsFilter: any;
  summonsTotalJurors;
  moreThanOnePotential; moreThanOneAssigned; moreThanOneSummons; allSummonsSelected; allPotentialSelected; allAssignedSelected; summonsSelectedFilter: string = '';
  maxAlertText; maxAlert; maxAllAlertText; maxAllAlert; infoTextAlert; assingedEventAlert; SearchKeyword;
  jurorStatusDetails; jurorStatusInfo; jurorAddressInfo; selected; editaddress; chosenPlace; emailPattern; phonePattern;
  allQuestionnaireSelected = true; age; felony; english; citizen; civil; resident;
  selectedStatus: any;
  felonyBtn; updateSuccessMessage; updateErrorMessage; LoggedinUser; LoggedinUserID;
  pricePerMile; paymentPerDay; permile; dailypay; onewayMiles; noofDays; totalAmount; totalMileCharge; totalServiceCharge; onewayMileError; roundOff;
  showSinglePrint; showMultiPrint; jurorID; statusID; selectedItem: any = {}; multiSelectedSummons: any = [];
  printJurors: any = []; datevallst: any = []; defaultdateval; previewcerti; preview; previewCertificate; iFrameSrc: SafeResourceUrl; iFrameSummonsSrc: SafeResourceUrl; Invaliddate: boolean = false;
  preview1: boolean = false; SetIframeBackNext: boolean = false;
  disableNext: boolean = false; disableBack: boolean = false; isLoading: boolean = false;
  modelTrue: boolean = false;
  iframePath; updatedCertificateName1; iframePath1; iFrameSummonsSrc2;
  excusedReasonFile: any = [];

  public lat: number = 47.483148;
  public lng: number = -122.194544;
  RouteURL: any;
  updatedCertificateName: string; selectedvalue: any; potialtoAssignJurorCount;
  residenceAddress;
  residencecity;
  residencestate;
  updatedAddress;
  zipcode;
  public origin: any;
  public destination: any;
  _baseAzureURL: string;
  clicks: number = 1;
  questionnaireList: any = []; optionSetList: any = []; OptionsList: any = [];
  listNames: any; selectedValue: any;
  QuestionsList: any = []; questionValues: any; DistinctQuesList: any = [];
  jurorAccountID: any;
  optionListLength;
  toDos: any = [];
  showNone: boolean = false;
  optionsListLength;
  Ques_option: any; Ques_list: any = [];
  jurorIdList: any = [];
  assignedJurorQuesList: any = [];
  selecteddateitem: string;
  isConfirmValue: string;
  batchidValue: any;
  checkboxModel: boolean = true;
  judgenamepopup: string;
  eventNamee: any;
  defendantt: any;
  MaxJurorss: any;
  roomnoo: any;
  casenoo: any;
  judgenamepopupDup: any;
  defendanttDup: any;
  roomnooDup: any;
  casenooDup: any;
  MaxJurorssDup: any;
  eventNameeDup: any;
  @ViewChild('iframe') iframe: ElementRef;

  constructor(private _fb: FormBuilder, private _avRoute: ActivatedRoute,
    private datePipe: DatePipe, private _router: Router,
    private adalSvc: MsAdalAngular6Service,
    private _eventService: EventService, private zone: NgZone,
    private _configuration: ConfigurationService, private _sanitizer: DomSanitizer) {
    this.eventID = this._avRoute.snapshot.params['id'];
    this.RouteURL = '/home/calendar';
    this.requestjurors = false;
    this.showRequestJurorAlert = false;
    this.editaddress = false;
    this.JurisdictionID = localStorage.getItem("JurisdictionID");
    this.noOfJurors = localStorage.getItem("NoOfJurors");
    this.JurisdictionName = localStorage.getItem("JurisdictionName");
    this.havingJurors = true; // Need to change value based on Initial Login Details    
    this.emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    this.phonePattern = /^[(]{0,1}[0-9]{3}[)\.\- ]{0,1}[0-9]{3}[\.\- ]{0,1}[0-9]{4}$/;
    this.LoggedinUser = this.adalSvc.LoggedInUserName;//localStorage.getItem("UserName");
    this.pricePerMile = localStorage.getItem("PricePerMile");
    this.paymentPerDay = localStorage.getItem("PaymentPerDay");
    this.roundOff = JSON.parse(localStorage.getItem("RoundOff"));
    this.LoggedinUserID = localStorage.getItem("UserID");
    this.selected = [];
    this._baseAzureURL = this._configuration.azurecertificateurl;

  }
  ngOnInit() {
    this.checkJurors();
    this.FormEditEvent = new FormGroup({
      eventTitle: new FormControl(),
      judgeName: new FormControl(),
      caseNo: new FormControl(),
      roomNo: new FormControl(),
      defendant: new FormControl(),
      maxJurors: new FormControl()
    });
    this.viewCount();
    this.listJurors();
    this.getAllBatchEvent();
    //this.isLoading = false;
  }

  navigateToCalendar() {
    this._router.navigate(['/home/calendar']);
  }

  eventProfile(event) {
    this.eventID = event.eventID;
    this.listJurors();
  }

  listJurors() {
    let results: any = [];
    let eventDetailsList: any = [];
    const EventID = this.eventID;
    this.eventDetails = {
      eventID: this.eventID,
      JurisdictionID: this.JurisdictionID
    };
    this.moreThanOnePotential = false;
    this.moreThanOneAssigned = false;
    this.moreThanOneSummons = false;
    this.isLoading = true;
    this._eventService.getAllJurorList(this.eventDetails).subscribe(data => {
      results = data.eventJurorList;
      eventDetailsList = results.filter(function (element) {
        return (element.eventID == EventID);
      });
      if (results.length > 0) {
        this.showJurorDiv = true;
        this.showAddJurorDiv = false;
        this.requestjurors = false;

        this.eventName = eventDetailsList[0].title;
        this.eventDatedisplay = eventDetailsList[0].startDateTime;
        this.eventDate = eventDetailsList[0].startDateTime;
        this.eventStartTime = eventDetailsList[0].startDateTime;
        this.eventEndTime = eventDetailsList[0].endDateTime;
        this.judgename = eventDetailsList[0].judgeName;
        this.caseno = eventDetailsList[0].caseNumber;
        this.roomno = eventDetailsList[0].roomNumber;
        this.defendant = eventDetailsList[0].defendant;
        this.MaxJurors = eventDetailsList[0].maxAssignedJurors;
      }
      else {
        this.showJurorDiv = false;
        this.showAddJurorDiv = true;
        this.requestjurors = true;

        this._eventService.getEventDetails(this.eventDetails).subscribe(data => {
          this.EventDetails = data.eventJurorList;

          this.eventName = this.EventDetails[0].title;
          this.eventDatedisplay = this.EventDetails[0].startDateTime;
          this.eventDate = this.EventDetails[0].startDateTime;
          this.eventStartTime = this.EventDetails[0].startDateTime;
          this.eventEndTime = this.EventDetails[0].endDateTime;
          this.roomnoo = data.eventJurorList[0].roomNumber;
          this.judgenamepopup = data.eventJurorList[0].judgeID;
          this.casenoo = data.eventJurorList[0].caseNumber;
          this.defendantt = data.eventJurorList[0].defendant;
          this.MaxJurorss = data.eventJurorList[0].maxAssignedJurors;
          this.judgenamepopupDup = this.judgenamepopup?true:false;
          this.defendanttDup = this.defendantt?true:false;
          this.roomnooDup = this.roomnoo?true:false;
          this.casenooDup = this.casenoo?true:false;
          this.MaxJurorssDup = this.MaxJurorss?true:false;
          this.eventNameeDup = this.eventNamee?true:false;
          // this.FormEditEvent.controls['roomNo'].setValue(data.eventJurorList[0].roomNumber);

          let todaysDate = this.datePipe.transform(new Date(), 'yyyy/MM/dd');
          let eventDateValidaion = this.datePipe.transform(this.eventDate, 'yyyy/MM/dd');

          if (eventDateValidaion <= todaysDate) {
            this.hideRequest = true;
          } else {
            this.hideRequest = false;
          }

        });
      }
      this.eventResults = results.filter(function (element) {
        return (element.eventID == EventID);
      });
      this.summonsSentJurors = this.eventResults.filter(function (element) {
        return (element.statusDescription === 'Summons Issued');
      });
      this.summonsNotSentJurors = this.eventResults.filter(function (element) {
        return (element.statusDescription === 'Summons Not Issued');
      });
      this.summonsTotalJurors = this.summonsSentJurors.concat(this.summonsNotSentJurors);
      this.potentialJurors = this.eventResults.filter(function (element) {
        return (element.statusDescription === 'Potential');
      });

      this.assignedJurors = this.eventResults.filter(function (element) {
        return (element.statusDescription === 'Assigned');
      });

      this.paneledJurors = this.eventResults.filter(function (element) {
        // this.getPrintCertificate();
        return (element.statusDescription === 'Jury Served');
      });

      this.paidJurorList = this.eventResults.filter(function (element) {
        return (element.paystatus === 'PAID');
      });
    });
    setTimeout(() => { this.isLoading = false; }, 2000);

  }

  // Check the below code once Login Page Done and we are able to get Initial User Login Details

  checkJurors() {
    if (this.havingJurors === true) {
      this.displayList = true;
      this.requestjurors = false;
    } else if (this.havingJurors === false) {
      this.displayList = false;
      this.requestjurors = false;
    }
  }

  getStatusInfo(item) {
    this.isSingleCheck = true;
    this.jurorAccountID = item.jurorID;
    const inValidateAddress = item.residenceAddress;
    const exactAddress = inValidateAddress.replace(/#/g, '');
    item.residenceAddress = exactAddress;
    this.selected = item;
    this.selectedStatus = "Potential";
    this.allQuestionnaireSelected = true;
    this.selectAllQuestionnaire();
    this.GetQuestionnaireData(parseInt(this.JurisdictionID));
  }
  changeJurorStatus(e) {
    if (e.target.value === "Undelivered") {
      this.selected.workPhone = "000-000-0000";
    }
  }

  confirmSaveDetails(jurorAddressForm: NgForm) {
    let statusID;
    let civilRights;

    if (this.civil === "" || this.civil === undefined || this.civil === "0") {
      civilRights = "0";
    } else {
      civilRights = "1";
    }
    if (this.selectedStatus == null || this.selectedStatus == "") {
      if ((this.age == "0" || this.english == "0" || this.citizen == "0" || this.resident == "0" || this.felony == "1"
      ) && civilRights == "0" || civilRights == "1") {
        this.selectedStatus = 'PermanentlyExcused';
      }
      if ((this.age == "1" && this.english == "1" && this.citizen == "1" && this.resident == "1"
        && this.felony == "1" && civilRights == "1") || civilRights == "0") {
        this.selectedStatus = 'Potential';
      }
    }
    if (this.selectedStatus === 'Potential' || this.selectedStatus == 'Commited Felony') { statusID = 20; }
    else if (this.selectedStatus === 'Rescheduled') { statusID = 12; }
    else if (this.selectedStatus === 'PermanentlyExcused') { statusID = 13; }
    else if (this.selectedStatus === 'Undelivered') { statusID = 16; }
    else { statusID = 11; }
    let age, citizen, resident, english, felony, civilRightsVal = false;
    if (this.age == "1") { age = true; }
    if (this.citizen == "1") { citizen = true; }
    if (this.resident == "1") { resident = true; }
    if (this.english == "1") { english = true; }
    if (this.felony == "1") { felony = true; }
    if (civilRights == "1") { civilRightsVal = true; }
    // age = this.age == "1"
    // citizen= this.citizen == "1"
    // resident = this.resident == "1"
    // english = this.english == "1"
    // felony = this.felony == "1"
    // civilRights = civilRights == "1"

    if (statusID == 12) {
      let jurorInfo = [{
        jurorID: this.selected.jurorID,
        jurisdictionID: this.JurisdictionID,
        eventID: this.eventID
      }]
      this._eventService.moveToReschedule(jurorInfo).subscribe(data => {
        if (data['success'] == true) {
          this.listJurors();
          this.infoTextAlert = 'Selected Jurors Rescheduled Successfully';
          this.showSuccessReschedule = true;
          setTimeout(() => { this.showSuccessReschedule = false; }, 4000);
        }
      })
    }
    else {
      let batchEventIds = [];
      if(this.displayallevent && this.displayallevent.length > 0){
        this.displayallevent.forEach(element => {
          batchEventIds.push(element.eventID)
        });
      }
      this.jurorStatusInfo = {
        JurorID: this.selected.jurorID,
        Email: this.selected.email,
        WorkPhone: this.selected.workPhone,
        HomePhone: this.selected.phone,
        StatusId: statusID,
        EventID: this.eventID,
        ResidenceAddress: this.selected.residenceAddress,
        ResidenceCity: this.selected.residenceCity,
        ResidenceState: this.selected.residenceState,
        County: this.selected.county,
        IsOfEligibleAge: age,
        IsACitizenOfUS: citizen,
        IsAResidentOfCounty: resident,
        IsAbleToCommunicateInEnglish: english,
        HasCommitedFelony: felony,
        CivilRightsRestored: civilRightsVal,
        JurisdictionId: this.JurisdictionID,
        Loggedinuser: this.LoggedinUser,
        EventIDs: batchEventIds
      };

      this._eventService.saveJurorStatus(this.jurorStatusInfo).subscribe(data => {
        if (data['success'] === true) {
          this.allSummonsSelected = false;
          //this.selectAllQuestionnaire();
          this.cancelJurorUpdate();
          //jurorAddressForm.reset();        
          this.SaveQuestionnaireValue();
          this.listJurors();
          this.sendJurorInfoToEmail(this.jurorStatusInfo);
          this.toDos = '';
          //this.GetQuestionnaireData(this.JurisdictionID);     

        }
      });
    }
  }

  updateResidenceAddress(chosenPlace) {
    if (this.residenceAddress == undefined || this.residenceAddress == '') {
      if (chosenPlace == undefined || chosenPlace == '') {
        this.infoTextAlert = 'Please enter new address';
        this.updateSuccessMessage = true;
        setTimeout(() => { this.updateSuccessMessage = false; }, 4000);
      } else {

        chosenPlace = chosenPlace.split(',');

        if (chosenPlace[1] === undefined || chosenPlace[1] === '') {
          this.infoTextAlert = 'Please enter a valid address.(ex: Address,city,state,zipcode) ';
          this.chosenPlace = '';
          this.updateErrorMessage = true;
          setTimeout(() => { this.updateErrorMessage = false; }, 4000);
        } else if (chosenPlace[2] === undefined || chosenPlace[2] === '') {
          this.infoTextAlert = 'Please enter a valid address.(ex: Address,city,state,zipcode) ';
          this.chosenPlace = '';
          this.updateErrorMessage = true;
          setTimeout(() => { this.updateErrorMessage = false; }, 4000);
        }
        else if (chosenPlace[3] === undefined || chosenPlace[3] === '') {
          this.infoTextAlert = 'Please enter a valid address.(ex: Address,city,state,zipcode) ';
          this.chosenPlace = '';
          this.updateErrorMessage = true;
          setTimeout(() => { this.updateErrorMessage = false; }, 4000);
        } else {
          const ResidenceAddress = chosenPlace[0];
          const City = chosenPlace[1];
          const State = chosenPlace[2];
          const Zipcode = chosenPlace[3];

          this.jurorAddressInfo = {
            JurorID: this.selected.jurorID,
            ResidenceAddress: ResidenceAddress,
            ResidenceState: State,
            ResidenceCity: City,
            ResidenceZipcode: Zipcode
          };

          this._eventService.updateAddress(this.jurorAddressInfo).subscribe(data => {
            if (data['success'] == true) {
              this.chosenPlace = '';
              this.selected.residenceAddress = ResidenceAddress;
              this.selected.residenceCity = City;
              this.selected.residenceState = State;
              this.selected.residenceZipCode = Zipcode;
              this.editaddress = false;
            }
          });
        }
      }
    } else {
      chosenPlace = this.residenceAddress.split(',');

      const ResidenceAddress = chosenPlace[0];
      const City = this.residencecity;
      const State = this.residencestate;
      const Zipcode = this.zipcode;

      this.jurorAddressInfo = {
        JurorID: this.selected.jurorID,
        ResidenceAddress: ResidenceAddress,
        ResidenceState: State,
        ResidenceCity: City,
        ResidenceZipcode: Zipcode
      };

      this._eventService.updateAddress(this.jurorAddressInfo).subscribe(data => {
        if (data['success'] == true) {
          this.chosenPlace = '';
          this.selected.residenceAddress = ResidenceAddress;
          this.selected.residenceCity = City;
          this.selected.residenceState = State;
          this.residenceAddress = '';
          this.editaddress = false;
        }
      });
    }
  }

  editaddressClick() {
    this.editaddress = true;
    this.chosenPlace = this.selected.residenceAddress.trim() + ',' + this.selected.residenceCity.trim() + ',' + this.selected.residenceState.trim() + ',' + this.selected.residenceZipCode;
  }

  removeaddress() {
    this.chosenPlace = '';
    this.editaddress = false;
  }
  setAddress(addrObj) {
    //We are wrapping this in a NgZone to reflect the changes to the object in the DOM.
    this.zone.run(() => {
      this.residenceAddress = addrObj.formatted_address;
      this.residencecity = addrObj.locality;
      this.residencestate = addrObj.admin_area_l1;
    });
  }
  cancelJurorUpdate() {
    this.editaddress = false;
    // this.selectedStatus = "Potential";
    // this.selectAllQuestionnaire();
    this.Ques_option = "--Select--";
    this.QuestionsList = [];
    this.questionnaireList = [];
    this.Ques_list = [];
    this.DistinctQuesList = [];
  }

  selectAllQuestionnaire() {
    if (this.allQuestionnaireSelected) {
      this.age = "1";
      this.felony = "0";
      this.english = "1";
      this.citizen = "1";
      this.civil = "0";
      this.resident = "1";

    } else {
      this.age = "";
      this.felony = "";
      this.english = "";
      this.citizen = "";
      this.civil = "";
      this.resident = "";

    }
  }
  isCheckQuestionarie(){
    if(this.selectedStatus === 'Potential'){
    if(((this.age == "1" || this.age == "0") && (this.felony == "0" || this.felony == "1") && (this.english == "1" || this.english == "0") 
    && (this.citizen == "1" || this.citizen == "0") && (this.civil == "1" || this.civil == "0") && (this.resident == "1" || this.resident == "0"))){
      return true;
    }
    else{
      return false;
    }
  }
    else{
      return true;
    }
  }

  hasFelonyChecked(status) {
    if (status === '1') {
      this.felonyBtn = true;
      this.civil = '';
    } else {
      this.felonyBtn = false;
      this.civil = '0';
    }
    this.isCheckQuestionarie();
  }

  civilChecked() {
    this.felonyBtn = false;
  }

  civilBtnchecked() {
    if (this.felony == '1') {
      return false
    }
    else {
      return true
    }
  }

  moveJurortoAssign(jurorId, statusId, status) {
    this.isLoading = true;
    if (this.MaxJurors <= this.assignedJurors.length) {
      this.isLoading = false;
      this.maxAlertText = 'Max Assigned Jurors Limit is ' + this.MaxJurors + ' only';
      this.maxAlert = true;
      setTimeout(() => { this.maxAlert = false; }, 4000);

    } else {
      let alreadyAssigned = 0;
      this._eventService.getJurorProfileInfo(jurorId).subscribe(data => {
        if (data.success === true) {
          const lengthtest = data.jurorActivityHistory;

          for (let i = 0; i <= lengthtest.length - 1; i++) {

            const getStatus = data.jurorActivityHistory[i];

            if (getStatus.statusDescription === 'Jury Served') {

              this.infoTextAlert = jurorId + ' already in Panel for another Event';
              alreadyAssigned = 1;
              this.assingedEventAlert = true;
              setTimeout(() => { this.assingedEventAlert = false; }, 4000);

            }
            if (alreadyAssigned === 0) {
              this.updateStatus(jurorId, statusId, status);
              this.allPotentialSelected = false;
              setTimeout(() => { this.isLoading = false; }, 4000);
              // this.isLoading=true;
            }
          }

        }

      });
      //setTimeout(() => { this.isLoading = false; }, 4000);
      //this.isLoading=false;

    }


  }

  moveJurortoPotientail(jurorId, statusId, status) {
    let alreadyAssigned = 0;
    this.isLoading = true;
    this._eventService.getJurorProfileInfo(jurorId).subscribe(data => {
      if (data.success === true) {
        const lengthtest = data.jurorActivityHistory;

        for (let i = 0; i <= lengthtest.length - 1; i++) {

          const getStatus = data.jurorActivityHistory[i];

          if (getStatus.statusDescription === 'Jury Served') {

            this.infoTextAlert = jurorId + ' already in Panel for another Event';
            alreadyAssigned = 1;
            this.assingedEventAlert = true;
            setTimeout(() => { this.assingedEventAlert = false; }, 4000);
          }
          if (alreadyAssigned === 0) {
            this.updateStatus(jurorId, statusId, status);
            this.allAssignedSelected = false;
            setTimeout(() => { this.isLoading = false; }, 4000);
          }
        }
      }
      // this.isLoading=false;
    });
  }

  moveJurortoPanel(jurorId, statusId, status) {
    let alreadyAssigned = 0;

    this.isLoading = true;
    this._eventService.getJurorProfileInfo(jurorId).subscribe(data => {
      if (data.success === true) {
        const lengthtest = data.jurorActivityHistory;

        for (let i = 0; i <= lengthtest.length - 1; i++) {

          const getStatus = data.jurorActivityHistory[i];

          if (getStatus.statusDescription === 'Jury Served') {

            this.infoTextAlert = jurorId + ' already in Panel for another Event';
            alreadyAssigned = 1;
            this.assingedEventAlert = true;
            setTimeout(() => { this.assingedEventAlert = false; }, 4000);
          }
          if (alreadyAssigned === 0) {
            this.updateStatus(jurorId, statusId, status);
            setTimeout(() => { this.isLoading = false; }, 4000);
          }
        }
      }
      //this.isLoading=false;
    });
  }

  updateStatus(jurorId, statusId, status) {
    this.isConfirmValue = '';
    const jurorID = jurorId;
    let statusID = statusId;


    if (status === 'Next') {
      switch (statusID) {
        case 5:
          statusID = 10;
          break;

        case 20:
          statusID = 35;
          break;
        case 35:
          statusID = 40;
          break;
      }
    }
    if (status === 'Return') {
      switch (statusID) {
        case 10:
          statusID = 5;
          break;
        case 35:
          statusID = 20;
          break;

        case 40:
          statusID = 35;
          break;
      }
    }
    let batchEventIds = [];
    if(this.displayallevent && this.displayallevent.length > 0){
      this.displayallevent.forEach(element => {
        batchEventIds.push(element.eventID)
      });
    }
    this.jurorStatusDetails = {
      JurorID: jurorID,
      EventID: this.eventID,
      StatusID: statusID,
      JurisdictionIDs: this.JurisdictionID,
      EventIDs: batchEventIds
    };

    this._eventService.updateStatus(this.jurorStatusDetails).subscribe(data => {
      if (data['success'] == true) {
        this.listJurors();
        this.closePrintSummonsPopup();
      }
    });

  }

  selectAllCheckBox(status, allSelectedActive) {
    if (status === '' || status === undefined) {
      for (let i = 0; i < this.summonsNotSentJurors.length; i++) {
        this.summonsNotSentJurors[i].isChecked = this.allSummonsSelected;
      }
      //for (let i = 0; i < this.summonsSentJurors.length; i++) {
      //  this.summonsSentJurors[i].isChecked = this.allSummonsSelected;
      //}
      this.moreThanOneSummons = this.allSummonsSelected;
    } else if (status === 'Summons Issued') {
      //for (let i = 0; i < this.summonsSentJurors.length; i++) {
      //  this.summonsSentJurors[i].isChecked = this.allSummonsSelected;
      //}
      this.moreThanOneSummons = this.allSummonsSelected;
    } else if (status === 'Summons Not Issued') {
      for (let i = 0; i < this.summonsNotSentJurors.length; i++) {
        this.summonsNotSentJurors[i].isChecked = this.allSummonsSelected;
      }
      this.moreThanOneSummons = this.allSummonsSelected;
    } else if (status === 'Potential') {

      for (let i = 0; i < this.potentialJurors.length; i++) {
        this.potentialJurors[i].isChecked = allSelectedActive;

      }
      this.moreThanOnePotential = allSelectedActive;
      this.allPotentialSelected = allSelectedActive;
    } else if (status === 'Assigned') {
      for (let i = 0; i < this.assignedJurors.length; i++) {
        this.assignedJurors[i].isChecked = allSelectedActive;
      }
      this.moreThanOneAssigned = allSelectedActive;
    }
  }

  selectCheckBox(status) {
    if (status === 'Summons Issued' || status === 'Summons Not Issued') {

      this.moreThanOneSummons = false;

      if (this.summonsSelectedFilter === '' || this.summonsSelectedFilter === undefined) {
        let if1 = false, if2 = false;

        for (let i = 0; i < this.summonsNotSentJurors.length; i++) {
          if (this.summonsNotSentJurors[i].isChecked) {
            this.moreThanOneSummons = true;
            if1 = true;
          }
          if (!this.summonsNotSentJurors[i].isChecked) {
            this.allSummonsSelected = false;
            if2 = true;
          }
          if (if1 && if2) { return; }
        }
        if (this.summonsSentJurors.length === 0 && !if1 && if2) { return; }
        for (let i = 0; i < this.summonsSentJurors.length; i++) {
          if (this.summonsSentJurors[i].isChecked) {
            this.moreThanOneSummons = true;
            if1 = true;
          }
          if (!this.summonsSentJurors[i].isChecked) {
            this.allSummonsSelected = false;
            if2 = true;
          }
          if (if1 && if2 || (i + 1 === this.summonsSentJurors.length && !if1 && if2)) { return; }

        }
      } else if (this.summonsSelectedFilter === 'Summons Issued') {
        let if1 = false, if2 = false;

        for (let i = 0; i < this.summonsSentJurors.length; i++) {
          if (this.summonsSentJurors[i].isChecked) {
            this.moreThanOneSummons = true;
            if1 = true;
          }
          if (!this.summonsSentJurors[i].isChecked) {
            this.allSummonsSelected = false;
            if2 = true;
          }
          if (if1 && if2 || (i + 1 === this.summonsSentJurors.length && !if1 && if2)) { return; }

        }
      } else if (this.summonsSelectedFilter === 'Summons Not Issued') {
        let if1 = false, if2 = false;

        for (let i = 0; i < this.summonsNotSentJurors.length; i++) {
          if (this.summonsNotSentJurors[i].isChecked) {
            this.moreThanOneSummons = true;
            if1 = true;
          }
          if (!this.summonsNotSentJurors[i].isChecked) {
            this.allSummonsSelected = false;
            if2 = true;
          }
          if (if1 && if2 || (i + 1 === this.summonsNotSentJurors.length && !if1 && if2)) { return; }
        }
      }
      this.allSummonsSelected = true;
    } else if (status === 'Potential') {
      this.moreThanOnePotential = false;
      let if1 = false, if2 = false;
      let count = 0;
      for (let i = 0; i < this.potentialJurors.length; i++) {
        if (this.potentialJurors[i].isChecked) {
          count++;
          if (count > 1) {
            this.moreThanOnePotential = true;
          }
          if1 = true;
        }
        if (!this.potentialJurors[i].isChecked) {
          this.allPotentialSelected = false;
          if2 = true;
        }
      }
      let ctrpotential = 0;
      for (let j = 0; j < this.potentialJurors.length; j++) {
        if (this.potentialJurors[j].isChecked) {
          ctrpotential = ctrpotential + 1;
        }
      }
      if (ctrpotential === this.potentialJurors.length) {
        this.allPotentialSelected = true;
      } else {
        this.allPotentialSelected = false;
      }
    } else if (status === 'Assigned') {
      this.moreThanOneAssigned = false;
      let if1 = false, if2 = false;

      for (let i = 0; i < this.assignedJurors.length; i++) {
        if (this.assignedJurors[i].isChecked) {
          this.moreThanOneAssigned = true;
          if1 = true;
        }
        if (!this.assignedJurors[i].isChecked) {
          this.allAssignedSelected = false;
          if2 = true;
        }
      }

      let ctrassigned = 0;
      for (let j = 0; j < this.assignedJurors.length; j++) {
        if (this.assignedJurors[j].isChecked) {
          ctrassigned = ctrassigned + 1;
        }
      }
      if (ctrassigned === this.assignedJurors.length) {
        this.allAssignedSelected = true;
      } else {
        this.allAssignedSelected = false;
      }
    }
  }

  onSummonsFilterChanged(data) {
    this.allSummonsSelected = false;
    this.allPotentialSelected = false;
    this.allAssignedSelected = false;
    // this.allPanelSelected = false;
    this.moreThanOnePotential = false;
    this.moreThanOneAssigned = false;
    this.moreThanOneSummons = false;

    for (let i = 0; i < this.summonsNotSentJurors.length; i++) {
      this.summonsNotSentJurors[i].isChecked = false;
    }
    for (let i = 0; i < this.summonsSentJurors.length; i++) {
      this.summonsSentJurors[i].isChecked = false;
    }

    this.SearchKeyword = this.summonsSelectedFilter;
  }

  printPayReceipt(printArea) {
    const printContents = document.getElementById(printArea).innerHTML;
    const popupWinindow = window.open('', '_blank', 'width=800,height=800,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
    popupWinindow.document.open();
    popupWinindow.document.write('<html><head><link href="event.component.css" rel="stylesheet" /></head><body onload="window.print()">' + printContents + '</body></html>');
    popupWinindow.document.close();
  }

  exportData() {
    const blob = new Blob([document.getElementById('myTable').innerHTML], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
    });
    FileSaver.saveAs(blob, 'JurorPaid.xls');
  }
  exportAssingedJurorData() {
    const blob = new Blob([document.getElementById('excelAssinged').innerHTML], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
    });
    FileSaver.saveAs(blob, 'JurorPanel.xls');
  }
  exportAssingedQuestionnaireJurorData() {
    const blob = new Blob([document.getElementById('excelAssignedQuestionnaire').innerHTML], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
    });
    FileSaver.saveAs(blob, 'AssignedJurorQuestionnaire.xls');
  }
  GetJurorIdList() {
    this.jurorIdList = [];
    this.assignedJurors.forEach(element => {
      this.jurorIdList.push(element.jurorID)
    });
    this.GetJurorQuestionnaireList(this.jurorIdList);

  }
  GetJurorQuestionnaireList(jurorIds) {
    this.assignedJurorQuesList = [];
    this._eventService.GetQuestionnaire(jurorIds).subscribe(data => {
      if (data != null) {
        this.assignedJurorQuesList = data;

      }
    });
  }
  exportPanelJurorData() {
    const blob = new Blob([document.getElementById('excelPanel').innerHTML], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
    });
    FileSaver.saveAs(blob, 'JurorPanel.xls');
  }
  jurorEvent(item) {
    let eventInfoList = [];
    const jurorEventDateList = [];
    this._eventService.getJurorEvents(item).subscribe(data => {
      if (data.success === true) {
        eventInfoList = data.eventInfoList;
        for (let i = 0; i < eventInfoList.length; i++) {
          jurorEventDateList.push(eventInfoList[i].startTime);
        }
        this.jurorEvents = Array.from(new Set(jurorEventDateList));
      }
    });
  }

  paymentReceipt(paystatus) {
    this._eventService.getPaymentDetails(paystatus).subscribe(data => {
      if (data.success === true) {
        this.paymentResults = data.eventPaymentInfo;
      }
    });
  }

  onChangeFilter(data) {
    this.filterText = data;
  }

  sortTableCols(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }


  RegularVal(){
    if(this.judgename){
      this.judgenamepopup = this.judgename;
    }
    else{
      if(!this.judgenamepopupDup){
        this.judgenamepopup = '';
      }
    
    }
    if(this.caseno){
      this.casenoo = this.caseno;
    }
    else{
      if(!this.casenooDup){
        this.casenoo = '';
      }
     
    }
    
    if(this.roomno){
      this.roomnoo = this.roomno;
    }
    else{
     if(!this.roomnooDup){
      this.roomnoo = '';
     }
    }
    if(this.defendant){
      this.defendantt = this.defendant;
    }
    else{
      if(!this.defendanttDup){
        this.defendantt = '';
      }
    }
    if(this.MaxJurors){
      this.MaxJurorss = this.MaxJurors;
    }
    else{
      if(!this.MaxJurorssDup){
        this.MaxJurorss = '';
      }
    }
    if(this.eventName){
      this.eventNamee = this.eventName;
    }
    else{
      if(!this.eventNameeDup){
        this.eventNamee = '';
      }
    }
  }

  updateEventDetails(data) {
    this.EditEventParam = {
      EventID: this.eventID,
      JurisdictionIDs: this.JurisdictionID,
      Title: data.eventTitle,
      StartDateTime: this.eventStartTime,
      EndDateTime: this.eventEndTime,
      JudgeName: data.judgeName,
      CaseNumber: data.caseNo,
      RoomNumber: data.roomNo,
      Defendant: data.defendant,
      MaxAssignedJurors: data.maxJurors,
      Loggedinuser: this.LoggedinUser
    };

    this._eventService.updateEventDetails(this.EditEventParam).subscribe(data => {
      if (data.success === true) {
        this.listJurors();
        this.showTextAlert = 'Event is successfully updated';
        this.showSuccessAlert = true;
        setTimeout(() => { this.showSuccessAlert = false; }, 5000);
        this.judgename = this.judgenamepopup;
        this.eventName = this.eventNamee;
        this.defendant = this.defendantt;
        this.roomno = this.roomnoo;
        this.caseno = this.casenoo;
        this.MaxJurors = this.MaxJurorss;
      } else {
        this.showTextAlert = 'Event is not updated successfully';
        this.showFailureAlert = true;
        setTimeout(() => { this.showFailureAlert = false; }, 5000);
      }
    });
    // $scope.maxAssignedWarning = false;
  }

  deletEvent() {
    const eventDetails = {
      EventID: this.eventID,
      LoggedInUser: this.LoggedinUser
    }
    this._eventService.deleteEvent(eventDetails).subscribe(data => {
      if (data.success === true) {
        // this.listJurors();
        this.showTextAlert = 'Event Deleted successfully';
        this.showSuccessAlert = true;
        setTimeout(() => {
          this.showSuccessAlert = false;
          this._router.navigate(['/home/calendar']);
        }, 5000);
      } else {
        this.showTextAlert = 'Event is not Deleted successfully';
        this.showFailureAlert = true;
        setTimeout(() => { this.showFailureAlert = false; }, 5000);
      }
    });
  }

  viewCount() {
    this._eventService.jurorCount(this.JurisdictionID).subscribe(data => {
      this.totalJurors = data.countrec;
    });
  }

  jurorRequest(requested) {
    if (requested > this.totalJurors) {
      this.showRequestJurorAlert = true;
    } else {
      this.showRequestJurorAlert = false;
    }
    this.viewCount();
  }

  getAllBatchEvent() {
    this._eventService.getAllBatchEvents(this.eventDetails).subscribe(data => {
      this.displayallevent = data.batchEventList;
      // this.batchid = this.displayallevent[0].batchID;
    });
  }
  emptValue(){
    this.batchidValue = '';
  }



  isCheckedBtn(){
 if(this.checkboxModel){
  this.batchidValue = this.displayallevent[0].batchID;
 }
 else{
   this.batchidValue = null;
 }
  }

  
  AddMoreJurors(checkboxModel) {

    if (checkboxModel === false || checkboxModel === undefined) {
      this.batchid == null;
    }
 this.isCheckedBtn();
    const jurorRequestDetails = {
      BatchID: this.batchidValue,
      EventID: this.eventID,
      JurisdictionID: this.JurisdictionID,
      TotalNoOfJurors: this.noOfJurors
    };
    const jurorData = JSON.parse(JSON.stringify(jurorRequestDetails));

    this._eventService.generateJurors(jurorData).subscribe(data => {
      if (data['success'] === true) {
        this.listJurors();
        this.showTextAlertt = 'Jurors are updated successfully';
        this.showSuccessAlertt = true;
        setTimeout(() => { this.showSuccessAlertt = false; }, 5000);
        this.checkboxModel = false;
        this.batchidValue = '';
      }
      else {
        alert(data['errorMessage']);
        this.showTextAlertt = 'Juror is not added successfully';
        this.showFailureAlertt = true;
        setTimeout(() => { this.showFailureAlertt = false; }, 5000);
      }

    });
  }

  showStatus(status) {
    if (status === 'Summonsed') {
      document.getElementById('SummonsedTab').style.display = 'block';
      document.getElementById('SummonsedActive').classList.add('redActive');
      document.getElementById('PotentialTab').style.display = 'none';
      document.getElementById('PotentialActive').classList.remove('redActive');
      document.getElementById('AssignedTab').style.display = 'none';
      document.getElementById('AssignedActive').classList.remove('redActive');
      document.getElementById('PanelTab').style.display = 'none';
      document.getElementById('PanelActive').classList.remove('redActive');

    }
    if (status === 'Potential') {
      document.getElementById('SummonsedTab').style.display = 'none';
      document.getElementById('SummonsedActive').classList.remove('redActive');
      document.getElementById('PotentialTab').style.display = 'block';
      document.getElementById('PotentialActive').classList.add('redActive');
      document.getElementById('AssignedTab').style.display = 'none';
      document.getElementById('AssignedActive').classList.remove('redActive');
      document.getElementById('PanelTab').style.display = 'none';
      document.getElementById('PanelActive').classList.remove('redActive');
    }
    if (status === 'Assigned') {
      document.getElementById('SummonsedTab').style.display = 'none';
      document.getElementById('SummonsedActive').classList.remove('redActive');
      document.getElementById('PotentialTab').style.display = 'none';
      document.getElementById('PotentialActive').classList.remove('redActive');
      document.getElementById('AssignedTab').style.display = 'block';
      document.getElementById('AssignedActive').classList.add('redActive');
      document.getElementById('PanelTab').style.display = 'none';
      document.getElementById('PanelActive').classList.remove('redActive');
    }
    if (status === 'Panel') {
      document.getElementById('SummonsedTab').style.display = 'none';
      document.getElementById('SummonsedActive').classList.remove('redActive');
      document.getElementById('PotentialTab').style.display = 'none';
      document.getElementById('PotentialActive').classList.remove('redActive');
      document.getElementById('AssignedTab').style.display = 'none';
      document.getElementById('AssignedActive').classList.remove('redActive');
      document.getElementById('PanelTab').style.display = 'block';
      document.getElementById('PanelActive').classList.add('redActive');
    }
  }

  getPayButtonText(Paystatus) {
    if (Paystatus === 'PAID' || Paystatus === 'Donation') {
      return 'Paid';
    } else {
      return 'Pay';
    }
  }

  disablePayButton(Paystatus) {
    if (Paystatus === 'PAID' || Paystatus === 'Donation') {
      return true;
    } else {
      return false;
    }
  }

  updateAllStatus(status) {
    this.printJurors = [];
    let checkedPotialJurorsCount = 0;
    this.potialtoAssignJurorCount = 0;

    for (let i = 0; i < this.potentialJurors.length; i++) {
      if (this.potentialJurors[i].isChecked) {
        checkedPotialJurorsCount = checkedPotialJurorsCount + 1;
      }
    }
    this.potialtoAssignJurorCount = checkedPotialJurorsCount + this.assignedJurors.length;

    if (status === 'Potential') {
      if (this.allPotentialSelected === true) {
        for (let i = 0; i < this.potentialJurors.length; i++) {
          if (this.MaxJurors < this.potialtoAssignJurorCount) {
            this.maxAllAlertText = 'Max Assigned Jurors Limit is ' + this.MaxJurors + ' only';
            //this.moreThanOnePotential = true;
            this.maxAllAlert = true;
            setTimeout(() => { this.maxAllAlert = false; }, 4000);
          } else {
            this.assign(this.potentialJurors[i].jurorID, 20);
          }
        }
      } else {
        for (let i = 0; i < this.potentialJurors.length; i++) {
          if (this.potentialJurors[i].isChecked) {
            if (this.MaxJurors < this.potialtoAssignJurorCount) {
              this.maxAllAlertText = 'Max Assigned Jurors Limit is ' + this.MaxJurors + ' only';
              //this.moreThanOnePotential = true;
              this.maxAllAlert = true;
              setTimeout(() => { this.maxAllAlert = false; }, 4000);
            } else {
              this.assign(this.potentialJurors[i].jurorID, 20);
            }
          }
        }
      }
      this.allPotentialSelected = false;
      this.listJurors();
    } else if (status === 'Assigned') {
      if (this.allAssignedSelected === true) {
        for (let i = 0; i < this.assignedJurors.length; i++) {
          this.assign(this.assignedJurors[i].jurorID, 35);
        }
      } else {
        for (let i = 0; i < this.assignedJurors.length; i++) {
          if (this.assignedJurors[i].isChecked) {
            this.assign(this.assignedJurors[i].jurorID, 35);
          }
        }
      }
      this.allAssignedSelected = false;
      this.listJurors();
    }
  }
  confirmJurorDelete() {
    if (this.isSingleCheck) {
      this.rescheduleJuror(this.selected.jurorID);
    } else {
      this.moveReschedule();
    }
  }
  moveReschedule() {
    let model = [];
    this.potentialJurors.forEach(x => {
      if (x.isChecked) {
        model.push({
          jurorID: x.jurorID,
          jurisdictionID: x.jurisdictionID,
          eventID: x.eventID
        })
      }
    })
    this._eventService.moveToReschedule(model).subscribe(data => {
      if (data['success'] == true) {
        this.listJurors();
        this.infoTextAlert = 'Selected Jurors Rescheduled Successfully';
        this.showSuccessReschedule = true;
        setTimeout(() => { this.showSuccessReschedule = false; }, 4000);
      }
    })
  }

  assign(jurorId, statusId) {
    //if (this.MaxJurors <= this.assignedJurors.length) {
    //  this.maxAlertText = 'Max Assigned Jurors Limit is ' + this.MaxJurors + ' only';
    //  this.maxAlert = true;
    //  setTimeout(() => { this.maxAlert = false; }, 4000);
    //} else
    //{
    let alreadyAssigned = 0;

    this._eventService.getJurorProfileInfo(jurorId).subscribe(data => {
      if (data.success === true) {
        const lengthtest = data.jurorActivityHistory;

        for (let i = 0; i <= lengthtest.length - 1; i++) {

          const getStatus = data.jurorActivityHistory[i];

          if (getStatus.statusDescription === 'Jury Served') {

            this.infoTextAlert = jurorId + ' already in Panel for another Event';
            alreadyAssigned = 1;
            this.assingedEventAlert = true;
            setTimeout(() => { this.assingedEventAlert = false; }, 4000);
          }
          if (alreadyAssigned === 0) {
            this.updateStatus(jurorId, statusId, 'Next');
          }
        }
      }
    });
    //}
  }

  inputChangedEvent(event, name) {

    if (name === 'OneWayMile') {
      this.onewayMiles = Number(event);
      if (this.onewayMiles > 0) {
        this.total();
        this.onewayMileError = false;
      } else {
        this.onewayMileError = true;
      }
    } else if (name === 'NoofDays') {
      this.noofDays = Number(event);
      this.total();
    } else if (name === 'PerMile') {
      this.permile = Number(event);
      this.total();
    } else if (name === 'DailyPay') {
      this.dailypay = Number(event);
      this.total();
    }
  }

  getJurorTotalPaid() {
    let total = 0;
    for (let i = 0; i < this.paidJurorList.length; i++) {
      if (this.paidJurorList[i].totalPayAmount) {
        total += this.paidJurorList[i].totalPayAmount;
      }
    }
    return total;
  }

  total() {
    let tempTotal;
    // let tempTotalroundoff;
    // let tempTotalMileCharge

    tempTotal = this.noofDays * (Number(this.dailypay) + 2 * this.onewayMiles * this.permile);

    // Round off Total Based on JurisdictionID

    if (this.roundOff === true) {
      if (this.JurisdictionID == 3) {
        this.totalAmount = Math.ceil(tempTotal);
        this.totalMileCharge = (Math.ceil((2 * this.onewayMiles * this.permile) * this.noofDays));
        this.totalServiceCharge = this.noofDays * this.dailypay;
      } else {
        this.totalAmount = Math.round(tempTotal);
        this.totalMileCharge = Math.round(2 * this.onewayMiles * this.permile);
        this.totalServiceCharge = this.noofDays * this.dailypay;
      }
    } else {
      if (this.JurisdictionID == 3) {
        const temptotalAmount = parseFloat(tempTotal);
        this.totalAmount = temptotalAmount.toFixed(3);
        this.totalMileCharge = (2 * this.onewayMiles * this.permile).toFixed(3);
        this.totalServiceCharge = this.noofDays * this.dailypay;
      } else {
        const temptotalAmount = parseFloat(tempTotal);
        this.totalAmount = temptotalAmount.toFixed(2);
        this.totalMileCharge = ((2 * this.onewayMiles * this.permile)* this.noofDays).toFixed(2);
        this.totalServiceCharge = this.noofDays * this.dailypay;
      }
    }
    return this.totalAmount;
  }

  submit(paystatus) {

    let jurorAmount;
    let donationAmount;

    switch (paystatus) {
      case 0:
        // full paid
        jurorAmount = this.totalAmount;
        donationAmount = 0;
        break;
      case 1:
        // full donated
        jurorAmount = 0;
        donationAmount = this.totalAmount;
        break;

      case 2:
        // pay service
        jurorAmount = this.totalServiceCharge;
        donationAmount = this.totalMileCharge;
        break;
      case 3:
        // pay miles
        jurorAmount = this.totalMileCharge;
        donationAmount = this.totalServiceCharge;
        break;
      case 4:
        // donate $10
        jurorAmount = this.totalAmount - 10;
        donationAmount = 10;
    }

    const jurorPayDetails = {
      JurorID: this.selected.jurorID,
      EventID: this.eventID,
      JurisdictionID: this.JurisdictionID,
      NoOfDays: this.noofDays,
      JurorDailyPay: this.dailypay,
      PerMileFare: this.permile,
      OnewayMiles: this.onewayMiles,
      JurorAmount: jurorAmount,
      DonationAmount: donationAmount,
      TotalAmount: this.totalAmount,
      Created: this.LoggedinUser
    };

    this._eventService.insertJurorPayment(jurorPayDetails).subscribe(data => {
      if (data['success'] === true) {
        this.listJurors();
      }
    });
  }

  jurorPayNow(item) {
    this.onewayMiles = '';
    this.noofDays = 1;
    this.totalAmount = 0.00;

    this.permile = this.pricePerMile;
    this.dailypay = this.paymentPerDay;

    this.selected = item;
    const startAddr = this.selected.residenceAddress + ' ' + this.selected.residenceCity + ' ' + this.selected.residenceState;

    this.getOneWayMiles(startAddr);
  }

  getOneWayMiles(startAddr) {

    this._eventService.getJurisdictionDetails(this.JurisdictionID).subscribe(data => {
      const endAddr = data.listJurisdictions[0].jurisdictionAddress;

      this.origin = startAddr;
      // this.destination = { lat: 47.483148, lng: -122.194544 }      
      var jurisdID = data.listJurisdictions[0].jurisdictionCode.trim();
      var JurisCode = jurisdID.slice(0, 3);
      //everytime we need to add the new lat and lng based on their jurisdiction address
      if(JurisCode == 'DMM')
    {
      this.destination = { lat: 47.4076286, lng: -122.3193998 };
    }
    else if (JurisCode=='TKM')
    {
      this.destination = { lat: 47.46837913532947, lng: -122.28761607191552 };
    }
    else if(JurisCode=="FED")
    {
      this.destination={lat:47.30419398343241,lng:-122.3264562682213};
    }
    else if(JurisCode=="BSM")
    {
      this.destination={lat:47.17584739476982,lng:-122.18361093144982};
    }
    else{
       this.destination = { lat: 47.483148, lng: -122.194544 };
     }

      const jurorMiles = {
        start: startAddr.replace(/\#/g, '%23'),
        end: endAddr
      };

      this._eventService.getMilesOfJuror(jurorMiles).subscribe(data => {
        if (data <= 0) {
          this.infoTextAlert = 'Please update a valid address for this juror from juror profile page or manually enter one way mile';
          this.onewayMileError = true;
        }
        this.onewayMiles = data;
        this.total();
      });
    });
  }

  printMultipleSummons() {
    var j = 0;
    for (var i = 0; i < this.summonsNotSentJurors.length; i++) {
      if (this.summonsNotSentJurors[i].isChecked == true) {
        j = j + 1;
      }
    }
    //if (j > 300) {
    //  alert("Please select 300 jurors only");
    //  this.modelTrue = false;
    //  //setTimeout(() => { this.errorMsg = false; }, 5000);    
    //  //return true;
    //}
    // else {
    this.preview1 = false;
    this.iFrameSummonsSrc = '';
    this.modelTrue = true;
    this.showMultiPrint = true;
    this.showSinglePrint = false;
    this.selectedvalue = [];
    this.isLoading = true;
    //this.summonsNotSentJurors = this.summonsSentJurors.concat(this.summonsNotSentJurors);
    for (var i = 0; i < this.summonsNotSentJurors.length; i++) {
      if (this.summonsNotSentJurors[i].isChecked) {
        this.selectedvalue.push({
          JuridictionId: this.summonsNotSentJurors[i].jurisdictionID,
          JurerId: this.summonsNotSentJurors[i].jurorID,
          JurerName: this.summonsNotSentJurors[i].firstName.trim() + " " + this.summonsNotSentJurors[i].lastName,
          Date: this.summonsNotSentJurors[i].startDateTime,
          ResidenceAddress: this.summonsNotSentJurors[i].residenceAddress.trim(),
          ResidenceCity: this.summonsNotSentJurors[i].residenceCity.trim(),
          ResidenceState: this.summonsNotSentJurors[i].residenceState.trim(),
          ResidenceZipCode: this.summonsNotSentJurors[i].residenceZipCode.trim(),
          LoggedinUserId: this.LoggedinUserID.trim(),
          Eventid: this.eventID,
          Title: this.summonsNotSentJurors[i].title         
        });
      }
    }
    this._eventService.printSummonsAll(this.selectedvalue).subscribe(data => {
      if (data == "Invalid") {
        alert("Please check Active Template/Doc to DB Mapping");
      }
      else if (data == "Failure") {
        alert("There is an System failure while print, please contact the Application Admin!!!");
      }
      else {
        // if (this.selectedvalue.length > 150) {
        //console.log("Summons File List : ", data);
        this.count = 1;
        this.splitNames = data.split(',');
        if (this.splitNames.length == 2) {
          this.disableNext = true;
          this.disableBack = true;
        } else {
          this.disableNext = false;
          this.disableBack = true;
        }
        this.urlFormation(this.count);

        //this.updatedCertificateName = splitNames[0];
        //this.updatedCertificateName1 = splitNames[1];
        //var rand = Math.floor((Math.random() * 1000000) + 1);
        //var path = this._baseAzureURL + this.updatedCertificateName + "?uid=" + rand;
        //this.isLoading = false;
        //this.iframePath = path;
        // setTimeout(function () {
        //this.iFrameSummonsSrc = this._sanitizer.bypassSecurityTrustResourceUrl(this.iframePath);
        //this.preview = true;
        // }, 5000);
        //this.iFrameSummonsSrc = this._sanitizer.bypassSecurityTrustResourceUrl(this.iframePath);
        //this.preview = true;
        // }
        //else {
        //  this.updatedCertificateName = data;
        //  var rand = Math.floor((Math.random() * 1000000) + 1);
        //  var path_1 = this._baseAzureURL + this.updatedCertificateName + "?uid=" + rand;
        //  this.isLoading = false;
        //  setTimeout(function () {
        //    this.iFrameSummonsSrc1 = this._sanitizer.bypassSecurityTrustResourceUrl(path_1);
        //    this.preview = true;
        //  }, 3000);
        //}
      }
    });
    //}


  }
  //setPage(page: number) {
  //  if (page < 1 || page > this.pager.totalPages) {
  //    return;
  //  }
  //  // get pager object from -
  //  this.pager = this._pagerService.getPager(this.splitNames.length-1, page);
  //  // get current page of items
  //  this.pagedItems = this.splitNames.slice(this.pager.startIndex, this.pager.endIndex + 1);
  //}

  // urlFormation(pageNo) {
  //   this.isLoading = true;
  //   var baseUrl = this.splitNames[pageNo - 1];
  //   var rand = Math.floor((Math.random() * 1000000) + 1);
  //   var path = this._baseAzureURL + baseUrl + "?uid=" + rand;
  //   this.iFrameSummonsSrc = this._sanitizer.bypassSecurityTrustResourceUrl(path);
  //   if (this.selectedvalue.length >= 0 && this.selectedvalue.length <= 50) {
  //     setTimeout(() => {
  //       this.iFrameSummonsSrc = this.iFrameSummonsSrc;
  //       this.preview = true;
  //       this.SetIframeBackNext = true;
  //       this.isLoading = false;
  //     }, 9000);
  //   }
  //   if (this.selectedvalue.length >= 51 && this.selectedvalue.length <= 70) {
  //     setTimeout(() => {
  //       this.iFrameSummonsSrc = this.iFrameSummonsSrc;
  //       this.preview = true;
  //       this.SetIframeBackNext = true;
  //       this.isLoading = false;
  //     }, 10000);
  //   }
  //   else if (this.selectedvalue.length >= 71 && this.selectedvalue.length <= 100) {
  //     setTimeout(() => {
  //       this.iFrameSummonsSrc = this.iFrameSummonsSrc;
  //       this.preview = true;
  //       this.SetIframeBackNext = true;
  //       this.isLoading = false;
  //     }, 15000);
  //   }
  //   else if (this.selectedvalue.length >= 101 && this.selectedvalue.length <= 150) {
  //     setTimeout(() => {
  //       this.iFrameSummonsSrc = this.iFrameSummonsSrc;
  //       this.preview = true;
  //       this.SetIframeBackNext = true;
  //       this.isLoading = false;
  //     }, 20000);
  //   }
  //   else if (this.selectedvalue.length >= 151 && this.selectedvalue.length <= 200) {
  //     setTimeout(() => {
  //       this.iFrameSummonsSrc = this.iFrameSummonsSrc;
  //       this.preview = true;
  //       this.SetIframeBackNext = true;
  //       this.isLoading = false;
  //     }, 25000);
  //   }
  //   else if (this.selectedvalue.length >= 201 && this.selectedvalue.length <= 250) {
  //     setTimeout(() => {
  //       this.iFrameSummonsSrc = this.iFrameSummonsSrc;
  //       this.preview = true;
  //       this.SetIframeBackNext = true;
  //       this.isLoading = false;
  //     }, 30000);
  //   }
  //   else {
  //     setTimeout(() => {
  //       this.iFrameSummonsSrc = this.iFrameSummonsSrc;
  //       this.preview = true;
  //       this.SetIframeBackNext = true;
  //       this.isLoading = false;
  //     }, 35000);
  //   }

  // };

  urlFormation(pageNo) {
    
   this.preview = true;
   this.SetIframeBackNext = true;
   this.isLoading = false;
   var baseUrl = this.splitNames[pageNo - 1];
   var rand = Math.floor((Math.random() * 1000000) + 1);
   var path = this._baseAzureURL + baseUrl + "?uid=" + rand;

   this.iframePath = path;
   this.iFrameSummonsSrc = this._sanitizer.bypassSecurityTrustResourceUrl(this.iframePath);

  //  window.open(this.iframePath, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
   console.log(this.iFrameSummonsSrc);
  };

  reload() {
    // console.log('123' + this.iframe);
    // const win: Window = this.iframe.nativeElement.contentWindow;
    // const doc: Document = win.document;
    // console.log('1234' + doc);
    this.urlFormation(this.count);
  }

  back() {
    if (this.count > 1) {
      this.count = this.count - 1;
    }
    if (this.count == 1) {
      this.disableNext = false;
      this.disableBack = true;
    } else if (this.count > 1 && this.count < this.splitNames.length - 1) {
      this.disableNext = false;
      this.disableBack = false;
    }
    this.urlFormation(this.count);
    //this.iFrameSummonsSrc = this._sanitizer.bypassSecurityTrustResourceUrl(this.iframePath);
    //this.disableNext = false;
    //this.disableBack = true;
    this.clicks -= 1;
  };
  next() {
    if (this.count < this.splitNames.length - 1) {
      this.count = this.count + 1;
    }
    if (this.count == this.splitNames.length - 1) {
      this.disableNext = true;

      this.disableBack = false;
    } else if (this.count > 1 && this.count < this.splitNames.length - 1) {
      this.disableNext = false;
      this.disableBack = false;
    }
    this.urlFormation(this.count);
    //var rand1 = Math.floor((Math.random() * 1000000) + 1);
    //this.disableBack = false;
    //this.disableNext = true;
    //var path1 = this._baseAzureURL + this.updatedCertificateName1 + "?uid=" + rand1;
    //this.iframePath1 = path1;
    //this.preview = true;
    //this.iFrameSummonsSrc = this._sanitizer.bypassSecurityTrustResourceUrl(this.iframePath1);
    this.clicks += 1;
    // document.getElementById("clicks").innerHTML = clicks;

  };
  printSingleSummons(data) {
    this.modelTrue = true;
    this.showSinglePrint = true;
    this.showMultiPrint = false;
    this.iFrameSummonsSrc = '';

    this.jurorID = data.jurorID;
    this.statusID = data.statusID;
    this.eventName = data.title

    const printSingleSummonsDetails = {
      jurisdictionIDs: this.JurisdictionID,
      jurorID: data.jurorID,
      eventID: data.eventID,
      LoggedinUserID: this.LoggedinUserID,
      Title: data.title
    };

    this._eventService.printSummons(printSingleSummonsDetails).subscribe(data => {
      if (data == "Invalid") {
        alert("Please check Active Template/Doc to DB Mapping");
      } else if (data == "Failure") {
        alert("There is an System failure while print, please contact the Application Admin!!!");
      } else {
        this.updatedCertificateName = data;
        const rand = Math.floor((Math.random() * 1000000) + 1);
        const path = this._baseAzureURL + this.updatedCertificateName + "?uid=" + rand;
        this.iFrameSummonsSrc = this._sanitizer.bypassSecurityTrustResourceUrl(path);
        this.preview = true;
      }
    });
  }

  isSingleJuorAvailableFn(){
    return !(this.summonsTotalJurors.filter(x=>x.statusDescription != 'Summons Issued').length > 0);
  }

  updateMultipleSummons() {
    for (let i = 0; i < this.eventResults.length; i++) {
      if (this.eventResults[i].isChecked) {
        const JurorID = this.eventResults[i].jurorID;
        const EventID = this.eventResults[i].eventID;
        const BatchID = this.eventResults[i].batchID;
        const Title = this.eventResults[i].Title;

        const StatusID = 10;
        const JurisdictionID = this.eventResults[i].jurisdictionID;
        this.selectedItem = { JurorID: JurorID, EventID: EventID, StatusID: StatusID, JurisdictionID: JurisdictionID, BatchID: BatchID,Title: Title  };
        this.multiSelectedSummons.push(this.selectedItem);
      }
    }

    this._eventService.updateMultiStatus(this.multiSelectedSummons).subscribe(data => {
      if (data['success'] === true) {
        this.listJurors();
        this.multiSelectedSummons = [];
        this.allSummonsSelected = false;
        this.closePrintSummonsPopup();
      }
    });
  }

  rescheduleJuror(jurorId) {
    let checkalreadyassigned = 0;

    this._eventService.getJurorProfileInfo(jurorId).subscribe(data => {
      if (data.success === true) {
        const lengthtest = data.jurorActivityHistory;

        for (let i = 0; i <= lengthtest.length; i++) {

          const getStatus = data.jurorActivityHistory[i];

          if (getStatus.statusDescription === 'Assigned' || getStatus.statusDescription === 'Jury Served') {
            if (getStatus.statusDescription === 'Assigned') {
              this.infoTextAlert = jurorId + ' already assinged in event';
              checkalreadyassigned = 1;
              this.assingedEventAlert = true;
              setTimeout(() => { this.assingedEventAlert = false; }, 4000);
            }
            if (getStatus.statusDescription === 'Assigned') {
              this.infoTextAlert = jurorId + ' already in Panel for another Event';
              checkalreadyassigned = 1;
              this.assingedEventAlert = true;
              setTimeout(() => { this.assingedEventAlert = false; }, 4000);
            }
          }
          if (checkalreadyassigned === 0) {
            this.reschedule(jurorId);
          }
        }
      }
    });
  }

  reschedule(jurorId) {
    const jurorID = jurorId;

    const reschedulejurorDetails = {
      JurorID: jurorID,
      JurisdictionIDs: this.JurisdictionID,
      Loggedinuser: this.LoggedinUser
    };

    this._eventService.rescheduleJuror(reschedulejurorDetails).subscribe(data => {
      if (data['success'] == true) {
        this.listJurors();
        this.infoTextAlert = jurorId + ' Rescheduled Successfully';
        this.showSuccessReschedule = true;
        setTimeout(() => { this.showSuccessReschedule = false; }, 4000);
      }
    });
  }

  assignJurors(data) {
    this.datevallst = [];
    $('#iframecertificate').attr('src', $('#iframecertificate').attr('src'));
    // document.getElementById("iframecertificate").style.display = "none";
    this.printJurors = [];
    this.printJurors.push(data);
    if (data && data.listDates) {
      data.listDates.forEach(element => {
        const dtval = this.datePipe.transform(element, 'yyyy-MM-dd');
        this.datevallst.push(dtval);
      });
    }
    else {
      const dtval = this.datePipe.transform(data.startDateTime, 'yyyy-MM-dd');
      this.datevallst.push(dtval);
    }
    this.defaultdateval = this.datePipe.transform(data.startDateTime, 'yyyy-MM-dd');
  }

  getPrintCertificate(data) {
    console.log(data)
    let formData = {
      jurorID: data.jurorID,
      jurisdictionID: data.jurisdictionID,
      eventID: data.eventID
    }
    this._eventService.getPrintCertificate(formData).subscribe(res => {
      if (res && res.length > 0) {
        data = {
          ...data,
          listDates: [...res[0].listofDate.split(",")]
        }

      }
      this.assignJurors(data);
    })
  }

  printCertificate() {
    this.previewcerti = 'priviewed';
    // document.getElementById("iframecertificate").style.display = "block";
    this.selecteddateitem = '';0
    const printCertificateDetails = {
      listJurorEvent: this.printJurors,
      listDates: this.datevallst
    };
    var printCertificateJsonData = JSON.parse(JSON.stringify(printCertificateDetails));
    this._eventService.printCertificate(printCertificateDetails).subscribe(data => {
      if (data) {
        this._sanitizer.bypassSecurityTrustResourceUrl
        this.updatedCertificateName = data;
        const rand = Math.floor((Math.random() * 1000000) + 1);
        const path = this._baseAzureURL + this.updatedCertificateName + "?uid=" + rand;
        // this.iFrameSrc = templatedazurepath + "?uid=" + rand;
        this.iFrameSrc = this._sanitizer.bypassSecurityTrustResourceUrl(path);
        this.previewCertificate = true;
      }
    });
  }

  closePrintSummonsPopup() {
    this.iFrameSummonsSrc += '';

    this._eventService.deleteUpdatedTemplateVersion(this.updatedCertificateName).subscribe(data => {
      if (data.success == true) {
        for (var i = 0; i < this.summonsNotSentJurors.length; i++) {
          if (this.summonsNotSentJurors[i].isChecked == true) {
            this.summonsNotSentJurors[i].isChecked = false;
            this.allSummonsSelected = false;
          }
        }
        this.moreThanOneSummons = false;
        this.preview = false;
      }
    });
  }

  closePrintCertificatePopup() {
    this.previewcerti = '';
    this.datevallst = [];
    this.iFrameSrc = '';
    this.InvaliddateMessage = '';
    this.selecteddateitem = '';
    this._eventService.deleteUpdatedTemplateVersion(this.updatedCertificateName).subscribe(data => {
      if (data.success == true) {
        this.previewCertificate = false;
      }
    });
  }

  removeDates(item) {
    if (this.defaultdateval === item) {
      alert('Can not Remove Default Date');
    } else {
      const index = this.datevallst.indexOf(item);
      this.datevallst.splice(index, 1);
    }
  }

  adddate(value) {
    this.Invaliddate = false;

    const datevalue = this.datePipe.transform(value, 'yyyy-MM-dd');

    if (value === '' || value == null || value === undefined) {
      this.Invaliddate = true;
      this.InvaliddateMessage = 'Please select a date';
    } else if (this.datevallst.indexOf(datevalue) === -1) {
      this.datevallst.push(this.datePipe.transform(value, 'yyyy-MM-dd'));
    } else {
      this.Invaliddate = true;
      this.InvaliddateMessage = 'Date is already selected';
    }
    // ascending order the array inorder to show it in certificate
    // this.datevallst = $filter('orderBy')($scope.datevallst, false)
  }

  closeJurorList() {
    this.p = 1;
    this.filterText = '';
    this.searchJurorText = '';
    // this.key = '';
  }

  phonePatternCheck(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    else {
      if (event.currentTarget.name == 'homephone') {
        let eles = ((document.getElementById("homephone") as HTMLInputElement).value);
        if (eles != "") {
          let ele = eles.split("-").join("");    // Remove dash (-) if mistakenly entered.
          if (((ele).length) <= 8) {
            let finalVal = (ele).match(/.{1,3}/g).join("-");
            ((document.getElementById("homephone") as HTMLInputElement).value) = finalVal;
          }
          //if (((ele).length) >= 8) {
          //  let finalVal = (ele).match(/.{13,13}/g).join("");
          //  ((document.getElementById("homephone") as HTMLInputElement).value) = finalVal;
          //}
        }
      }
      else if (event.currentTarget.name == 'workphone') {
        let eles = ((document.getElementById("workphone") as HTMLInputElement).value);
        if (eles != "") {
          let ele = eles.split("-").join("");    // Remove dash (-) if mistakenly entered.
          if (((ele).length) <= 8) {
            let finalVal = (ele).match(/.{1,3}/g).join("-");
            ((document.getElementById("workphone") as HTMLInputElement).value) = finalVal;
          }
          //if (((ele).length) >= 8) {
          //  let finalVal = (ele).match(/.{13,13}/g).join("");
          //  ((document.getElementById("workphone") as HTMLInputElement).value) = finalVal;
          //}
        }

      }

    }
  }
  GetQuestionnaireData(jurisID) {
    this.Ques_list = [];
    var questionnaireDetails = {
      QuestionID: 0,
      JurisdictionID: jurisID,
      QuestionName: null,
      OptionSet_ID: 0,
      Query_Type: 'GET'
    }
    this._eventService.ManageQuestionnaire(questionnaireDetails).subscribe(data => {
      if (data.success == true && data.statusCode == 0) {

        this.questionnaireList = [];
        this.optionSetList = [];
        this.OptionsList = [];
        this.questionnaireList = data.questionnaireDTOList.filter(x => x.jurisdictionID === jurisID);
        for (var val of this.questionnaireList) {
          this.listNames = {
            id: val.questionID,
            name: val.listNames.split('/')
          };
          this.OptionsList.push(this.listNames);
          this.optionsListLength = (this.OptionsList.length) + 1;
        }
        this.toDos.length = this.questionnaireList.length;
      }
    });
  }
  onChange(OptionValue, QuestionID) {
    // this.QuestionsList=[];
    this.questionValues = {
      QuestionaireID: QuestionID,
      Ques_Answer: OptionValue
    };
    if (OptionValue == "None" || OptionValue == "--Select--" || OptionValue == null || OptionValue == undefined) {
    }
    else {
      this.QuestionsList.push(this.questionValues);
    }
  }
  trackByIndex(index: number, obj: any): any {
    return index;
  }
  SaveQuestionnaireValue() {
    if (this.Ques_list.length > 0) {
      this.DistinctQuesList = this.Ques_list.filter(
        (thing, i, arr) => arr.findIndex(t => t.QuestionaireID === thing.QuestionaireID) === i);

      //else if (this.Ques_list.length == 0)
      //{
      //  this.DistinctQuesList = this.QuestionsList.filter(
      //    (thing, i, arr) => arr.findIndex(t => t.QuestionaireID === thing.QuestionaireID) === i);

      //}

      var quesDetails = {
        JurorID: this.jurorAccountID,
        EventID: this.eventID,
        JurisdictionID: this.JurisdictionID,
        questionaireDTOs: this.DistinctQuesList
      }
      this._eventService.SaveQuestionnaire(quesDetails).subscribe(res => {
        if (res.success == true && res.statusCode == 0) {
          //alert("Data saved sucessfully!");
          // this.sendJurorInfoToEmail(this.jurorStatusInfo);
          //localStorage.clear();
          //this._router.navigate(['/']);
        }
      });
    }

  }
  sendJurorInfoToEmail(dataParams) {
    this._eventService.sendJurorDataToEmail(dataParams).subscribe(res => {
      // console.log("juror email");

    });
  }
  GetQuestionnaireValue(reasonslist) {
    this.Ques_list = [];
    if(this.age == '0' || this.resident == '0' || this.citizen == '0' || this.english == '0'){
    this.selectedStatus = 'PermanentlyExcused';
    }
    if(this.age == "1" && this.felony == "1" && this.english == "1" 
    && this.citizen == "1" && this.civil == "0" && this.resident == "1"){
      this.selectedStatus = 'Commited Felony';
    }
    this.isConfirmValue = "#confirm";
    for (var val of this.questionnaireList) {
      let optionValue = ((document.getElementById("None_" + val.questionID) as HTMLInputElement).value);
      if (optionValue != "") {
        var questionData = {
          QuestionaireID: parseInt(val.questionID),
          Ques_Answer: optionValue
        };
        this.Ques_list.push(questionData);
      }
    }
    if (this.QuestionsList.length > 0) {
      for (var val of this.QuestionsList) {
        var questionDropdownData = {
          QuestionaireID: parseInt(val.QuestionaireID),
          Ques_Answer: val.Ques_Answer
        };
        this.Ques_list.push(questionDropdownData);
      }
      //this.Ques_list.push(this.QuestionsList);
    }
  }

}



