/// <reference types="@types/googlemaps" />

import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JurorprofileService } from '../jurorprofile/jurorProfile.service';

@Component({
  selector: 'app-jurorprofile',
  templateUrl: './jurorprofile.component.html',
  styleUrls: ['./jurorprofile.component.css']
})
export class JurorprofileComponent implements OnInit {
  JurorID;
  showDiv;
  statusMessage;
  statusInfoMessage;
  fullResidenceAddress;
  residenceAddress;
  residencecity;
  residencestate;
  homePhone;
  workPhone;
  jurorEmail;
  phonePattern;
  emailPattern;
  updatedAddress;
  jurorBasicInfo: any[] = [];
  jurorActivityHistory: any = [];
  jurorPaymentHistory: any = [];  
  quesList: any = [];
  jurorQuesList : any =[];
  jurorDetails: any;
  age; resident; citizen; communication; felony; civil;
  lat: number = 47.483148;
  lng: number = -122.194544;

  origin: any;
  destination: any;
  activeDelete: boolean;

  constructor(private _avRoute: ActivatedRoute, private _router: Router, private _jurorProfileService: JurorprofileService, private zone: NgZone) {
    this.JurorID = this._avRoute.snapshot.params['id'];
    this.showDiv = true;
    this.statusMessage = false;
    this.phonePattern = /^[(]{0,1}[0-9]{3}[)\.\- ]{0,1}[0-9]{3}[\.\- ]{0,1}[0-9]{4}$/;
    this.emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
  }

  ngOnInit() {
    this.getJurorProfile();
  }

  getJurorProfile() {
    this._jurorProfileService.GetJurorProfileInfo(this.JurorID).subscribe(data => {
      this.jurorBasicInfo = data.jurorBasicInfo;
      this.age = data.jurorBasicInfo.isOfEligibleAge;
      this.resident = data.jurorBasicInfo.isAResidentOfCounty;
      this.citizen = data.jurorBasicInfo.isACitizenOfUS;
      this.communication = data.jurorBasicInfo.isAbleToCommunicateInEnglish;
      this.felony = data.jurorBasicInfo.hasCommitedFelony;
      this.civil = data.jurorBasicInfo.civilRightsRestored;
      if(data.jurorBasicInfo.statusDescription == "Summons Issued" ||data.jurorBasicInfo.statusDescription == "Summons Not Issued" )
       {       
          this.age = 'Not Updated';
          this.resident = 'Not Updated';
          this.citizen = 'Not Updated';
          this.communication = 'Not Updated';
          this.felony = 'Not Updated';
          this.civil = 'Not Updated';

      }
      if (this.age === true) { this.age = 'Yes'; } else if (this.age === false) { this.age = 'No';   }      
      if (this.resident === true) { this.resident = 'Yes'; } else if (this.resident === false) {
        this.resident = 'No';
      }       
      if (this.citizen === true) { this.citizen = 'Yes'; } else if (this.citizen === false) {
        this.citizen = 'No';
      }      
      if (this.communication === true) { this.communication = 'Yes'; } else if (this.communication === false) {
        this.communication = 'No';
      }      
      if (this.felony === true) {this.felony = 'Yes'; } else if (this.felony === false) {
        this.felony = 'No';
      }      
      if (this.civil === true) { this.civil = 'Yes';  } else if (this.civil === false) {
        this.civil = 'No';
      } 
      this.activeDelete = false; 
      this.jurorActivityHistory = data.jurorActivityHistory;
      this.jurorPaymentHistory = data.jurorPaymentHistory;
      this.jurorActivityHistory.forEach(element => {
        if(element.isActive){
          this.activeDelete = true;
        }
      });
      this.getDirection();
    });
  }

  getDirection() {
    const fullAddress = this.jurorBasicInfo['residenceAddress'].trim() + ',' + this.jurorBasicInfo['residenceCity'].trim() + ',' + this.jurorBasicInfo['residenceState'].trim();
    this.origin = fullAddress;
    var jurisdID = this.jurorBasicInfo['jurorAccountID'].trim();
    var JurisCode = jurisdID.slice(0, 3);
    //everytime we need to add the new lat and lng based on their jurisdiction address
    if(JurisCode == 'DMM')
    {
      this.destination = { lat: 47.4076286, lng: -122.3193998 };
    }
    else if (JurisCode=='TKM')
    {
      this.destination = { lat: 47.46837913532947, lng: -122.28761607191552 };
    }
    else if(JurisCode=="FED")
    {
      this.destination={lat:47.30419398343241,lng:-122.3264562682213};
    }
    else if(JurisCode=="BSM")
    {
      this.destination={lat:47.17584739476982,lng:-122.18361093144982};
    }
    else{
       this.destination = { lat: 47.483148, lng: -122.194544 };
     }
  }

  mapFullAddress() {
    this.fullResidenceAddress = this.jurorBasicInfo['residenceAddress'].trim() + ',' + this.jurorBasicInfo['residenceCity'].trim() + ',' + this.jurorBasicInfo['residenceState'].trim();
    this.homePhone = this.jurorBasicInfo['phone'];
    this.workPhone = this.jurorBasicInfo['workPhone'];
    this.jurorEmail = this.jurorBasicInfo['email'];
  }

  updateJurorProfile() {
    if (this.residenceAddress === undefined || this.residenceAddress == null) {
      this.updatedAddress = this.fullResidenceAddress.split(',');
      this.jurorBasicInfo['residenceAddress'] = this.updatedAddress[0];
      this.jurorBasicInfo['residenceCity'] = this.updatedAddress[1];
      this.jurorBasicInfo['residenceState'] = this.updatedAddress[2];
    } else {
      this.updatedAddress = this.residenceAddress.split(',');
      this.jurorBasicInfo['residenceAddress'] = this.updatedAddress[0];
      this.jurorBasicInfo['residenceCity'] = this.residencecity;
      this.jurorBasicInfo['residenceState'] = this.residencestate;
    }

    this.jurorDetails = {
      JurorID: this.JurorID,
      ResidenceAddress: this.jurorBasicInfo['residenceAddress'],
      ResidenceCity: this.jurorBasicInfo['residenceCity'],
      ResidenceState: this.jurorBasicInfo['residenceState'],
      County: this.jurorBasicInfo['county'],
      HomePhone: this.homePhone,
      WorkPhone: this.workPhone,
      Email: this.jurorEmail
    };

    this._jurorProfileService.UpdateAddress(this.jurorDetails).subscribe(data => {
      if (data.success === true) {
        this.getJurorProfile();
        this.statusInfoMessage = 'Record updated successfully';
        this.statusMessage = true;
        setTimeout(() => { this.statusMessage = false; }, 4000);
        this.showDiv = true;
      } else {
        this.statusInfoMessage = 'Record not updated successfully';
        this.statusMessage = true;
        setTimeout(() => { this.statusMessage = false; }, 4000);
        this.showDiv = false;
      }
    });
  }

  setAddress(addrObj) {
    // We are wrapping this in a NgZone to reflect the changes to the object in the DOM.
    this.zone.run(() => {
      this.residenceAddress = addrObj.formatted_address;
      this.residencecity = addrObj.locality;
      this.residencestate = addrObj.admin_area_l1;
      // this.addrKeys = Object.keys(addrObj);
    });
  }

  goback() {
    const routeURL = localStorage.getItem('RouterURL');
    if (routeURL !== null) {
      this._router.navigate([routeURL]);
    } else {
      this._router.navigate(['/home/dashboard']);
    }
  }

  phonePatternCheck(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    } else {
      if (event.currentTarget.name === 'homephone') {
        const eles = ((document.getElementById('homephone') as HTMLInputElement).value);
        if (eles !== '') {
          const ele = eles.split('-').join('');    // Remove dash (-) if mistakenly entered.
          if (((ele).length) <= 8) {
            const finalVal = (ele).match(/.{1,3}/g).join('-');
            ((document.getElementById('homephone') as HTMLInputElement).value) = finalVal;
          }
          if (((ele).length) >= 8) {
            const finalVal = (ele).match(/.{13,13}/g).join('');
            ((document.getElementById('homephone') as HTMLInputElement).value) = finalVal;
          }
        }
      } else if (event.currentTarget.name === 'workphone') {
        const eles = ((document.getElementById('workphone') as HTMLInputElement).value);
        if (eles !== '') {
          const ele = eles.split('-').join('');    // Remove dash (-) if mistakenly entered.
          if (((ele).length) <= 8) {
            const finalVal = (ele).match(/.{1,3}/g).join('-');
            ((document.getElementById('workphone') as HTMLInputElement).value) = finalVal;
          }
          if (((ele).length) >= 8) {
            const finalVal = (ele).match(/.{13,13}/g).join('');
            ((document.getElementById('workphone') as HTMLInputElement).value) = finalVal;
          }
        }
      }
    }
    // ((document.getElementById("homephone") as HTMLInputElement).value) = finalVal;
  }
  GetJurorQuestionnaire(jurorID)
  {
    this.jurorQuesList = [];
    this._jurorProfileService.GetJurorQuestionnaire(this.JurorID).subscribe(data =>{
      if(data != null && data.length >0)
      {
        for (var val of data) {    
          this.quesList = {
            ques_name: val.questionName,
            ques_answer: val.ques_Answer
          };
          this.jurorQuesList.push(this.quesList);
        }  
        console.log("juror ques list", this.jurorQuesList);

      }
    });

  }
}
