import { Component, Inject, TemplateRef, ViewChild, ElementRef, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgForm, FormBuilder, FormGroup, Validators, FormControl, FormsModule, ReactiveFormsModule, Form } from '@angular/forms';
import { Router, ActivatedRoute, Data } from '@angular/router';
import { AdminService } from './admin.service'
import { Observable } from 'rxjs';
import { Location } from '@angular/common';
import { empty } from 'rxjs';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { ConfigurationService } from '../configuration.service';
import { map, takeUntil, tap } from 'rxjs/operators';
import { OrderPipe } from 'ngx-order-pipe';
import { NgxSpinnerService } from 'ngx-spinner';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css'],
})
export class AdminComponent implements OnInit {

  EditablePriceform: FormGroup;
  EditablePaymentForm: FormGroup;
  submitted = false;
  requestoptions: any;
  headers: Headers;
  key: string = 'name';
  errorMsg: any;
  DBFiled=[];
  RequestType: string;
  data: any;
  cervernumber: any;
  cerdocname: any;
  certemplates: any;
  cerdata: any;
  activeTemplate:any
  termplateId: any;
  deleteZipCodeMsg: string;
  addedZipCodeMsg: string;
  isAddedZipCode: boolean=false;
  isCheckedZip:boolean=false;
  stagingTblName: any;
  jurisdictionUser='';
  rentonRegExp: boolean=false;
  saveProfileSuccessMsg: string;
  saveProfileFailedMsg: string;
  isSavePhone: boolean;
  isSavePhoneFailed:boolean;
  isSaveFax: boolean;
  isSaveFaxFailed: boolean;
  isSaveEmail: boolean;
  isSaveEmailFailed: boolean;
  isJurisettingSuccess: boolean;
  JurisettingSuccessMsg: string;
  activeTemplateId: any;
  converttoarray(arg0: any): any {
    throw new Error("Method not implemented.");
  }
  //modalRef: BsModalRef = new BsModalRef;
  userList: any;
  adminSettingsForm: any;
  orderByField: string = "EmailID";
  reverseSort: boolean = false;
  jurisdictionID : any;
  jurisdictionName:string;
  userName = "";
  alertMessage: string = '';
  dataParams: any;
  showEditUserRoleMessage: boolean = false;
  acceptedAlertMessage: boolean = false;
  editUserRoleMessage: string = '';
  roleID: number = 2;
  editorPriceEnabled = false;
  editorPaymentEnabled = false;
  editorJurorsEnabled = false;
  editorRoundOffEnabled = false;
  editorUpcomingEvts = false;
  NotANumber = false;
  uploadstart = false;
  pricePerMile: any;
  editablePricePerMile;
  settingsDetails: any = [];
  paymentPerDay;
  editablePaymentPerDay;
  noOfJurors;
  editableNoOfJurors;
  editableRoundOff;
  roundOff;
  roundOffDisplay;
  UpcomingEvts;
  editorUpcomingEvtsEnabled;
  editableUpcomingEvts;
  MaxEvts;
  editorMaxEvtsEnabled;
  editableMaxEvts;
  templates: any = [];
  contacts: any = [];
  fldinDBlst: any = [];
  templateId;
  docname;
  vernumber;
  SummonsFile;
  flag = false;
  saveFlag = false;
  field1 = false;
  field2 = false;
  field3 = false;
  true = false;
  countRemove = 0;
  countAdd = 0;
  CertificateFile;
  JurorListLog: any = [];
  filename: string = '';
  loader = true;
  myFile;
  editorPhoneEnabled = false;
  editorFaxEnabled = false;
  editorEmailEnabled = false;
  Phone;
  Email;
  Fax;
  editablePhone;
  editableFax;
  editableEmail;
  AddedZip;
  ele: any;
  ZipCodes: any = [];
  toDelete: any = [];
  SavedZip: any = [];
  InvalidZip = false;
  successmsg;
  NoNewUserRequest: boolean = false;
  userSingleSelect;
  userFirstName;
  jurisdictionData: any = [];
  userDetails: any = [];
  editUserFirstName: string = '';
  userID: number = 0;
  isActive: boolean = true;
  lastName: string = '';
  rejectedAlertMessage: boolean = false;
  assingedAlertMessage: boolean = false;
  fileToUpload;
  zipList: any = [];
  checkedList: any = [];
  public progress: number;
  public message: string;
  templatefilename: string = '';
  UploadMessage: string = '';
  visible: boolean = true;
  display = 'block';
  singleSelect="select Role"
  isLoading: boolean = false;
  truevalue: Number = 0;
  @ViewChild('template') public template;
  myFiles: any = [];
  myJurorListFiles: any = [];
  myCertificateFiles: any = [];
  sMsg: string = '';
  alertMessageText: string = '';
  setAlertMessage: boolean = false;
  mobnumPattern;
  ErrorMessage: string = "";
  NoOfJurorsForm: FormGroup;
  NoOfEventsForm: FormGroup;
  MaxEventsForm: FormGroup;
  EditablePhoneForm: FormGroup;
  EditableFaxForm: FormGroup;
  EditableEmailForm: FormGroup;
  FileUploadSuccess: Boolean = false;
  FileUploadFailure: Boolean = false;
  UnassignedUserMsg: string = '';
  errorTextAlert: string = "";
  showSuccessAlert: boolean = false;
  rejectMessage: string = "";
  showSaveChangesMsg = false;
  showSaveChangesMessage = "";
  summonsTemp = false;
  InvalidZipMsg: string = "";
  showErrorMsg: boolean = false;
  showErrorMessage: string = " ";
  templateFileName: string = " ";
  UploadErrorMessage: string = "";
  UploadSummonsFailure: boolean = false;
  UploadCertificateFailure: boolean = false;
  UploadSummonsErrorMessage: string = "";
  CertificateErrorMessage: string = "";
  UploadSummonsSuccess: boolean = false;
  UploadSummonsMessage: string = "";
  setSummons: boolean = false;
  isDeleteZipCode:boolean=false;
  SummonsDocumentForm;
  selectedDBFileds: any = [];
  duplicatefieldInDoc: boolean = false;
  AddedZipCode;
  deleteSuccess;
  deleteSuccessMsg;
  isEditorPricePerMile: boolean = false;
  btnview: boolean = false;
  btnleft: boolean = false;
  totalJurors: any;
  FilePath: any;
  finalVal: any;
  reverse: boolean = true;
  order: string = 'modifiedDatePST';
  isOrder: boolean = true;
  RoleName="Select Role"
  deleteSummonsCertificate:boolean=false;
  deleteSummonsMessage:string='';
  deleteCertificateMessageText:string='';
  deleteCertificate:boolean=false;
  UserDeleteData:any;
  newUserAcceptData:any;
  uploadSummonsTemplateform: FormGroup
  uploadCertificateForm: FormGroup
  NewUserForm:FormGroup
  constructor(private _fb: FormBuilder, private _adminService: AdminService,
    private http: HttpClient, private configurationservice: ConfigurationService,
    private router: Router, private orderPipe: OrderPipe, private spinner: NgxSpinnerService,
    private adalSvc: MsAdalAngular6Service) {
    this.editorEmailEnabled = false;
    this.jurisdictionID = localStorage.getItem('JurisdictionID');
    this.mobnumPattern = "^((\\+91-?)|0)?[0-9]{10}$";
  }

  ngOnInit() {
    this.NewUserForm = this._fb.group({
      //txtPricePerMile: new FormControl('', [Validators.required, Validators.maxLength(2), Validators.pattern(/^[0-9]{1,3}(\.[0-9]{1,2})?$/)])
      'RoleName': ['SelectRole', Validators.compose([Validators.required])]
    });
    this.EditablePriceform = this._fb.group({
      //txtPricePerMile: new FormControl('', [Validators.required, Validators.maxLength(2), Validators.pattern(/^[0-9]{1,3}(\.[0-9]{1,2})?$/)])
      'txtPricePerMile': ['', Validators.compose([Validators.required, Validators.max(20.00)])]
    });
    this.EditablePaymentForm = this._fb.group({
      txtPaymentPerDay: new FormControl('', [Validators.required, Validators.max(100.00), Validators.pattern(/^[0-9]{1,3}(\.[0-9]{1,2})?$/)])
    });
    this.NoOfJurorsForm = this._fb.group({
      txtNoOfJurors: new FormControl('', [Validators.required, Validators.max(500), Validators.pattern(/^[0-9]{1,3}(\.[0-9]{1,2})?$/)])
    });
    this.NoOfEventsForm = this._fb.group({
      txtEvents: new FormControl('', [Validators.required, Validators.max(10), Validators.pattern(/^[1-9][0-9]*$/)])
    });
    this.MaxEventsForm = this._fb.group({
      txtMaxEvents: new FormControl('', [Validators.required, Validators.max(100), Validators.pattern(/^[0-9]{1,3}(\.[0-9]{1,2})?$/)])
    });
    this.EditablePhoneForm = this._fb.group({
      txtEditablePhone: ['', Validators.compose([Validators.required, Validators.minLength(10)])]
    });
    this.EditableFaxForm = this._fb.group({
      txtEditableFax: ['', Validators.compose([Validators.required, Validators.minLength(10)])]
    });
    this.EditableEmailForm = this._fb.group({
      txtEditableEmail: ['', Validators.compose([Validators.required])]
    });
    this.SummonsDocumentForm = this._fb.group({
      txtdocumentName: [''],
      optSelectedDBField: ['']
    });
    this.uploadSummonsTemplateform = this._fb.group({
      summonsTemplateFile: ['', Validators.compose([Validators.required])]
    })
    this.uploadCertificateForm = this._fb.group({
      uploadCertificateTemplate: ['', Validators.compose([Validators.required])]
    })
    this.AddedZipCode = "";
this.jurisdictionName=localStorage.getItem("JurisdictionName");

    this.userName = this.adalSvc.LoggedInUserName;//localStorage.getItem("UserID");
//this.jurisdictionID=localStorage.getItem("JurisdictionID");
//console.log("Name:="+this.jurisdictionName);
this.ViewJurisdictionDetails();
//this.getActiveTemplate();
  }
  //******************************Customize Jurisdiction Settings*******************************
  LoadJurisdictionSettings() {
    this.isLoading=true;
    this._adminService.JurisdictionSettingsData(this.jurisdictionID).subscribe(data => {
      this.isLoading=false;
      this.settingsDetails = data;
    });
  }

  enablePriceEditor() {
    this.editorPriceEnabled = true;
    this.editablePricePerMile = this.settingsDetails.perMileFare;
  }

  disablePriceEditor() {
    this.editorPriceEnabled = false;
    this.rentonRegExp=false;
  }
  savePrice(editablePricePerMile) {
    this.pricePerMile = editablePricePerMile;
    this.disablePriceEditor();
    this.dataParams = {
      key: 'PerMileFare',
      value: this.pricePerMile,
      jurisdictionID: this.jurisdictionID,
      LoggedInUser:this.userName
    };
    this._adminService.JurisdictionSettings(this.dataParams).subscribe(data => {
      // this.dataObj.storage = data;
      if (data.success == true) {
        // localStorage.setItem("PricePerMile", this.pricePerMile);
        this.isJurisettingSuccess=true;
        this.JurisettingSuccessMsg='Price saved';
        setTimeout(()=>{
          this.isJurisettingSuccess=false;
          this.JurisettingSuccessMsg='';
        },3000)
        this._adminService.JurisdictionSettingsData(this.jurisdictionID).subscribe(data => {
          this.settingsDetails = data;
        });
      }
    });
  }
  enablePaymentEditor() {
    this.editorPaymentEnabled = true;
    this.editablePaymentPerDay = this.settingsDetails.jurorDailyPay;
  }
  disablePaymentEditor() {
    this.editorPaymentEnabled = false;
    this.NotANumber = false;
  }
  savePayment(editablePaymentPerDay) {
    this.paymentPerDay = editablePaymentPerDay;
    this.disablePaymentEditor();
    this.dataParams = {
      key: 'JurorDailyPay',
      value: this.paymentPerDay,
      jurisdictionID: this.jurisdictionID,
      LoggedInUser:this.userName
    };
    this._adminService.JurisdictionSettings(this.dataParams).subscribe(data => {
      //this.dataObj.storage = data;
      if (data.success == true) {
        //  this.isLoading = true;
        this._adminService.JurisdictionSettingsData(this.jurisdictionID).subscribe(data => {
          this.settingsDetails = data;
        });
        //this.LoadJurisdictionSettings(this.template);
        this.isLoading = false;
      }
    });
    //localStorage.setItem("PaymentPerDay", this.paymentPerDay);
    //this.settingsDetails.savePay("SavePaymentPerDay", this.paymentPerDay, this.jurisdictionID);

  }
  enableJurorsEditor() {
    this.editorJurorsEnabled = true;
    this.editableNoOfJurors = this.settingsDetails.noOfJurors;
  }
  disableJurorsEditor() {
    this.editorJurorsEnabled = false;
  }
  saveJurors(editableNoOfJurors) {
    this.noOfJurors = editableNoOfJurors;
    this.disableJurorsEditor();
    this.dataParams = {
      key: 'NoOfJurors',
      value: this.noOfJurors,
      jurisdictionID: this.jurisdictionID,
      LoggedInUser:this.userName
    };
    this._adminService.JurisdictionSettings(this.dataParams).subscribe(data => {
      //   this.dataObj.storage = data;
      if (data.success == true) {
        this._adminService.JurisdictionSettingsData(this.jurisdictionID).subscribe(data => {
          this.settingsDetails = data;
        });
      }
    });
  }
  enableRoundOffEditor() {
    this.editorRoundOffEnabled = true;
    this.editableRoundOff = JSON.parse(this.settingsDetails.roundOff);
  }
  disableRoundOffEditor() {
    this.editorRoundOffEnabled = false;
  }
  saveRoundOff(editableRoundOff) {

    this.roundOff = editableRoundOff;
    if (this.roundOff === true) {
      this.truevalue = 1;
      this.roundOffDisplay = "Yes"
    }
    else {
      this.truevalue = 0;
      this.roundOffDisplay = "No"
    }
    this.settingsDetails.roundOff = this.roundOff;
    this.disableRoundOffEditor();
    this.dataParams = {
      key: 'RoundOffPayment',
      value: this.truevalue,
      jurisdictionID: this.jurisdictionID,
      LoggedInUser:this.userName
    };
    this._adminService.JurisdictionSettings(this.dataParams).subscribe(data => {
      if (data.success == true) {
        this._adminService.JurisdictionSettingsData(this.jurisdictionID).subscribe(data => {
          this.settingsDetails = data;
        });
      }
    });
  }
  enableUpcomingEvtsEditor() {
    this.editorUpcomingEvtsEnabled = true;
    this.editableUpcomingEvts = parseInt(this.settingsDetails.noOfUpcomingEvts);
  }
  enableMaxEvtsEditor() {
    this.editorMaxEvtsEnabled = true;
    this.editableMaxEvts = parseInt(this.settingsDetails.maxAssignedJurors);
  }
  disableMaxEvtsEditor() {
    this.editorMaxEvtsEnabled = false;
  }
  disableUpcomingEvtsEditor() {
    this.editorUpcomingEvtsEnabled = false;
  }
  saveUpcomingEvts(editableUpcomingEvts) {
    this.UpcomingEvts = editableUpcomingEvts;
    this.disableUpcomingEvtsEditor();
    this.dataParams = {
      key: 'NoOfUpcomingEvents',
      value: this.UpcomingEvts,
      jurisdictionID: this.jurisdictionID,
      LoggedInUser:this.userName
    };
    this._adminService.JurisdictionSettings(this.dataParams).subscribe(data => {
      // this.dataObj.storage = data;
      if (data.success == true) {
        this._adminService.JurisdictionSettingsData(this.jurisdictionID).subscribe(data => {
          this.settingsDetails = data;
        });

      }
    });
  }
  saveMaxEvts() {
    this.MaxEvts = this.editableMaxEvts;
    this.disableMaxEvtsEditor();
    this.dataParams = {
      key: 'MaxAssignedJurors',
      value: this.MaxEvts,
      jurisdictionID: this.jurisdictionID,
      LoggedInUser:this.userName
    };
    this._adminService.JurisdictionSettings(this.dataParams).subscribe(data => {
      //  this.dataObj.storage = data;
      if (data.success == true) {
        this._adminService.JurisdictionSettingsData(this.jurisdictionID).subscribe(data => {
          this.settingsDetails = data;
        });
      }
    });

  }
  resetCustomizeJurisdictionPopup() {
    this.editorPriceEnabled = false;
    this.editorPaymentEnabled = false;
    this.editorJurorsEnabled = false;
    this.editorRoundOffEnabled = false;
    this.editorMaxEvtsEnabled = false;
    this.editorUpcomingEvtsEnabled = false;
  }
  //******************************End Customize Jurisdiction Settings*******************************
  //************************************Upload Summons template ***********************************

  addContact(fieldInDoc, fieldInDB) {       
    if (fieldInDoc == undefined || fieldInDB == undefined) {
      this.countAdd = this.countAdd + 1;
      this.flag = true;
    }
    this.contacts.push({
    });
  }
  removeContact(item) {
    var index = this.contacts.indexOf(item);
    this.contacts.splice(index, 1);
    this.countRemove = this.countRemove + 1;
    if (this.countRemove == this.countAdd) {
      this.flag = false;
      this.saveFlag = false;
    }
  }

  LoadTemplate() {
    this.uploadSummonsTemplateform.reset();
    this.vernumber = "";
    this.docname = "";
    this.contacts = [];
    this.isLoading = true;
    this.alertMessageText = "";
    this.setAlertMessage = false;
    //this.isLoading = true;
    this.templates = [];
    var jurisId = parseInt(this.jurisdictionID);
    this._adminService.GetDBFields(jurisId).subscribe(data => {
      if (data.success == true) {
        this.DBFiled=data['listTemplates'];
        this.fldinDBlst=   this.DBFiled.map(x=>{
          return x.fieldInDB
        });
      }
    });    
     
      this._adminService.LoadTemplate(jurisId).subscribe(data => {
        this.isLoading = false;
        //this.spinner.hide();
        this.summonsTemp = true;
        this.cerdata= data['lstTemplateVersion'];
        this.templates=this.cerdata.map(x=>{
          return{ 'templateFileName':x.templateFileName,'versionNumber':x.versionNumber,'id':x.id,'isActive':x.isActive}
        })
     // setting template version number and template Name
      this.cerdata.map(x=>{
         if(x.isActive) {

          if (data.listTemplates.length != 0) {
            this.contacts = data.listTemplates;
          }          
          return this.docname = x.templateFileName, this.vernumber =x.versionNumber
         }
        })
       
        this.termplateId = this.cerdata[0]['id'];
        //console.log("version" + this.termplateId, "docname" + this.docname);
        
      });
  }
  getActiveTemplate(){
    this._adminService.LoadTemplate(this.jurisdictionID).subscribe(data => {
      this.isLoading = false;
      this.spinner.hide();
      this.summonsTemp = true;
      this.activeTemplate= data['lstTemplateVersion']
      this.activeTemplateId=this.activeTemplate.map(x=>{
       if(x.isActive){
        return x.id
       }
      
      });
    });
  }

  GetDocToDBMap() {
    this._adminService.GetDBFields(this.jurisdictionID).subscribe(data => {
      if (data.success == true) {
        var length = data.listTemplates.length;
        for (var i = 0; i < length; i++) {
          this.fldinDBlst.push(data.listTemplates[i].fieldInDB);
        }
      }
      else {

      }
    });

  }
/**############# Upload Certificate Templates ################################## */
  LoadCertificateTemplate() {
    this.CertificateErrorMessage = "";
    this.RequestType ='CERTIFICATE'
    this.isLoading = true;
    this._adminService.LoadCertificateTemplate(this.jurisdictionID).subscribe(data => {
      this.summonsTemp = true;     
      this.data = data['lstCertificateTemplateVersion']
      this.certemplates = this.data;      
      this.certemplates.map(x=>
        {
          if(x.isActive){
            return this.cerdocname = x.certificateTemplateFileName,this.cervernumber =  x.certifcateVersionNumber
          }       
      })
      this.isLoading = false;
     // this.cervernumber = this.data[0]['certifcateVersionNumber'];
     // this.cerdocname = this.data[0]['certificateTemplateFileName'];
      //console.log("version" + this.cervernumber, "docname" + this.cerdocname);
    }); 
}
DeleteCertificate(template, operation){
  template={...template,
    Requesttype:'CERTIFICATE',
    operation:operation,
    UserName:this.userName
  }
  if (operation == "Delete") {
    this._adminService.UpdateTemplateVersionStatus(template).subscribe(data => {
      if (data.success == true) {
        this.deleteCertificateMessageText = "Certificate Deleted Successfully";
        this.deleteCertificate = true;
        this.cervernumber = "";
        this.cerdocname = "";
        this.LoadCertificateTemplate();
        setTimeout(() => {
          this.deleteCertificateMessageText = "";
          this.deleteCertificate = false;
        }, 2000);
       
      }
      else {
        this.errorTextAlert = "Something went wrong";
        this.setAlertMessage = false;
      }
    

    });
  }
}
setCertificateTemplateVersion(template, operation){
  template={...template,
    Requesttype:'CERTIFICATE',
    operation:operation,
    UserName:this.userName,
    JurisdictionId:this.jurisdictionID
  }
  this._adminService.UpdateTemplateVersionStatus(template).subscribe(data => {
    this.LoadCertificateTemplate();
    if (data.success == true) {
      this.alertMessageText = "Selected Certificate set to Active";
      this.setAlertMessage = true;
    }
    else {
      this.errorTextAlert = "Something went wrong";
      this.setAlertMessage = false;
      this.showSuccessAlert = true;
    }
    setTimeout(() => {
      this.alertMessageText = "";
      this.errorTextAlert = "";
      this.setAlertMessage = false;
    }, 2000);

  });
}
  //********************Common function in Summons and upload template section ***************** */
  UpdateTemplateVersionStatus(template, operation) {
   // template.Operation = operation;
   template={...template,
    Requesttype:'SUMMONS',
    operation:operation,
    UserName:this.userName
  }
      this._adminService.UpdateTemplateVersionStatus(template).subscribe(data => {
        if (data.success == true) {
          this.deleteSummonsCertificate=true;
          this.deleteSummonsMessage = "Template Deleted Successfully";
          this.vernumber = "";
          this.docname = "";
          this.LoadTemplate();
      setTimeout(() => {
        this.deleteSummonsMessage = "";
        this.deleteSummonsCertificate = false;
      }, 2000);
          //  this.isLoading = true;
         

        }
        else {
          this.errorTextAlert = "Something went wrong";
          this.setAlertMessage = false;
        }
        setTimeout(() => {
          this.alertMessageText = "";
          this.setAlertMessage = false;
        }, 2000);

      });
  }
  setTemplateVersion(template, operation){
    template={...template,
      Requesttype:'SUMMONS',
      operation:operation,
      UserName:this.userName,
      JurisdictionId:this.jurisdictionID
    }
    this._adminService.UpdateTemplateVersionStatus(template).subscribe(data => {
      
      if (data.success == true) {
        this.alertMessageText = "Selected Template set to Active";
        this.setAlertMessage = true;
        this.LoadTemplate(); 
      }
      else {
        this.errorTextAlert = "Something went wrong";
        this.setAlertMessage = false;
        this.showSuccessAlert = true;
      }
      setTimeout(() => {
        this.alertMessageText = "";
        this.errorTextAlert = "";
        this.setAlertMessage = false;
      }, 2000);

    });


  }
  DeleteTemplate(template) {
    this.dataParams = {
      Id: this.jurisdictionID,
      AreaName: template,
      IsActive: false,
      IsDeleted: true,
      TemplateFileName: template
    };

    this._adminService.deleteTemplate(this.dataParams).subscribe(data => {
      if (data.success == true) {
        this.alertMessageText = data.errorMessage;
        this.setAlertMessage = true;
      }
      else {
        this.errorTextAlert = "Something went wrong";
        this.setAlertMessage = false;
        this.showSuccessAlert = true;
      }
      setTimeout(() => {
        this.alertMessageText = "";
        this.errorTextAlert = "";
        this.setAlertMessage = false;
        this.showSuccessAlert = false;
      }, 3000);
    });
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    this.templatefilename = this.fileToUpload.name;
  }

  getFileDetails(e) {
    this.myFiles.length = 0;
    for (var i = 0; i < e.target.files.length; i++) {
      this.myFiles.push(e.target.files[i]);
    }
  }

  getFileDetailsJurorList(e) {
    this.myJurorListFiles = e.target.files;
    this.filename = this.myJurorListFiles[0].name;
  }

  cancelUploadList() {
    this.filename = '';
  }

  getCertificateFileDetails(e) {

    this.myCertificateFiles.length = 0;

    for (var i = 0; i < e.target.files.length; i++) {
      this.myCertificateFiles.push(e.target.files[i]);
    }
  }
  //********Common upload for Upload jurror list and summons template *** */
  uploadFiles() {
    if (this.myJurorListFiles.length == 0 || this.myJurorListFiles.length == undefined) {
      this.FileUploadFailure = true;
      this.UploadErrorMessage = "Please select file for upload";
    }
    else {
      var validateexcel = this.myJurorListFiles[0].name.split(".");
      if (((validateexcel[1]) != 'xls') && ((validateexcel[1]) != 'xlsx') && ((validateexcel[1]) != 'csv')) {

        this.FileUploadFailure = true;
        this.UploadErrorMessage = "Please upload .xlx / .xlsx  or .csv file";
      }
      else {
       this.isLoading = true;
        const frmData = new FormData();
        for (var i = 0; i < this.myJurorListFiles.length; i++) {
          frmData.append("fileUpload", this.myJurorListFiles[i]);
        }
        var userEmailId = this.adalSvc.LoggedInUserEmail;
        var uploadUrl = this.configurationservice.baseURL + "AdminApi/ImportJurorFile?" + 'JurisdictionName=' + this.jurisdictionName
          + '&UserName=' + userEmailId + '&FileType=JurorList' + '&FileName=' + this.myJurorListFiles[0].name
          + '&SheetName=Sheet1' + '&StagingTableName='+this.stagingTblName + '&IsHeader=false' + '&JurisdictionID=' + this.jurisdictionID;

        this.http.post(uploadUrl, frmData).subscribe(data => {
          
          var response = data;
          this.errorMsg = data['errorMessage'];
          
          if (data['successMessage'] == "uploadSuccess") {
            // this.myJurorListFiles.length = 0;
            this.isLoading = false;
            this.UploadMessage = "The juror list has been successfully extracted!";
            this.FileUploadSuccess = true;
            this.loader = false;
            this.filename = '';
            this.uploadstart = false;
            this.dataParams = {
              jurisdictionID: this.jurisdictionID,
              moduleType: 'JurorList'
            };
            this.isLoading=true;
            this._adminService.ViewJurorListLog(this.dataParams).subscribe(data => {             
              this.JurorListLog = data.jurorUploadData;
              this.isLoading = false;
            });
            // this.ViewJurorListLog(this.dataParams);
          }
          if (data['errorMessage'] == "uploadFailure") {
            this.isLoading = false;
            this.FileUploadFailure = true;
            this.UploadErrorMessage = "The juror list failed to extract data!";
          }
          if (this.errorMsg == "FileExists") {
            this.isLoading = false;
            this.FileUploadFailure = true;
            this.UploadErrorMessage = "A file is already exists";
          }
          if (response == undefined) {
            this.FileUploadFailure = true;
            // this.UploadErrorMessage = "An exception occured in the program- " + data.statusText; //will display the exception message

          }
          setTimeout(() => {
            this.FileUploadSuccess = false;
            this.FileUploadFailure = false;
            this.UploadErrorMessage = "";
            this.UploadMessage = "";
          }, 3500);
        }
        );
      }
    }
    setTimeout(() => {
      this.FileUploadFailure = false;
      this.UploadMessage = "";
    }, 3000);
  }
  UploadCertificate() {
    this.uploadCertificateForm.reset();
    if (this.myCertificateFiles.length == 0) {
      alert("Please Select file for upload");
    }
    else {
      var CertificateFileTemp = this.myCertificateFiles;
      var fileType = this.myCertificateFiles[0].name.split(".");
      // if (((SummonsFile[1]) != 'docx') && ((SummonsFile[1]) != 'doc') && ((SummonsFile[1]) != 'pdf')) {
        if ((fileType[1]) != 'docx') {
          this.UploadCertificateFailure = true;
        this.CertificateErrorMessage = "Please upload .docx file only";
        // return false;
      }
      else {
      this.CertificateErrorMessage = "";
      var uploadUrl = this.configurationservice.baseURL + "AdminApi/ImportJurisdictionData?" + 'JurisdictionName=' + this.jurisdictionName +
        '&UserName=' + this.userName + '&FileType=CertificateTemplate'+ 
        //+'&REQUESTYPE=CERTIFICATE'+
        '&FileName=' + this.myCertificateFiles[0].name + '&jurisdictionid=' + this.jurisdictionID;
      // this.isLoading = true;
      this.uploadFileToUrl(CertificateFileTemp, uploadUrl);
      // this.isLoading = false;
      }
    }
    setTimeout(() => {
      this.UploadCertificateFailure = false;
      this.CertificateErrorMessage = "";
      this.myCertificateFiles.length = 0;
    }, 3000);
  }
  UploadSummons() {
    this.uploadSummonsTemplateform.reset();
    if (this.myFiles.length == 0) {
      alert("Please Select file for upload");
    }
    else {
      var SummonsFileTemp = this.myFiles;
      var SummonsFile = this.myFiles[0].name.split(".");
      // if (((SummonsFile[1]) != 'docx') && ((SummonsFile[1]) != 'doc') && ((SummonsFile[1]) != 'pdf')) {
        if ((SummonsFile[1]) != 'docx') {
        this.UploadSummonsFailure = true;
        this.UploadSummonsErrorMessage = "Please upload .docx file only";
      }
      else {

        var uploadUrl = this.configurationservice.baseURL + "AdminApi/ImportJurisdictionData?" + 'JurisdictionName=' + this.jurisdictionName +
          '&UserName=' + this.userName + '&FileType=SummonsTemplate'+
          // +'&REQUESTYPE=SUMMONS'+
          '&FileName=' + this.myFiles[0].name + '&jurisdictionid=' + this.jurisdictionID;
        this.uploadFileToUrl(SummonsFileTemp, uploadUrl);
        var SummonsName = "";
      }
    }
    setTimeout(() => {
      this.UploadSummonsFailure = false;
      this.UploadSummonsErrorMessage = "";
      this.myFiles.length = 0;
    }, 3000);
  }
  uploadFileToUrl(file, uploadUrl) {
    const frmData = new FormData();
    for (var i = 0; i < file.length; i++) {
      frmData.append("TemplateUpload", file[i]);
    }
    this.http.post(uploadUrl, frmData).subscribe(data => {
      var response = data
      if (data['success'] === true && data['errorMessage'] === "upload success") {
        this.UploadSummonsSuccess = true;
        this.UploadSummonsMessage = "File Uploaded Successfully";
        this.uploadCertificateForm.reset();
        this.uploadSummonsTemplateform.reset();
        //this.isLoading = true;
        this.templates = [];
        this.fldinDBlst = [];
        this.contacts = [];
        this.vernumber = "";
        this.docname = "";
        this.LoadTemplate();
        this.LoadCertificateTemplate();
      }
      else if (data['success'] === true && data['errorMessage'] === "File Exists") {
        this.UploadSummonsFailure = true;
        this.uploadCertificateForm.reset();
        this.uploadSummonsTemplateform.reset();
        this.UploadSummonsErrorMessage = "File with name already exists";

      }
      else if (data['success'] === false && data['errorMessage'] === "upload success") {
        this.UploadSummonsSuccess = true;
        this.UploadSummonsMessage = "File Uploaded Successfully";
        this.uploadCertificateForm.reset();
        this.uploadSummonsTemplateform.reset();
      }
      else {
        this.errorTextAlert = "Error Occurred";
        this.uploadCertificateForm.reset();
        this.uploadSummonsTemplateform.reset();
        this.showSuccessAlert = true;
      }
      setTimeout(() => {
        this.myFiles.length = 0;
        this.uploadSummonsTemplateform.reset();
        this.uploadSummonsTemplateform.reset();
        this.UploadSummonsSuccess = false;
        this.UploadSummonsMessage = "";
        this.UploadSummonsFailure = false;
        this.UploadSummonsErrorMessage = "";
        this.alertMessageText = "";
        this.setAlertMessage = false;
        this.errorTextAlert = "";
        this.showSuccessAlert = false;

      }, 3000);
    }, error => {
      //console.log(JSON.stringify(error.json()));
    })
  }
  upload(files) {
    if (files.length != 0) {
      this.uploadJurorFile(files);
    }
  }
  uploadJurorFile(data) {
    data.IsHeader = true;
    var validateexcel = data[0].name.split(".");
    if (((validateexcel[1]) != 'xls') && ((validateexcel[1]) != 'xlsx') && ((validateexcel[1]) != 'csv')) {
      var UploadMessage = "Please upload .xls , .xlsx  or .csv file";
      var FileUploadSuccess = true;
    }
    else {
      this.loader = true;
      this.uploadstart = true;
      var uploadUrl = this.configurationservice.baseURL + "AdminApi/ImportJurorFile?" + 'JurisdictionName='+this.jurisdictionName + '&UserName=' + data.UserName
        + '&FileType=JurorList' + '&FileName=' + data[0].name + '&SheetName=' + data.SheetName + '&StagingTableName=' + data.StagingTableName +
        '&IsHeader=' + data.IsHeader + '&JurisdictionID=' + this.jurisdictionID;
      // this.ImportJurorFile(uploadUrl, data);
      //console.log(uploadUrl);
      //console.log(data);

    }
  }

  //************************************ view all users*****************************************
  Viewuserdetails() {
    // this.isLoading = true;
    this.spinner.show();
    this._adminService.Viewuserdetails(this.jurisdictionID).subscribe(data => {
      this.spinner.hide();
      this.userDetails = data.listJurisdictionUsers;
      //console.log(this.userDetails);
      this.isLoading = false;

    });


  }
  UserAccept(jurisdictionUser) {
    // if (jurisdictionUser === ' Admin') {
    //   this.roleID = 1;
    // }
    if (jurisdictionUser === 'Court Admin') {
      this.roleID = 2;
    }
    if (jurisdictionUser === 'Court Clerk') {
      this.roleID = 3;
    }
    this.dataParams = {
      emailID: this.Email,
      RoleName: jurisdictionUser,
      FirstName: this.editUserFirstName,
      UserID: this.userID,
      RoleID: this.roleID,
      IsActive: this.isActive,
      LastName: this.lastName,
      UserName: this.userName,
      jurisdictionName: this.jurisdictionName,
      JurisdictionID: this.jurisdictionID
    }
    this._adminService.UserAccept(this.dataParams).subscribe(data => {
      if (data.success == true) {
        this.showEditUserRoleMessage = true;
        this.acceptedAlertMessage = true;
        this.editUserRoleMessage = this.editUserFirstName + '\'s role is changed to ' + jurisdictionUser;
        this._adminService.Viewuserdetails(this.jurisdictionID).subscribe(data => {

          this.userDetails = data.listJurisdictionUsers;
        });
        setTimeout(() => {
          this.showEditUserRoleMessage = false;
          this.acceptedAlertMessage = false;               
        }, 3000);
      }
      else {
        this.showEditUserRoleMessage = false;
        this.acceptedAlertMessage = false;
      }
    });
  }
  edit(data) {
    this.editUserFirstName = data.firstName;
    this.Email = data.emailID;
    this.userID = data.userID;
    this.roleID = data.roleID;
    this.jurisdictionID = data.jurisdictionID;
    this.isActive = data.isActive;
    this.lastName = data.lastName;
    this.userName = this.userName;
    this.jurisdictionName = data.jurisdictionName;
    this.jurisdictionUser = data.roleName;
  }

  ConfirmUserDelete(jurisdictionUser) {
    this.dataParams = {
      EmailID: this.UserDeleteData.emailID,
      RoleName: this.UserDeleteData.roleName,
      FirstName: this.UserDeleteData.firstName,
      UserID: this.UserDeleteData.userID,
      RoleID: this.roleID,
      IsActive: this.UserDeleteData.isActive,
      LastName: this.UserDeleteData.lastName,
      UserName: this.userName,
      JurisdictionName: this.jurisdictionName,
      JurisdictionID: this.jurisdictionID
    };
    this._adminService.ConfirmUserDelete(this.dataParams).subscribe(data => {
      if (data.success == true) {
        this.rejectedAlertMessage = true;
        this.rejectMessage = this.dataParams.EmailID + '\'s is rejected';
        this.assingedAlertMessage = true;
        this.UnassignedUserMsg = this.dataParams.EmailID + '\'s is Unassigned';
        this._adminService.Viewuserdetails(this.jurisdictionID).subscribe(res => {

          this.userDetails = res.listJurisdictionUsers;
          //console.log(":" + res.listJurisdictionUsers);
        });
        setTimeout(() => {
          this.rejectedAlertMessage = false;
          this.rejectMessage = "";
          this.UnassignedUserMsg = "";
          this.assingedAlertMessage = false;
        }, 4000);
        this.ViewUsers();
      }
      else {
        this.rejectedAlertMessage = false;
        this.assingedAlertMessage = false;
      }

    });
  }
  clearViewAllUsers() {
    this.assingedAlertMessage = false;
    this.showEditUserRoleMessage = false
  }
  //************************************End view all users*****************************************

  //*********************************** New user Request ***************************************
  ViewUsers() {
    //this.isLoading = true;
    this.spinner.show();
    this._adminService.ViewUsers(this.jurisdictionID).subscribe(data => {
      this.spinner.hide();
      // this.modalRef = this.modalService.show(template);
      if (data.listJurisdictionUsers.length === 0) {     //If the length of the Users array is '0' then the message will be displayed - "There are no new users requests"
        this.NoNewUserRequest = true;
      } else {
        this.NoNewUserRequest = false;
       // this.userList = data.listJurisdictionUsers
       this.userList = data.listJurisdictionUsers.map(x=>{
         return {...x,'roleName':"Select Role"}
       })
       // this.userList['RoleName']="Select Role";
        //console.log(this.userList);
      }
      // this.isLoading = false;
    });
  }
  NewUserAcceptData(data){
    this.newUserAcceptData=data
    //console.log(this.newUserAcceptData);

  }
  NewUserAccept() {
    if (this.newUserAcceptData != null || this.newUserAcceptData == undefined) {
      if (this.newUserAcceptData.roleName === 'Unassigned') {
          this.roleID = 0;
      }
      if (this.newUserAcceptData.roleName === 'Court Admin') {
          this.roleID = 2;
      }
      if (this.newUserAcceptData.roleName === 'Court Clerk') {
          this.roleID = 3;
      }
  }
  this.dataParams = {
      emailID: this.newUserAcceptData.emailID,
      RoleName: this.newUserAcceptData.roleName,
      FirstName: this.newUserAcceptData.firstName,
      UserID: this.newUserAcceptData.userID,
      RoleID: this.roleID,
      IsActive: true,
      LastName: this.newUserAcceptData.lastName,
      UserName:this.userName,
      jurisdictionName: this.jurisdictionName,
      JurisdictionID: this.jurisdictionID
  }
    this._adminService.UserAccept(this.dataParams).subscribe(data => {
        if (data.success == true) {              
            this.acceptedAlertMessage = true;
            this.alertMessage = this.dataParams.emailID + '\'s  is assigned to ' + this.dataParams.RoleName;
            this._adminService.Viewuserdetails(this.jurisdictionID).subscribe(data => {                    
                this.userDetails = data.listJurisdictionUsers;
            });
            setTimeout(() => {                       
                    this.acceptedAlertMessage = false;
                    this.alertMessage = "";                    
            }, 2000);
            this.ViewUsers();
        }
        else {
            this.alertMessage = "";
            this.acceptedAlertMessage = false;
        }
    });
} 
UserDelete(data) { 
  this.UserDeleteData=data;
 // this.ConfirmUserDelete(data);      
}   
  //*******************************************Edit Zip code************************************** */
  ViewJurisdictionDetails() {
    this.AddedZip = "";
    // this.modalRef = this.modalService.show(template);
    this.spinner.show();
    this._adminService.ViewJurisdictionDetails(this.jurisdictionID).subscribe(data => {
      this.spinner.hide();
      if (data.success == true && data.listJurisdictions[0].length != 0) {
        this.jurisdictionData = data.listJurisdictions[0];
        //console.log(this.jurisdictionData);
        if (this.jurisdictionData.assignedZipCodes != null) {
          this.ZipCodes = this.jurisdictionData.assignedZipCodes;
          this.zipList = this.ZipCodes.split(",");
        }
        this.stagingTblName = this.jurisdictionData.stagingTableName
        console.log("table name", this.stagingTblName);
      }
      else {
        this.showErrorMsg = true;
        this.showErrorMessage = "Error occurred";
      }
      setTimeout(() => {
        this.showErrorMsg = false;
        this.showErrorMessage = "";
      }, 2000);
    });
  }
  AddZip(AddedZipCode) {
    var InvalidZipMsg = '';
    var NumberPattern = new RegExp(/^[0-9]+$/);
    if (AddedZipCode < 98001 || AddedZipCode > 99403 || !NumberPattern.test(AddedZipCode)) {
      this.InvalidZip = true;
      this.InvalidZipMsg = "Please enter a valid Washington Zipcode between the range 98001-99403";
      this.AddedZipCode = "";

    }
    else if (this.zipList != undefined && this.zipList.indexOf(AddedZipCode) !== -1) {
      this.InvalidZip = true;
      this.InvalidZipMsg = "The zipcode entered already exists in the list";
      this.AddedZipCode = "";


    }
    else {
      if (this.zipList === "" || this.zipList === null || this.zipList.length == 0) {
        this.zipList.push(AddedZipCode);

      }
      else {
        // this.isLoading = true;
        this.zipList.push(AddedZipCode);
        this.isAddedZipCode=true;
this.addedZipCodeMsg="zipcode added"
        this.AddedZipCode = "";
        this.isLoading = false;
        setTimeout(() => {
          this.isAddedZipCode = false;
          this.addedZipCodeMsg = "";
        
        }, 2000);
      }
    }

    setTimeout(() => {
      this.InvalidZip = false;
      this.InvalidZipMsg = "";
      this.AddedZipCode = "";
    }, 2000);

  }
  onCheckboxChange(option, event) {
    if (event.target.checked) {
      this.checkedList.push(option);
      
    } else {
      for (var i = 0; i < this.zipList.length; i++) {
        if (this.checkedList[i] == option) {
          this.checkedList.splice(i, 1);
        }
      }
    }

  }
  DeleteZip() {
    for (var i = 0; i < this.checkedList.length; i++) { 
      var index = this.zipList.indexOf(this.checkedList[i]);
      var res=this.zipList.splice(index, 1);  
       if(res.length>0 && this.checkedList.length==1){
      this.isDeleteZipCode=true;
      this.deleteZipCodeMsg="Zipcode deleted";
      setTimeout(() => {
        this.isDeleteZipCode = false;
        this.deleteZipCodeMsg = "";
      }, 2000);
    }          
  }      
  this.isDeleteZipCode=true;
  this.deleteZipCodeMsg="Zipcode deleted";
  setTimeout(() => {
    this.isDeleteZipCode = false;
    this.deleteZipCodeMsg = "";
  }, 2000);
  this.checkedList.length = 0;           
  }
  SaveZip() {
    this.AddedZipCode = "";
    this.dataParams = {
      JurisdictionID: this.jurisdictionID,
      ZipCodes: this.zipList,
      LoggedInUser:this.userName
    };
    this._adminService.SaveZip(this.dataParams).subscribe(data => {
      if (data.success == true) {
        this.showSaveChangesMsg = true;
        this.showSaveChangesMessage = "Saved Changes successfully";
        this.SavedZip = this.zipList;

      }
      else {
        this.showErrorMsg = true;
        this.showErrorMessage = "Error Occurred";
        this.SavedZip = this.zipList;
      }
      setTimeout(() => {
        this.showSaveChangesMsg = false;
        this.showSaveChangesMessage = "";
      }, 2000);
    });
  }
  clearZiplist() {
    //this.zipList = null;
    //this.zipList.length = 0;
    this.checkedList.length = 0;
    this.InvalidZip = false;
    this.AddedZip = "";
    this.successmsg = "";
  }
  //****************************************Edit Jurisdiction Profile******************************** */
  enablePhoneEditor() {
    this.editorPhoneEnabled = true;
    this.editablePhone = this.jurisdictionData.jurisdictionPhone;
  }
  disablePhoneEditor() {
    this.editorPhoneEnabled = false;
  }
  savePhone(editablePhone) {
    this.Phone = editablePhone;
    
    this.dataParams = {
      PhoneNumber: this.Phone,
      JurisdictionID: this.jurisdictionID,
      LoggedInUser:this.userName
    };
    this._adminService.UpdatePhoneFaxEmailByJurisdictionID(this.dataParams).subscribe(data => {
     
      if (data.success == true) { 
        this.isLoading = true;
        setTimeout(() => { 
        this._adminService.ViewJurisdictionDetails(this.jurisdictionID).subscribe(data => {          
          if (data.success == true && data.listJurisdictions[0].length != 0) {
            this.jurisdictionData = data.listJurisdictions[0];
            this.isSavePhone = true;
            this.saveProfileSuccessMsg = 'Phone saved successfully';            
         
        setTimeout(() => {
          this.isSavePhone = false;
          // this.saveProfileSuccessMsg = '';
          
        }, 2000);
        this.isLoading = false;
        this.disablePhoneEditor();
      }
    });
      }, 1000);
      }
      else{
        this.isSavePhoneFailed=true;
        this.saveProfileFailedMsg='Phone is not save';
        setTimeout(() => {
          this.isSavePhoneFailed=false;
          this.saveProfileFailedMsg='';
          
        }, 1000);
        
      }
    });
  }
  enableFaxEditor() {
    this.editorFaxEnabled = true;
    this.editableFax = this.jurisdictionData.jurisdictionFax;
  }
  disableFaxEditor() {
    this.editorFaxEnabled = false;
  }
  saveFax(editableFax) {   
    this.Fax = editableFax;  
    this.dataParams = {
      FaxNumber: this.Fax,
      JurisdictionID: this.jurisdictionID,
      LoggedInUser:this.userName
    };
    this._adminService.UpdatePhoneFaxEmailByJurisdictionID(this.dataParams).subscribe(data => {
      if (data.success == true) {
        this.isLoading = true;
                
        setTimeout(() => {  
        this._adminService.ViewJurisdictionDetails(this.jurisdictionID).subscribe(data => {
          if (data.success == true && data.listJurisdictions[0].length != 0) {
            this.jurisdictionData = data.listJurisdictions[0];                   
                // this.saveProfileSuccessMsg = '';
                this.isSaveFax = true;
                this.saveProfileSuccessMsg = 'Fax saved successfully';
        setTimeout(() => {
                this.isSaveFax = false;
              }, 2000);
            this.editorFaxEnabled = false;
            this.isLoading = false;
          }
        });
        }, 1000);
        
      }
      else{
        this.isSaveFaxFailed=true;
          this.saveProfileFailedMsg='Fax is not save';
        setTimeout(() => {
          this.isSaveFaxFailed=false;
          this.saveProfileFailedMsg='';
        }, 2000);
        
      }
    });
  }
  enableEmailEditor() {
    this.editorEmailEnabled = true;
    this.editableEmail = this.jurisdictionData.jurisdictionEmail;
  }
  disableEmailEditor() {
    this.editorEmailEnabled = false;
  }
  saveEmail(editableEmail) {
    this.Email = editableEmail;
    //this.disableEmailEditor();
    this.dataParams = {
      Email: this.Email,
      JurisdictionID: this.jurisdictionID,
      LoggedInUser:this.userName
    };
    this._adminService.UpdatePhoneFaxEmailByJurisdictionID(this.dataParams).subscribe(data => {      
      if (data.success == true) {
        this.isLoading = true;

        setTimeout(() => {
          this._adminService.ViewJurisdictionDetails(this.jurisdictionID).subscribe(data => {
            if (data.success == true && data.listJurisdictions[0].length != 0) {              
              this.jurisdictionData = data.listJurisdictions[0];
              this.isLoading = false;
              this.isSaveEmail = true;
              this.saveProfileSuccessMsg = 'Email saved successfully';
              setTimeout(() => {
              this.isSaveEmail = false;
            }, 2000);
              this.disableEmailEditor();
            }
          });
        }, 2000);
      }
      else{
        this.isSaveEmailFailed=true;
        this.saveProfileFailedMsg='Email is not save';
        setTimeout(() => {
          this.isSaveEmailFailed=false;
          this.saveProfileFailedMsg='';
        }, 2000);
        
      }
    });
  }
  resetEditJuriProfilePopup() {
    this.editorPhoneEnabled = false;
    this.editorFaxEnabled = false;
    this.editorEmailEnabled = false;
  }
  //************************************Upload Juror List************************************************ *
  ViewJurorListLog() {
    //  this.isLoading = true;
    this.dataParams = {
      jurisdictionID: this.jurisdictionID,
      moduleType: 'JurorList'
    };
    this.isLoading=true;
    this._adminService.ViewJurorListLog(this.dataParams).subscribe(data => {      
      this.JurorListLog = data.jurorUploadData;
      this.isLoading = false;
    });
  }

  browseFiles() {    
    document.getElementById("fileUpload").click();
  }

  viewCount() {
    this._adminService.viewCount(this.jurisdictionID).subscribe(data => {
      this.totalJurors = data.countrec;
      setTimeout(() => {
        this.btnleft = false;
        this.btnview = true
      }, 2000);
    });
  }
  //**********************************Upload Certificate Template******************************************************* */
  uploadCertificateTempPopup() {

    this.uploadCertificateForm.reset();
    this.vernumber = "";
    this.docname = "";
    this.contacts = [];
    //this.isLoading = true;
    this.summonsTemp = true;
    this.alertMessageText = "";
    this.setAlertMessage = false;
    //this.isLoading = true;
    this.templates = [];
    this._adminService.GetDBFields(this.jurisdictionID).subscribe(data => {
      if (data.success == true) {
        var length = data.listTemplates.length;
        for (var i = 0; i < length; i++) {
          this.fldinDBlst.push(data.listTemplates[i].fieldInDB);
        }
      }
      else {

      }
    });
    this.LoadTemplate();
  }
  BrowseCertificate() {
    document.getElementById("CertificateFileID").click();
    var name = document.getElementById("CertificateFileID");
  }
  switchBool(showSuccessAlert) {
    this[showSuccessAlert] = !this[showSuccessAlert];
  }
  SelectedTemplateClick(id, jurisdictionId) {
    if (id.length > 1)
      this.templateId = id[id.length - id.length];
    else
    {
    if(id == null)
    {     
     this.templateId = this.vernumber ;
    }
    else{
      this.templateId = id;

    }
  }
      
    //this.templateId = id;
    this.summonsTemp = false;
    this.dataParams = {
      templateId: this.templateId,
      jurisdictionId: parseInt(this.jurisdictionID)
    }
   // this.isLoading=true;
    this._adminService.SelectedTemplateClick(this.dataParams).subscribe(data => {
      if (data.success == true) {
        //this.isLoading=false;
        if (data.listTemplates.length != 0) {
          this.contacts = data.listTemplates;
        }
        else {
          this.contacts = data.listTemplates;

        }
      }
    });
  }
  SelectedCertificateTemplateClick(id, jurisdictionId) {
    this.templateId = id;
    this.summonsTemp = false;
    this.dataParams = {
      templateId: id,
      jurisdictionId: this.jurisdictionID
    }
    this._adminService.SelectedTemplateClick(this.dataParams).subscribe(data => {
      if (data.success == true) {
        if (data.listTemplates.length != 0) {
          this.contacts = data.listTemplates;
        }
        else {
          this.contacts = data.listTemplates;

        }
      }
    });
  }
  docNameVerDisplay(docname, vernumber) {
    this.docname = docname;
    this.vernumber = vernumber;
  }
  templateVerDisplay(certificateName, Certificatevernumber) {
    this.cerdocname = certificateName;
    this.cervernumber = Certificatevernumber;
   
  }
  closePopup() {

  }
  closeSummonsPopup()
  {

  }
  SaveTemplateParameter() {
    //this.contacts.push(this.SummonsDocumentForm.value.optSelectedDBField);
    this.dataParams = {
      templateId: this.templateId,
      listTemplates: this.contacts
    };
    
    this._adminService.SaveTemplateParameter(this.dataParams).subscribe(data => {
      this.isLoading = true;
      if (data.success == true) {
        var updatedfname = data;
        this.setAlertMessage = true;
        this.alertMessageText = "Document Saved Successfully";     

      }
      else {
        this.showSuccessAlert = true;
        this.errorTextAlert = "Error Occured";

      }
      this.isLoading = false;
      setTimeout(() => {
        this.alertMessageText = "";
        this.setAlertMessage = false;
        this.showSuccessAlert = false;
        this.errorTextAlert = "";        
      }, 1000);
    })
    
  }
  //Sorting ngx-order-pipe
  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.order = value;
  }
  addHyphen() {
    this.ele = ((document.getElementById("phone1") as HTMLInputElement).value);
    if (this.ele != "") {
      this.ele = this.ele.split('-').join('');    // Remove dash (-) if mistakenly entered.
      if (((this.ele).length) <= 8) {
        this.finalVal = (this.ele).match(/.{1,3}/g).join('-');
        ((document.getElementById("phone1") as HTMLInputElement).value) = this.finalVal;
      }
    }
    //this.ele = ((document.getElementById("phone1") as HTMLInputElement).value);
    //this.ele = this.ele.split('-').join('');    // Remove dash (-) if mistakenly entered.
    //if (((this.ele).length) <= 8) {
    //  this.finalVal = (this.ele).match(/.{1,3}/g).join('-');
    //}
    //if (((this.ele).length) >= 8) {
    //  this.finalVal = (this.ele).match(/.{13,13}/g).join('');
    //}
    //((document.getElementById("phone1") as HTMLInputElement).value) = this.finalVal;
    ////  document.getElementById(element.id).value = finalVal;
  }


}
