import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigurationService } from '../configuration.service';
import { catchError } from 'rxjs/operators';


@Injectable()
export class DashboardServices {

  public ObjData: any;
  public routeURL: any;
  private _baseURL: string;

  constructor(private _http: HttpClient, private configuration: ConfigurationService) {
    this._baseURL = configuration.baseURL;
  }

  SearchJurors(model: any): Observable<any> {
    return this._http.post(this._baseURL + 'DashboardApi/SearchJurors', model)
      .pipe(catchError(this.errorHandler));
  }

  SearchEvent(model: any): Observable<any> {
    return this._http.post(this._baseURL + 'DashboardApi/SearchEvent', model)
      .pipe(catchError(this.errorHandler));
  }

  getUpcommingEvents(model: any): Observable<any> {
    return this._http.get(this._baseURL + 'DashboardApi/UpcomingEventsDetails?jurisdictionID=' + model.jurisdictionID + '&isDashboard=' + model.isDashboard)
      .pipe(catchError(this.errorHandler));
  }

  getJurorList(eventID, jurisdictionId): Observable<any> {
    return this._http.get(this._baseURL + 'DashboardApi/EventJurorList?eventID=' + eventID + '&jurisdictionID=' + jurisdictionId)
      .pipe(catchError(this.errorHandler));
  }

  errorHandler(error: Response) {
    return Observable.throw(error);
  }
}

