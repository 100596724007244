import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { inject } from '@angular/core/testing';
import { ConfigurationService } from '../configuration.service';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DevtoolsService {
  public baseURL = this.configurationservice.baseURL;

  constructor(private configurationservice: ConfigurationService, private http: HttpClient) {

  }

  getalljurisdiction(step): Observable<any> {
    return this.http.get(this.baseURL + 'OnboardApi/GetAllJurisdictions?step=' + step)
      .pipe(catchError(this.errorHandler));
  }
  ViewAllDevelopers(): Observable<any> {
    return this.http.get(this.baseURL + 'OnboardApi/GetDevelopers')
      .pipe(catchError(this.errorHandler));
  }
  GetAllJurisdictionData(): Observable<any> {
    return this.http.get(this.baseURL + 'OnboardApi/GetNewUserJuName')
      .pipe(catchError(this.errorHandler));
  }
  GetJurisdictionDetails(jurisdictionID: Number): Observable<any> {
    return this.http.get(this.baseURL + 'OnboardApi/GetJurisDetails?jurisdictionID=' + jurisdictionID)
      .pipe(catchError(this.errorHandler));
  }
  startService(model: any): Observable<any> {
    return this.http.get(this.baseURL + 'OnboardApi/GetJurisdictionStatus?jurisdictionId=' + model.jurisdictionId + '&serviceName=' + model.serviceName + '&LoggedInUserName=' + model.LoggedInUserName)
      .pipe(catchError(this.errorHandler));
  }
  DeleteDeveloper(userID: Number): Observable<any> {
    return this.http.post(this.baseURL + 'OnboardApi/DeleteDeveloper', userID)
      .pipe(catchError(this.errorHandler));
  }
  UpdateDevplrData(model: any): Observable<any> {
    return this.http.post(this.baseURL + 'OnboardApi/UpdateDeveloper', model)
      .pipe(catchError(this.errorHandler));
  }
  AddEditDeveloper(model: any): Observable<any> {
    return this.http.post(this.baseURL + 'OnboardApi/AddEditDeveloper', model)
      .pipe(catchError(this.errorHandler));
  }
  ManageDeveloper(model: any): Observable<any> {
    return this.http.post(this.baseURL + 'OnboardApi/ManageDeveloper', model)
      .pipe(catchError(this.errorHandler));
  }
  checkDuplicateEmail(model: any): Observable<any> {
    return this.http.get(this.baseURL + 'OnboardApi/CheckDuplicateUserEmail?UserEmail=' + model.Email + '&JurisdictionID=' + model.JurisdictionID)
      .pipe(catchError(this.errorHandler));
  }
  GetCourtAdminDetails(): Observable<any> {
    return this.http.get(this.baseURL + 'OnboardApi/GetCourtAdminEmail')
      .pipe(catchError(this.errorHandler));
  }
  UpdateCourtAdminEmail(model: any): Observable<any> {
    return this.http.post(this.baseURL + 'OnboardApi/UpdateCourtAdminEmail', model)
      .pipe(catchError(this.errorHandler));
  }
  GetQuestionnaireOptionSet(model: any): Observable<any> {
    return this.http.get(this.baseURL + 'OnboardApi/GetOptionSetWithValues?optionSetID=' + model.optionSetID + '&optionSetName=' + model.optionSetName + '&queryType=' + model.queryType)
      .pipe(catchError(this.errorHandler));
  }
  ManageOptionValues(model: any): Observable<any> {
    return this.http.get(this.baseURL + 'OnboardApi/ManageOptionValues?optionSetID=' + model.optionSetID + '&optionValueID=' + model.optionValueID + '&optionValueName=' + model.optionValueName + '&queryType=' + model.queryType)
      .pipe(catchError(this.errorHandler));
  }
  ManageQuestionnaire(model: any): Observable<any> {
    return this.http.get(this.baseURL + 'OnboardApi/ManageQuestionaire?jurisdictionID=' + model.JurisdictionID + '&questionID=' + model.QuestionID + '&questionName=' + model.QuestionName + '&optionSetID=' + model.OptionSet_ID + '&queryType=' + model.Query_Type)
      .pipe(catchError(this.errorHandler));

    // .pipe(catchError(this.errorHandler));
  }
  errorHandler(error: Response) {
    return Observable.throw(error);

  }
}
