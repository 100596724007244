import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { FormGroup, FormControl, ReactiveFormsModule, FormBuilder, Validators, Form } from '@angular/forms';
import { LoginService } from './login.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  UserName: any;
  RoleId: any;
  adminsetting: any;
  allusers: any;
  superAdmin: any;
  juror: boolean = false;
  currentURL: any;
  JurisdictionID: any;
  JurisdictionResults: any[] = [];
  successMessage: boolean = false;
  successText: string;
  errorMessage: boolean = false;
  ErrorText : string;
  emailPattern; jurorAccountNo;
  emailDuplicate: boolean = false;
  registerForm: FormGroup;
  showLogin: boolean = false; 

  constructor(private route: ActivatedRoute, private _router: Router,
    private adalSvc: MsAdalAngular6Service, private _fb: FormBuilder, private _loginService: LoginService) {
    this.currentURL = location.pathname;
    this.emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
  }

  ngOnInit() {
    //setTimeout(function () {
      this.showLogin = false;
    //}, 2000);
    const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    //console.log("entering into login page");
    if (this.adalSvc.userInfo == null) {
      //setTimeout(function () {
        this.showLogin = true;
        this._router.navigate(['/']);
        // }, 4000);
     
    } else {
     this.getIntialLoginSettingsDetails(this.adalSvc.userInfo.userName);
      //this._router.navigate(['/dashboard']);
    }
    this.registerForm = this._fb.group(
      {
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
        jurisdictionName: ['', Validators.required]
      }
    )

  }

  login() {
    if (this.adalSvc.userInfo) {         
      this.getIntialLoginSettingsDetails(this.adalSvc.userInfo.userName);     
    } else {
      //console.log("login page info", this.adalSvc.userInfo);   
      this.adalSvc.login();
    }
  }

  getIntialLoginSettingsDetails(userName) {

    const loginInfo = {
      UserName: userName,
      Password: '123'
    }
    this._loginService.getloginDetails(loginInfo).subscribe(data => {
      if (data.loginInfoList.length > 0) {
        if (data.loginInfoList[0].isActiveUser == true && data.loginInfoList[0].isActiveJurisdiction == true) {
          localStorage.setItem("JurisdictionName", data.loginInfoList[0].jurisdictionName);
          localStorage.setItem("RoleID", data.loginInfoList[0].roleId);
          localStorage.setItem("JurisdictionID", data.loginInfoList[0].jurisdictionID);
         // localStorage.setItem("UName", data.loginInfoList[0].userName);
          localStorage.setItem("Password", data.loginInfoList[0].password);
          localStorage.setItem("LoggedIn", "true");
          localStorage.setItem("SessionContext", data.loginInfoList[0].sessionContext);
          localStorage.setItem("UserID", data.loginInfoList[0].userID);
          this.RoleId = localStorage.getItem("RoleID");
          this.JurisdictionID = localStorage.getItem("JurisdictionID");
          if (typeof (Storage) !== "undefined") {
            sessionStorage.setItem("uniqueIdSessionStorage", "xyz");
          }
          sessionStorage.setItem("refreshtabs", 'true');
          sessionStorage.setItem("status", "true")

          //this._loginService.getIntialSettingsDetails(this.JurisdictionID).subscribe(data => {
          //  localStorage.setItem("UpcomingEvts", data.noOfUpcomingEvts);
          //  localStorage.setItem("PricePerMile", data.perMileFare);
          //  localStorage.setItem("PaymentPerDay", data.jurorDailyPay);
          //  localStorage.setItem("RoundOff", data.roundOff);
          //  localStorage.setItem("NoOfJurors", data.noOfJurors);
          //  localStorage.setItem("MaxJurors", data.maxAssignedJurors);
          //});

          if (this.RoleId === "1") {
            this.adminsetting = false;
            this.allusers = false;
            this.superAdmin = true;
            this._router.navigate(['/home/devtools']);
          }
          if (this.RoleId === "2") {
            this.adminsetting = true;
            this.allusers = true;
            this.superAdmin = false;
            if (this.currentURL == '/') {
              //this._router.navigate(['/home/dashboard']);
              this._router.navigate(['/dashboard']);
            }
          }
          if (this.RoleId === "3") {
            this.adminsetting = false;
            this.allusers = true;
            this.superAdmin = false;
            if (this.currentURL == '/') {
              this._router.navigate(['/home/dashboard']);
              //this._router.navigate(['/dashboard']);
            }
          }
         
        } else if (data.loginInfoList[0].isActiveUser == false || data.loginInfoList[0].isActiveJurisdiction == false) {
          if (data.loginInfoList[0].isActiveJurisdiction == false) {
            alert("Jurisdiction is not Active. Please contact the Admin!");
          } else {
            alert("Unauthorized User");
          }
          this.adalSvc.logout();
          localStorage.clear();
        }
      } else {
        alert("Unauthorized User, Please login with another account or register");
        this._router.navigate(['/']);       
        this.adalSvc.logout();
        localStorage.clear();
      }
    });
  }
gotoJurorPortal(jurorAccountID)
{
var dataParams = {
  jurorAccountID : jurorAccountID
  };
  
  this._loginService.getJurorDetails(dataParams).subscribe(data => {
    if (data.jurorBasicInfo != null) {
      var result = data.jurorBasicInfo;
      localStorage.setItem("jurorAccountID", result.jurorAccountID);  
      var userName = result.firstName +""+ result.lastName;
      localStorage.setItem("UName", userName);
      this.adminsetting = false;
      this.allusers = false;
      this.superAdmin = false;     
      if (this.currentURL == '/') {
        this._router.navigate(['/home/jurorPortal']);
      }
    }
    else {
      var errorMsg = data["errorMessage"];
      alert(errorMsg);
    }       
 });       
}
  formReset() {
    this.jurorAccountNo = "";
  }
  newUserRegisterForm() {
    this._loginService.getAllJurisdictions().subscribe(data => {
      this.JurisdictionResults = data.lstJurisdictionDetails;
    });
  }
closeRegisterPopup()
{
  setTimeout(() => {
    this.registerForm.patchValue({
      firstName: '',
      lastName: '',
      email: '',
      jurisdictionName: ''
    });
    this.emailDuplicate = false;
  }, 1000);
}

  newUserSave(userData) {
   
      const newUserParams = {
        JurisdictionID: userData.jurisdictionName,
        FirstName: userData.firstName,
        LastName: userData.lastName,
        EmailID: userData.email,
        RequestType: "INSERT"
      };
      this._loginService.newUserRegistration(newUserParams).subscribe(data => {
        if (data.success == true) {
          this.successMessage = true;
          this.successText = 'Registration successful. Please wait for admin approval.';
        }
        else if(data.success == false)
        {
          //this.errorMessage = true;          
          //this.ErrorText = data.errorMessage;
          alert(data.errorMessage);
        }
        setTimeout(() => {
          this.successMessage = false;
          this.successText = '';
          this.errorMessage = false;
          this.ErrorText = '';
          this.closeRegisterPopup();
        }, 2000);
      });
  }
  CheckDuplicateEmail(userEmail) {
    if (userEmail == "" || userEmail == undefined) {
    } else {
      this._loginService.checkDuplicateEmail(userEmail).subscribe(data => {
        if (data != 0) {
          this.emailDuplicate = true;
        }
        else {
          this.emailDuplicate = false;
        }
      });
    }
  }
}
