import { Injectable, Injector } from '@angular/core';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { ActivatedRoute, Router } from '@angular/router';
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

  constructor(private adalSvc: MsAdalAngular6Service, private _router: Router) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    var token = this.adalSvc.accessToken;
    //token = null;
    let tokenizedrq = req.clone({
      setHeaders: {
        Authorization: 'Bearer ' + token
      }
    })
    return next.handle(tokenizedrq)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMsg = '';
          if (error.error instanceof ErrorEvent) {
            errorMsg = `Error: ${error.error.message}`;
            window.alert(errorMsg);
            //console.log(erroMsg);
          }
          else if ((error.status === 0) && (error.statusText === "Unknown Error")) {
            window.alert('Session Expired, Please refresh the page or login again');
          }

          else if (error.status === 401) {
            localStorage.clear();
            this.adalSvc.logout();
            this._router.navigate['/'];
          }
          else if (error.status === 500) {
            errorMsg = `Error Code: ${error.status}\nMessage: ${error.error}`;
            window.alert(errorMsg);
          }
          else {
            errorMsg = `Error Code: ${error.status}\nMessage: ${error.error}`;
            //window.alert(errorMsg);
            window.alert('OOps, Something went wrong, Please try again.');
          }

          // return throwError(errorMessage);
          // if (error.status === 401) {
          //    localStorage.clear();
          //    this.adalSvc.logout();
          //    this._router.navigate['/'];
          // // }
          //  //console.log('this is server side error');
          //  //errorMsg = `Error Code: ${error.status},  Message: $ljl{error.message}`;
          //}
          //console.log(errorMsg);
          return throwError(errorMsg);
        })
      )
  }
}
