import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
declare var $: $;
import $ from "jquery";
import { DatePipe } from '@angular/common';
import { BatchwizrdService } from '../batchwizard/batchwizrd.service';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';

declare var $: $;
let GlobalData = [];
@Component({
  selector: "app-batchwizard",
  templateUrl: "./batchwizard.component.html",
  styleUrls: ["./batchwizard.component.css"],
  providers: [DatePipe]
})
export class BatchwizardComponent implements OnInit {
  evntsData: any;
  obj: any;
  selectedEvents = [];
  selectedBatch = [];
  selectedEventIds = [];
  duplicatesRecord = [];
  batchEvents = [];
  LoggedinUser: string;
  JurisdictionID: string;
  totalJurors: any; totalNoOfJurors: any;
  showAlertonPopup: boolean;
  isLoaderBatch: boolean = false;
  constructor(private _datePipe: DatePipe, private _batchwizrdService: BatchwizrdService,
  private _router: Router, private adalSvc: MsAdalAngular6Service ) {
    this.LoggedinUser = this.adalSvc.LoggedInUserName;//localStorage.getItem('UserName');
    this.JurisdictionID = localStorage.getItem("JurisdictionID");
    this.totalNoOfJurors = +localStorage.getItem("NoOfJurors");
    localStorage.setItem('RouteURL', '/home/calendar');
  }

  ngOnInit() {
    this.getcalendar();
    this.viewCount();
  }

  getcalendar() {
    var duplicates = [];
    var self = this;
    $(function () {
      $("#calendar").kendoScheduler({
        height: 600,
        views: [
          "day",
          "week",
          "workWeek",
          { type: "month", selected: true },
          "month",
          "agenda"
        ],
        editable: false,
        eventTemplate: function (e) {
          var indicate = "";

          var val = self.batchEvents.indexOf(e.batchID);
          if (val !== -1) {
            // indicate = "B"
            indicate = '<span class="glyphicon glyphicon-link"></span>';
          }
          // indicate = '<span class="glyphicon glyphicon-bold" style="color:black; font-weight:bold; font-size:20px;">B</span>';
          return (
            '<div class="movie-template">\
                            <input type="checkbox" id="' +
            e.eventID +
            '" class ="k-checkbox" onclick="batch(' + e + ',' + $(this) + ')">\
                            <label style="font-size:14px" class ="k-checkbox-label" for="' +
            e.eventID +
            '">' +
            e.title + '&nbsp;&nbsp'+
            indicate +
            '</label><br/>\<label style="font-size:14px;padding:7px"></label></div>' + ''
          );
        },

        dataSource: {
          sync: function () {
            this.read();
          },
          batch: true,
          transport: {
            read: function (options) {
              self.isLoaderBatch = true;
              self._batchwizrdService.getBatchEvents(self.JurisdictionID).subscribe(data => {
                self.evntsData = data.calenderEventList;
                //console.log(self.evntsData);
                self.obj = self.evntsData.map(data => {
                  if(data.judgeID==null||data.roomNumberID==null){
                  //  room:0;
                  //  JudgeID:0
                    return {
                      eventID: data.eventID,
                      title: data.title,
                      start: data.startDateTime,
                      end: data.endDateTime,
                      summons: data.summonsIssuedCount,
                      room:'',
                      JudgeID:'',
                      recurrenceId: data.recurrenceId,
                      batchID: data.batchID,
                      panel: data.juryServedCount,
                      // panel: 0,
                      description: "data"
                    };
                  }
                  return {
                    eventID: data.eventID,
                    title: data.title,
                    start: data.startDateTime,
                    end: data.endDateTime,
                    summons: data.summonsIssuedCount,
                    room: data.roomNumberID,
                    JudgeID: data.judgeID,
                    recurrenceId: data.recurrenceId,
                    batchID: data.batchID,
                    panel: data.juryServedCount,
                   // panel: 0,
                    description: "data"
                  };
                });
                self.duplicatesRecord = self.obj.map(x => {
                  return x.batchID;
                });
                var sorted_arr = self.duplicatesRecord.slice().sort(); 
                for (var i = 0; i < sorted_arr.length - 1; i++) {
                  if (sorted_arr[i + 1] == sorted_arr[i]) {
                    self.batchEvents.push(sorted_arr[i]);
                  }
                }
                options.success(self.obj);
                self.isLoaderBatch = false;
              });
            }
          }
        },
        update: function (options) {
          return true;
        },
        create: function (options) {
          return true;
        },
        destroy: function (options) {
          return true;
        },
        parameterMap: function (options, operation) {
          if (operation !== "read" && options.models) {
            return { models: options.models };
          }
        },

        schema: {
          model: {
            id: "eventID",
            fields: {
              eventID: { from: "eventID" },
              title: {
                from: "title",
                defaultValue: "No title",
                validation: { required: true }
              },
              start: { type: "date", from: "start" },
              end: { type: "date", from: "end" },
              batchID: { from: "batchID" },
              recurrenceId: {
                from: "recurrenceId"
              },
              recurrenceRule: {
                from: "RecurrenceRule"
              },
              recurrenceException: {
                from: "RecurrenceException"
              },
              JudgeName: { from: "JudgeID" },
              room: { from: "room" },
              summons: { from: "summons" },
              juryServedCount: { from: "juryServedCount" }
            }
          }
        }

      });
    });
    var scheduler = $("#calendar").data("kendoScheduler");

    $(function (e) {
      $("#calendar").kendoTooltip({
        filter: ".k-event",
        position: "top",
        width: 250,
        content: toolTip
      });
    });
    $(function (e) {
      $("#calendar").kendoTooltip({
        filter: ".k-task",
        float:"left",
        position: "top",
        width: 250,
        content: toolTip
      });
    });
    $("#calendar").hover(toolTip);

    function toolTip(e) {
      var target = $(e.target);
      var uid = target.attr("data-uid");
      var scheduler = target
        .closest("[data-role=scheduler]")
        .data("kendoScheduler");
      var model = scheduler.occurrenceByUid(uid);
      if (model) {
        const event = model.description;
      } else {
      }
      if (model != null || model != undefined) {
        return (
          "<div style='text-align:justify;line-height:25px'><b>Event Title &nbsp;:</b>&nbsp;&nbsp;" +
          model.title + "<br>" +
          "<b>Event Start time &nbsp;:</b>&nbsp;&nbsp;" +
          self._datePipe.transform(model.start, "h:mm:ss a") +
          "</br>" +
          "<b>Event End time &nbsp:</b>&nbsp;&nbsp;" +
          self._datePipe.transform(model.end, "h:mm:ss a") +
          "</br>"
          +
          "<b>Judge Name &nbsp:</b>&nbsp;&nbsp;" +
          model.JudgeID +
          "</br>" +
          "<b>Court Room Number &nbsp:</b>&nbsp;&nbsp;" +
          model.room +
          "</br>" +
          "<b>Summonsed &nbsp:</b>&nbsp;&nbsp;" +
          model.summons +
          "</br>" +
          "<b>Panel &nbsp:</b>&nbsp;&nbsp;" +
          model.panel +
          "</br>" +
          "</div>"
        );
      }
    }

    var scheduler = $("#calendar").data("kendoScheduler");
    $(document).on("click", ".k-checkbox", function batch(e, data) {
      var target = $(e.target);
      var uid = e.target.id;
      var checked = this.checked;
      if (checked) {
        var batchEvent = self.obj.map(x => {
          if (x.eventID == uid) {
            var obj = {
              ...x,
              start: self._datePipe.transform(x.start, "MMM d, y, h:mm:ss a"),
              end: self._datePipe.transform(x.end, "MMM d, y, h:mm:ss a")

            }
            self.selectedEvents.push(obj);
          }
          else {
            return x;
          }
        });

      }
      else {
        self.selectedBatch = self.selectedEvents.map((value, index) => {
          if (value.eventID == uid) {
            self.selectedEvents.splice(index, 1);
          }
          else {
            return value;
          }
        }
        );
        //console.log(self.selectedBatch);
      }
    });    
  }

  viewCount() {
    this._batchwizrdService.jurorsLeftCount(this.JurisdictionID).subscribe(data => {
      this.totalJurors = data.countrec;
    });
  }

  jurorRequestMsg(ReqJurors) {
    if (ReqJurors > this.totalJurors) {
      this.showAlertonPopup = true;
      $('#ConfirmRequestJurors').modal('show');
    }
    else {
      this.showAlertonPopup = false;
      $('#ConfirmRequestJurors').modal('hide');
    }
  }

  requestJurors() {
    for (var i = 0; i < this.selectedEvents.length; i++) {
      this.selectedEventIds.push(this.selectedEvents[i].eventID);
    }
    const requestJurorDetails = {
      TotalNoOfJurors: this.totalNoOfJurors,
      JurisdictionID: +this.JurisdictionID,
      EventIds: this.selectedEventIds
    }

    this._batchwizrdService.requestJurorBatchEvent(requestJurorDetails).subscribe(data => {
      if (data.success == true) {
        this._router.navigate(['/home/event/', this.selectedEvents[0].eventID]);
      }
    });
  }
}
