import { Component,OnInit, Inject, ViewChild, ElementRef, AfterViewInit, TemplateRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgForm, FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute, Data } from '@angular/router';
import { Observable } from 'rxjs'
//import { Dataobject } from '../data/dataobject.service';
import { ReportsService } from 'src/app/admin/reports/reports.service';
//import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DatePipe } from '@angular/common';
import { ExcelService } from 'src/app/shared/excel.service';
//import { filter } from 'rxjs/operator/filter';
@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {

  dataParams: any;
    reportData: any = [];
    formReport: any;
    startdate: Date;
    enddate: Date;
    errMessage: boolean = false;
    resultShow;
    reportFlag: boolean = false;
    totalPayAmount: number = 0.0;
    totalDailyAmount: number = 0.0;
    totalDonationAmunt: number = 0.0;
    jurisdictionID:any;
    
    constructor(private _fb: FormBuilder, private _avRoute: ActivatedRoute,
        public http: HttpClient, private _router: Router, private _reportService: ReportsService,private _excelService:ExcelService, private datePipe: DatePipe,) {
        this.resultShow = false;
    }
    ngOnInit() {
        this.totalPayAmount = 0.0;           
        this.formReport = this._fb.group({                   
            'startdate': ['', Validators.compose([Validators.required])],
            'enddate': ['', Validators.compose([Validators.required])], 
        jurisdictionID: new FormControl(328)
        });
       // this.jurisdictionName=localStorage.getItem("JurisdictionName");
//this.CurrentUSername=localStorage.getItem("UserName");
this.jurisdictionID=localStorage.getItem("JurisdictionID");
    }
    goback() {
        this._router.navigate(['admin-data']);
    }
    submit(inputsData) {   
        this._reportService.submitData(inputsData).subscribe(data => {
        this.reportData = [];
        this.totalPayAmount = 0.0;
        this.totalDailyAmount = 0.0;
        this.totalDonationAmunt = 0.0;
            this.reportData = data.listReports;
            //console.log(this.reportData);
            for (var i = 0; i < data.listReports.length; i++) {
                this.totalPayAmount += this.reportData[i].totalAmount;
                this.totalDailyAmount += this.reportData[i].totalDailyPayAmount
                this.totalDonationAmunt += this.reportData[i].totalDonationAmount; 
            }         
            if (data.listReports.length == 0) {
                this.reportFlag = true;
                setTimeout(() => {
                    this.reportFlag = false;
                }, 2000);
            }
            else {
               this.reportFlag = false;
            }
        });
    }
    datecheck(date, param) {
        // if (new Date(this.formReport.controls['reportStartdate'].value) > new Date(this.formReport.controls['reportEnddate'].value)) {
        //     this.errMessage = 'End Date should be greater than start date';
        //     return false;
        // }
        if (param == "StartDate") {
            if (date.startdate >= date.enddate) {
              this.errMessage = true;
            } else {
              this.errMessage = false;
            }
          }
          if (param == "EndDate") {
            if (date.startdate >= date.enddate) {
              this.errMessage = true;
            } else {
              this.errMessage = false;
            }
          }
    }
    reportToExport(reportData:any[]) {
         reportData=reportData.map(data=>{
return{...data,startDateTime:this.datePipe.transform(data.startDateTime,'EEE,MMM d, y')}
         })
           // startDateTime:this.datePipe.transform(reportData[0].startDateTime,'EEE,MMM d, y')

         
      this._excelService.exportExcel(reportData,this.totalPayAmount, this.totalDailyAmount, this.totalDonationAmunt);
    }    
}
