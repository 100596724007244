// <reference path="../../app.browser.module.ts" />
import { Injectable, Inject } from '@angular/core';
import{HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { inject } from '@angular/core/testing';
import{ConfigurationService} from '../configuration.service'
import { catchError } from 'rxjs/operators';


@Injectable()
export class AdminService {
  public baseURL=this.configurationservice.baseURL;
  constructor(private http:HttpClient,private configurationservice:ConfigurationService) { }
  JurisdictionSettingsData(jurisdictionID: Number): Observable<any> {
    return this.http.get(this.baseURL+'AdminApi/GetIntialSettingsDetails?jurisdictionID=' + jurisdictionID)
     // return this.api.get('AdminApi/GetIntialSettingsDetails?jurisdictionID=' + jurisdictionID)
     .pipe(catchError(this.errorHandler));
  }
  JurisdictionSettings(model: any): Observable<any> {
    return this.http.post(this.baseURL + 'AdminApi/JurisdictionSettings', model);
  } 

  UpdateTemplateVersionStatus(model: any): Observable<any> {
    return this.http.post(this.baseURL +'AdminApi/UpdateTemplateVersionStatus', model)
    .pipe(catchError(this.errorHandler));
  }
  LoadTemplate(jurisdictionID: Number): Observable<any> {
    return this.http.get(this.baseURL +'AdminApi/GetTemplateVersion?jurisdictionID=' + jurisdictionID)
    .pipe(catchError(this.errorHandler));
  }
  LoadCertificateTemplate(jurisdictionID: Number): Observable<any> {
    return this.http.get(this.baseURL +'AdminApi/GetCertificateTemplateVersion?jurisdictionID=' + jurisdictionID)
    .pipe(catchError(this.errorHandler));
  }
  deleteTemplate(model: any): Observable<any> {
    return this.http.post(this.baseURL +'AdminApi/DeleteUpdatedTemplateVersion', model)
    .pipe(catchError(this.errorHandler));
  }
  ViewJurorListLog(model: any): Observable<any> {
    return this.http.get(this.baseURL + 'AdminApi/ViewJurorListLog?jurisdictionID=' + model.jurisdictionID +'&ModuleType=' + model.moduleType)
    .pipe(catchError(this.errorHandler));
  }
  //******************************* View all users *****************************************************
  Viewuserdetails(jurisdictionID: number): Observable<any> {
    return this.http.get(this.baseURL+'AdminApi/GetJurisdictionUsers?jurisdictionID='+jurisdictionID)
    .pipe(catchError(this.errorHandler));
  }
  ConfirmUserDelete(model: any): Observable<any> {
    return this.http.post(this.baseURL+'AdminApi/DeleteNewUser', model)
    .pipe(catchError(this.errorHandler));
  } 
  UserAccept(model: any): Observable<any> {
    return this.http.post(this.baseURL+'AdminApi/UpdateNewUser', model)
    .pipe(catchError(this.errorHandler));
}
//*************************************New User Request ********************************
ViewUsers(jurisdictionID: number): Observable<any> {
  return this.http.get(this.baseURL+'AdminApi/GetNewRegsUsers?jurisdictionID=' + jurisdictionID)
  .pipe(catchError(this.errorHandler));
}
//******************************Edit Zip Code ****************** *************** */
ViewJurisdictionDetails(jurisdictionID: Number): Observable<any> {
  return this.http.get(this.baseURL+'AdminApi/GetJurisdictionDetails?jurisdictionID=' + jurisdictionID)
  .pipe(catchError(this.errorHandler));
} 

SaveZip(model: any): Observable<any> {
  return this.http.post(this.baseURL+'AdminApi/UpdateZipCodes', model)
  .pipe(catchError(this.errorHandler));     
}
//***************************************Edit Jurisdiction Profile******************************************************* */
UpdatePhoneFaxEmailByJurisdictionID(model: any): Observable<any> {
  return this.http.post(this.baseURL+'AdminApi/UpdatePhoneFaxEmailByJurisdictionID' ,model)
  .pipe(catchError(this.errorHandler));
}
//******************************************Upload Jurror List******************************************************************** */
viewCount(model: any): Observable<any> {
  return this.http.get(this.baseURL+'AdminApi/jurorsLeftCount?jurisdictionID=' + model)
  .pipe(catchError(this.errorHandler));
}
//***********************************Upload Certificate Template***************************************************** */
GetDBFields(jurisdictionID: Number): Observable<any> {
  return  this.http.get(this.baseURL+'AdminApi/GetDBField?jurisdictionID=' + jurisdictionID)
  .pipe(catchError(this.errorHandler));
}
SelectedTemplateClick(model: any): Observable<any> {
  return this.http.get(this.baseURL+'AdminApi/GetTemplateVersion?templateid=' + model.templateId +'&jurisdictionID=' + model.jurisdictionId)
  .pipe(catchError(this.errorHandler));
}
SaveTemplateParameter(model: any): Observable<any> {
  return this.http.post(this.baseURL+'AdminApi/SaveTemplateParameter', model)
  .pipe(catchError(this.errorHandler));
} 
//************************Missing Url******************************** */
uploadUrl(model:any): Observable<any> {
  return this.http.post(this.baseURL+'AdminApi/ImportJurisdictionData', model)
  .pipe(catchError(this.errorHandler));
} 
ImportJurorFileURL(model: any): Observable<any> {
  return this.http.post(this.baseURL+'AdminApi/ImportJurorFile', model)
  .pipe(catchError(this.errorHandler));
} 

errorHandler(error: Response) {
  return Observable.throw(error);
}
}
