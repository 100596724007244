import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Data } from '@angular/router';
import { DashboardServices } from '../dashboard/dashboard.service';

@Component({
  selector: 'app-viewallevents',
  templateUrl: './viewallevents.component.html',
  styleUrls: ['./viewallevents.component.css']
})
export class ViewalleventsComponent {
  filterText: any;
  key: string = 'name';
  reverse: boolean = false;
  DashParamsData: any;
  UpcommingEvents: any[] = [];
  EventsData: any;
  j: any = -1;
  i: any = 0;
  Summoned: any;
  Assigned: any;
  Potential: any;
  Panel: any;
  p: number = 1;
  searchText: string;
  isLoading: boolean;
  Jid: any = 1;

  constructor(private _dashBoardServices: DashboardServices, private _actRoute: ActivatedRoute, private _router: Router) {
    this.getDashData();
  }
  navigateToEvent(eventID) {
    this._router.navigate(['/home/event/', eventID]);
  }

  getDashData() {
    this.isLoading = true;
    //localStorage.getItem('');
    this.DashParamsData = {
      jurisdictionID: localStorage.getItem('JurisdictionID'),
      isDashboard: localStorage.getItem('isDashboard')
    };
    this._dashBoardServices.getUpcommingEvents(this.DashParamsData).subscribe(data => {
      this.EventsData = data.upcomingEventsList;
      let Prioreventid = 0;
      for (let i = 0; i < data.upcomingEventsList.length; i++) {
        let TotalJurors = 0;
        if (data.upcomingEventsList[i].eventID !== Prioreventid || i === 0) {
          Prioreventid = data.upcomingEventsList[i].eventID;
          // intilize the new array.
          const EventID = data.upcomingEventsList[i].eventID;
          const EventName = data.upcomingEventsList[i].title;
          const EventStart = data.upcomingEventsList[i].startDateTime;
          const EventEnd = data.upcomingEventsList[i].endDateTime;

          this.Potential = 0;
          this.Assigned = 0;
          this.Summoned = 0;
          this.Panel = 0;
          this.j = this.j + 1;
          this.UpcommingEvents[this.j] = {
            EvtIDRtrn: EventID,
            EvtNameRtrn: EventName,
            EvtStartRtrn: EventStart,
            EvtEndRtrn: EventEnd,
            TotalJurRtrn: TotalJurors,
            SummonedRtrn: this.Summoned,
            PotentialRtrn: this.Potential,
            AssignedRtrn: this.Assigned,
            PanelRtrn: this.Panel
          };
        }

        // Summons not sent
        if (data.upcomingEventsList[i].jurorStatus === 5) {
          TotalJurors = data.upcomingEventsList[i].jurorCount + TotalJurors;
          this.UpcommingEvents[this.j].TotalJurRtrn = TotalJurors;
          this.UpcommingEvents[this.j].SumNotSent = data.upcomingEventsList[i].jurorCount;
        }

        // Summonsed
        if (data.upcomingEventsList[i].jurorStatus === 10) {
          TotalJurors = data.upcomingEventsList[i].jurorCount;
          this.UpcommingEvents[this.j].TotalJurRtrn = TotalJurors;
          this.UpcommingEvents[this.j].SummonedRtrn = data.upcomingEventsList[i].jurorCount;
        }

        // Potential
        if (data.upcomingEventsList[i].jurorStatus === 20) {
          TotalJurors = data.upcomingEventsList[i].jurorCount + TotalJurors;
          this.UpcommingEvents[this.j].TotalJurRtrn = TotalJurors;
          this.UpcommingEvents[this.j].PotentialRtrn = data.upcomingEventsList[i].jurorCount;
        }

        // Assigned
        if (data.upcomingEventsList[i].jurorStatus === 35) {
          TotalJurors = data.upcomingEventsList[i].jurorCount + TotalJurors;
          this.UpcommingEvents[this.j].TotalJurRtrn = TotalJurors;
          this.UpcommingEvents[this.j].AssignedRtrn = data.upcomingEventsList[i].jurorCount;
        }

        // Panel
        if (data.upcomingEventsList[i].jurorStatus === 40) {
          TotalJurors = data.upcomingEventsList[i].jurorCount + TotalJurors;
          this.UpcommingEvents[this.j].TotalJurRtrn = TotalJurors;
          this.UpcommingEvents[this.j].PanelRtrn = data.upcomingEventsList[i].jurorCount;
        }
      }
    });
    this.isLoading = false;
  }
  onChangeFilter(data) {
    this.filterText = data;
  }
  sortTableCols(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }
}
