import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgmCoreModule, MapsAPILoader } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction'
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CalendarComponent } from './calendar/calendar.component';
import { BatchwizardComponent } from './batchwizard/batchwizard.component';
import { SearchjurorComponent } from './searchjuror/searchjuror.component';
import { AdminComponent } from './admin/admin.component';
import { DevtoolsComponent } from './devtools/devtools.component';
import { HomeComponent } from './home/home.component';
import { AuthenticationGuard, MsAdalAngular6Module } from 'microsoft-adal-angular6';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ConfigurationService, CustomInterceptor } from './configuration.service';
import { DashboardServices } from './dashboard/dashboard.service';
import { NgxPaginationModule } from 'ngx-pagination';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { Ng2OrderModule } from 'ng2-order-pipe';
import { JurorprofileComponent } from './jurorprofile/jurorprofile.component';
import { ViewalleventsComponent } from './dashboard/viewallevents.component';
import { ToasterService } from './shared/toaster.service';
import { ExcelService } from './shared/excel.service';
import { TooltipModule } from 'ng2-tooltip-directive';
import { DashboardjurorsearchComponent } from './dashboard/dashboardjurorsearch.component';
import { DashboardsearcheventComponent } from './dashboard/dashboardsearchevent.component';
import { AdminService } from './admin/admin.service';
import { EventComponent } from './event/event.component';
import { RecurrenceComponent } from './admin/recurrence/recurrence.component';
import { ReportsComponent } from './admin/reports/reports.component';
import { ReportsService } from './admin/reports/reports.service';
import { RecurrenceService } from './admin/recurrence/recurrence.service';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { OrderModule } from 'ngx-order-pipe';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DevtoolsService } from './devtools/devtools.service';
import { Onboardingstep1Component } from './devtools/onboardingstep1/onboardingstep1.component';
import { Onboardingstep2Component } from './devtools/onboardingstep2/onboardingstep2.component';
import { Onboardingstep3Component } from './devtools/onboardingstep3/onboardingstep3.component';
import { Onboardingstep4Component } from './devtools/onboardingstep4/onboardingstep4.component';
import { Onboardstep1Service } from './devtools/onboardingstep1/onboardstep1.service';
import { Onboardingstep3Service } from './devtools/onboardingstep3/onboardingstep3.service';
import { GooglePlacesDirective } from './shared/google-places.directive';
import { AboutComponent } from './about/about.component';
import * as $ from "jquery";
import { JurorDataComponent } from './JurorData/jurordata.component';
import {TokenInterceptorService} from './token-interceptor.service';
import { OnlynumberDirective } from './shared/OnlynumberDirective ';
import { SafePipe } from './shared/safePipe';

const appRoutes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', component: HomeComponent },
  { path: 'home', component: HomeComponent/*, canActivate: [AuthenticationGuard], canActivateChild: [AuthenticationGuard]*/,
    children: [
     // { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'dashboard', component: DashboardComponent, canActivate: [AuthenticationGuard] },
      { path: 'dashboardjurorsearch', component: DashboardjurorsearchComponent, canActivate: [AuthenticationGuard]},
      { path: 'dashboardsearchEvent', component: DashboardsearcheventComponent, canActivate: [AuthenticationGuard] },
      { path: 'viewallevents', component: ViewalleventsComponent, canActivate: [AuthenticationGuard]},
      { path: 'event/:id', component: EventComponent, canActivate: [AuthenticationGuard]},
      { path: 'calendar', component: CalendarComponent, canActivate: [AuthenticationGuard]},
      { path: 'adminSettings', component: AdminComponent, canActivate: [AuthenticationGuard]},
      { path: 'recurrenceEvent', component: RecurrenceComponent, canActivate: [AuthenticationGuard]},
      { path: 'batchwizard', component: BatchwizardComponent, canActivate: [AuthenticationGuard]},
      { path: 'searchjuror', component: SearchjurorComponent, canActivate: [AuthenticationGuard]},
      { path: 'jurorprofile/:id', component: JurorprofileComponent, canActivate: [AuthenticationGuard] },
      { path: 'reports', component: ReportsComponent, canActivate: [AuthenticationGuard]},
      { path: 'devtools', component: DevtoolsComponent, canActivate: [AuthenticationGuard]},
      { path: 'onboarding-step1', component: Onboardingstep1Component, canActivate: [AuthenticationGuard] },
      { path: 'onboarding-step1/:id', component: Onboardingstep1Component, canActivate: [AuthenticationGuard]},
      { path: 'onboarding-step2', component: Onboardingstep2Component, canActivate: [AuthenticationGuard]},
      { path: 'onboarding-step2/:id', component: Onboardingstep2Component, canActivate: [AuthenticationGuard]},
      { path: 'onboarding-step3', component: Onboardingstep3Component, canActivate: [AuthenticationGuard]},
      { path: 'onboarding-step3/:id', component: Onboardingstep3Component, canActivate: [AuthenticationGuard]},
      { path: 'onboarding-step4', component: Onboardingstep4Component, canActivate: [AuthenticationGuard]},
      { path: 'onboarding-step4/:id', component: Onboardingstep4Component, canActivate: [AuthenticationGuard]},
      { path: 'about', component: AboutComponent },
      { path: 'jurorPortal', component: JurorDataComponent},
      { path: '**', redirectTo: 'dashboard' }
    ]
  },
  { path: '**', redirectTo: 'home', pathMatch: 'full' },
];
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    CalendarComponent,
    BatchwizardComponent,
    SearchjurorComponent,
    AdminComponent,
    DevtoolsComponent,
    HomeComponent,
    JurorprofileComponent,
    ViewalleventsComponent,
    DashboardjurorsearchComponent,
    DashboardsearcheventComponent,
    EventComponent,
    RecurrenceComponent,
    ReportsComponent,
    Onboardingstep1Component,
    Onboardingstep2Component,
    Onboardingstep3Component,
    Onboardingstep4Component,
    GooglePlacesDirective,
    AboutComponent,
    JurorDataComponent,
    OnlynumberDirective,
    SafePipe
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    Ng2OrderModule,
    Ng2SearchPipeModule,
    NgxPaginationModule,
    TooltipModule,
    OrderModule,
    RouterModule.forRoot(appRoutes),
    NgxSpinnerModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCIUyjixFrQ4FAcg9DcFL0nxIC_cgX3xAQ',
      libraries: ['places', 'geometry']
    }),
    AgmDirectionModule,
    MsAdalAngular6Module.forRoot({
      tenant: 'common',
    //clientId: '915d7417-a190-4753-8ae2-f788d523b0d1',// Local
     //clientId: 'fb6dada7-f3a0-46b3-9cd5-66e75e05dc41',//qa
     clientId : '9f83df66-8fa7-4e99-8eb0-c7f1c4ff0c67',//prod
    //redirectUri: 'http://localhost:4200/signin-oidc',//Local
   //  redirectUri: 'https://jmsocourtqa.azurewebsites.net/signin-oidc',//qa
    redirectUri: 'https://jms.ocourts.com/Account/SignIn',//prod
      navigateToLoginRequestUrl: false,
      cacheLocation: 'localStorage',
      expireOffsetSeconds: "1200",
     //postLogoutRedirectUri: 'http://localhost:4200'//Local
      //postLogoutRedirectUri: 'https://jmsocourtqa.azurewebsites.net'//qa
    postLogoutRedirectUri: 'https://jms.ocourts.com',//prod
    }),
    NgMultiSelectDropDownModule.forRoot()
  ],
  providers: [AuthenticationGuard, ConfigurationService, DashboardServices, ToasterService,
    ExcelService, AdminService, DatePipe, RecurrenceService, ReportsService, DevtoolsService,
    Onboardstep1Service, Onboardingstep3Service,
      {
        provide: HTTP_INTERCEPTORS,
        useClass: TokenInterceptorService,
       multi: true
     }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
