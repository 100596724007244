import { Component, OnInit } from '@angular/core';
import { SearchjurorService } from '../searchjuror/searchjuror.service';
import { FormGroup, FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-searchjuror',
  templateUrl: './searchjuror.component.html',
  styleUrls: ['./searchjuror.component.css']
})
export class SearchjurorComponent implements OnInit {
  SearchParamsData;
  JurorID;
  LastName;
  FirstName;
  JurisdictionID;
  SearchJurorData: any = [];
  resultShow;
  NoResult;
  key: string = 'name';
  reverse: boolean = false;

  constructor(private _searchJurorService: SearchjurorService, private _avRoute: ActivatedRoute, private _router: Router) {
    this.JurisdictionID = localStorage.getItem("JurisdictionID");
    this.resultShow = false;
    this.NoResult = false;
    localStorage.setItem('RouterURL', this._router.url);
  }

  ngOnInit() {
    this.SearchParamsData = new FormGroup({
      JurorID: new FormControl(),
      LastName: new FormControl(),
      FirstName: new FormControl(),
      JurisdictionID: new FormControl(this.JurisdictionID)
    });
  }


  isValidForm(){
    const details = this.SearchParamsData.value;
    if ((details.FirstName === null || details.FirstName === '') && (details.JurorID === null || details.JurorID === '') && (details.LastName === null || details.LastName === '')) {
     return true;
    }
    return false;
  }

  onClickSubmit(details) {    
    if ((details.FirstName === null || details.FirstName === '') && (details.JurorID === null || details.JurorID === '') && (details.LastName === null || details.LastName === '')) {
        this.NoResult = true;
        this.resultShow = false;
    } else {
    this._searchJurorService.SearchJurors(details).subscribe(data => {
        this.SearchJurorData = data.jurorDetailsList;

        if (this.SearchJurorData.length === 0) {
            this.NoResult = true;
            this.resultShow = false;
        } else {
            this.resultShow = true;
            this.NoResult = false;
        }
      });
    }
  }

  sortTableCols(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }

  errorHandler(error: Response) {
    return Observable.throw(error);
  }
}
