import { Component, TemplateRef, OnInit } from '@angular/core';
import { NgForm, FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { DashboardServices } from '../dashboard/dashboard.service';
import { ToasterService } from '../shared/toaster.service';
import { ExcelService } from '../shared/excel.service';
import { map, takeUntil, tap } from 'rxjs/operators';
import { LoginService } from '../login/login.service';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnInit {
  SearchJurorsResponseData: any[] = [];
  jurorsearchForm: any;
  DashParamsData: any;
  JurisdictionID;
  eventTitle: string = '';
  eventStartDate: string = '';
  eventEndDate: string = '';
  jurorData: any;
  eventID: any;
  UpcommingEvents: any[] = [];
  EventsData: any;
  searchEventParams: any;
  j: any = -1;
  i: any = 0;
  Summoned: any;
  Assigned: any;
  Potential: any;
  Panel: any;
  p: number = 1;
  filterText: string = '';
  searchText: string;
  key: string = 'name';
  reverse: boolean = false;
  isLoading: boolean = false;
  Jid: any = 1;
  divToPrint: any;
  newWin: any;
  public NoRecFound = false;
  public NoEventFound = false;
  // modalRef: BsModalRef = new BsModalRef;
  eventSearch: any;
  jurorSearch: any;
  searchJurorParam: any;
  noOfUpcomingEvts: any;
  ExcelDownloadData: any[] = [];
  

  constructor(private _dashBoardServices: DashboardServices, private toastr: ToasterService, private _router: Router, private _exportExcel: ExcelService,
    private _loginService: LoginService) {
    this.JurisdictionID = localStorage.getItem("JurisdictionID");
    // this.noOfUpcomingEvts = localStorage.getItem("UpcomingEvts");
    //localStorage.setItem('RouteURL', '/home/dashboard');
    this.searchJurorsFormParms();
  }
  ngOnInit() {
    if (this.JurisdictionID == null || this.JurisdictionID == undefined) {
      this._router.navigate(['/']);
    }
    else {
      this.getDashData();
      this.getIntialSettingsDetails();
    }
  }

  getIntialSettingsDetails() {
    this._loginService.getIntialSettingsDetails(this.JurisdictionID).subscribe(data => {
      localStorage.setItem("UpcomingEvts", data.noOfUpcomingEvts);
      localStorage.setItem("PricePerMile", data.perMileFare);
      localStorage.setItem("PaymentPerDay", data.jurorDailyPay);
      localStorage.setItem("RoundOff", data.roundOff);
      localStorage.setItem("NoOfJurors", data.noOfJurors);
      localStorage.setItem("MaxJurors", data.maxAssignedJurors);
      this.noOfUpcomingEvts = localStorage.getItem("UpcomingEvts");
    });
  }

  searchJurorsFormParms() {
    this.jurorsearchForm = new FormGroup({
      jurorID: new FormControl('', [<any>Validators.required, <any>Validators.minLength(5)]),
      searchEvtInput: new FormControl('', [<any>Validators.required]),
      JurisdictionID: new FormControl(this.JurisdictionID)
    });
  }

  searchJurors() {
    this.isLoading = true;
    this.searchJurorParam = {
      JurisdictionID: this.JurisdictionID,
      JurorSearch: this.jurorSearch,
    };
    this._dashBoardServices.ObjData = this.searchJurorParam;
    this._router.navigate(['/home/dashboardjurorsearch']);
  }

  searchEvents() {
    this.isLoading = true;
    this.searchEventParams = {
      SearchEvtInput: this.eventSearch,
      JurisdictionID: this.JurisdictionID,
      SessionContext: localStorage.getItem("SessionContext")
    };
    this._dashBoardServices.ObjData = this.searchEventParams;
    this._router.navigate(['/home/dashboardsearchEvent']);
  }

  getDashData() {
    this.isLoading = true;
    this.DashParamsData = {
      jurisdictionID: this.JurisdictionID,
      isDashboard: true
    };
    this._dashBoardServices.getUpcommingEvents(this.DashParamsData).subscribe(data => {
      this.EventsData = data.upcomingEventsList;
      let Prioreventid = 0;
      for (let i = 0; i < data.upcomingEventsList.length; i++) {
        let TotalJurors = 0;
        if (data.upcomingEventsList[i].eventID !== Prioreventid || i === 0) {
          Prioreventid = data.upcomingEventsList[i].eventID;
          // intilize the new array.
          const EventID = data.upcomingEventsList[i].eventID;
          const EventName = data.upcomingEventsList[i].title;
          const EventStart = data.upcomingEventsList[i].startDateTime;
          const EventEnd = data.upcomingEventsList[i].endDateTime;

          this.Potential = 0;
          this.Assigned = 0;
          this.Summoned = 0;
          this.Panel = 0;
          this.j = this.j + 1;
          this.UpcommingEvents[this.j] = {
            EvtIDRtrn: EventID,
            EvtNameRtrn: EventName,
            EvtStartRtrn: EventStart,
            EvtEndRtrn: EventEnd,
            TotalJurRtrn: TotalJurors,
            SummonedRtrn: this.Summoned,
            PotentialRtrn: this.Potential,
            AssignedRtrn: this.Assigned,
            PanelRtrn: this.Panel
          };
        }

        // Summons not sent
        if (data.upcomingEventsList[i].jurorStatus === 5) {
          TotalJurors = data.upcomingEventsList[i].jurorCount + TotalJurors;
          this.UpcommingEvents[this.j].TotalJurRtrn = TotalJurors;
          this.UpcommingEvents[this.j].SumNotSent = data.upcomingEventsList[i].jurorCount;
        }

        // Summonsed
        if (data.upcomingEventsList[i].jurorStatus === 10) {
          TotalJurors = data.upcomingEventsList[i].jurorCount;
          this.UpcommingEvents[this.j].TotalJurRtrn = TotalJurors;
          this.UpcommingEvents[this.j].SummonedRtrn = data.upcomingEventsList[i].jurorCount;
        }

        // Potential
        if (data.upcomingEventsList[i].jurorStatus === 20) {
          TotalJurors = data.upcomingEventsList[i].jurorCount + TotalJurors;
          this.UpcommingEvents[this.j].TotalJurRtrn = TotalJurors;
          this.UpcommingEvents[this.j].PotentialRtrn = data.upcomingEventsList[i].jurorCount;
        }

        // Assigned
        if (data.upcomingEventsList[i].jurorStatus === 35) {
          TotalJurors = data.upcomingEventsList[i].jurorCount + TotalJurors;
          this.UpcommingEvents[this.j].TotalJurRtrn = TotalJurors;
          this.UpcommingEvents[this.j].AssignedRtrn = data.upcomingEventsList[i].jurorCount;
        }

        // Panel
        if (data.upcomingEventsList[i].jurorStatus === 40) {
          TotalJurors = data.upcomingEventsList[i].jurorCount + TotalJurors;
          this.UpcommingEvents[this.j].TotalJurRtrn = TotalJurors;
          this.UpcommingEvents[this.j].PanelRtrn = data.upcomingEventsList[i].jurorCount;
        }
      }
      this.isLoading = false;
    });
    // this.dataObj.storage = this.UpcommingEvents;
  }

  getJurorList(evntsData) {
    this.isLoading = true;
    this.eventTitle = evntsData.EvtNameRtrn;
    this.eventStartDate = evntsData.EvtStartRtrn;
    this.eventEndDate = evntsData.EvtEndRtrn;
    this.eventID = evntsData.EvtIDRtrn;
    const eventID = evntsData.EvtIDRtrn;

    this._dashBoardServices.getJurorList(this.eventID, this.DashParamsData.jurisdictionID).subscribe(data => {
      this.jurorData = data.eventJurorList.filter(function (element) {
        return element.eventID == eventID;
      });

      this.isLoading = false;
    });
    this.jurorData = [];
  }
  navigateToEvent(eventID) {
    this._router.navigate(['/home/event/', eventID]);
  }
  closeJurorList() {
    this.p = 1;
    this.filterText = '';
    this.searchText = '';
    this.key = '';
  }

  viewAllEvents() {
    localStorage.setItem('isDashboard', 'true');
    this._router.navigate(['/home/viewallevents']);
  }

  onChangeFilter(data) {
    this.filterText = data;
  }

  sortTableCols(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }

  exportToExcel() {

    if (this.filterText === "Summons Not Issued") {
      this.ExcelDownloadData = this.jurorData.filter(function (element) {
        return (element.statusDescription === 'Summons Not Issued');
      });
    }
    else if (this.filterText === "Summons Issued") {
      this.ExcelDownloadData = this.jurorData.filter(function (element) {
        return (element.statusDescription === 'Summons Issued');
      });
    }
    else if (this.filterText === "Potential") {
      this.ExcelDownloadData = this.jurorData.filter(function (element) {
        return (element.statusDescription === 'Potential');
      });
    }
    else if (this.filterText === "Assigned") {
      this.ExcelDownloadData = this.jurorData.filter(function (element) {
        return (element.statusDescription === 'Assigned');
      });
    }
    else if (this.filterText === "Jury Served") {
      this.ExcelDownloadData = this.jurorData.filter(function (element) {
        return (element.statusDescription === 'Jury Served');
      });
    }
    else if (this.filterText === "Commited Felony") {
      this.ExcelDownloadData = this.jurorData.filter(function (element) {
        return (element.statusDescription === 'Commited Felony');
      });
    }
    else if (this.filterText === "Undelivered") {
      this.ExcelDownloadData = this.jurorData.filter(function (element) {
        return (element.statusDescription === 'Undelivered');
      });
    }
    else {
      this.ExcelDownloadData = this.jurorData
    }

    this._exportExcel.exportToExcel(this.ExcelDownloadData);
  }

  printBadges(printsection) {
    const divToPrint = document.getElementById('print-section').innerHTML;
    // this.newWin = window.open('');
    // this.newWin.document.write(this.divToPrint.outerHTML);
    // this.newWin.print();
    // this.newWin.close();
    const popupWinindow = window.open('', '_blank', 'width=800,height=800,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
    popupWinindow.document.open();
    popupWinindow.document.write('<html><head><link href="event.component.css" rel="stylesheet" /></head><body onload="window.print()">' + divToPrint + '</body></html>');
    popupWinindow.document.close();
  }

  searchJurorHandler(event) {
    // 13 is keycode for Enter
    if (event.which === 13) {
      this.searchJurors();
    }
  }

  searchEventHandler(event) {

    // 13 is keycode for Enter
    if (event.which === 13) {
      this.searchEvents();
    }
  }
}
