
import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit, TemplateRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgForm, FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute, Data } from '@angular/router';
import { Observable } from 'rxjs'
import { RecurrenceService } from '../recurrence/recurrence.service';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { map } from 'rxjs/operators';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
@Component({
  selector: 'app-recurrence',
  templateUrl: './recurrence.component.html',
  styleUrls: ['./recurrence.component.css']
})
export class RecurrenceComponent implements OnInit {
  recurrenceEventForm: FormGroup;
  RecurrenceType: string = '';
  StartTime: any;
  EndTime: any;
  allItems = [];
  Name: any;
  StartDate: any;
  EndDate: any;
  WeekNo: string = '';
  DaysOfWeek = [];
  dataParams: any;
  formRecurrence: FormGroup;
  startdate: Date = new Date();
  enddate: Date = new Date();
  jurisdictionID: any
  CurrentUSername: string
  recurrenceDelete: any;
  errMessage: string = '';
  resultShow;
  weekDaysModel: any = [];
  Weeklydata: any = [];
  recDeletedAlert;
  eventsList: any = [];
  showDiv: boolean = false;
  showWeekDiv: boolean = false;
  showWeekDayDiv: boolean = false;
  showWeekDaysDiv: boolean = false;
  validatedate: boolean = false;
  dateValidationMsg: boolean = false;
  isEditSave: boolean;
  disableBtnFlag2: boolean = true;
  errMessagestart: string = '';
  disableBtnFlag1: boolean = true;
  selectedRepeatEvent: string = '';
  selectedWeekNo: number = 0;
  selectedWeekDay: number = 0;
  selectedWeekDays;
  selectedWeekDaysList: any = [];
  isLoading: boolean = false;
  starttime;
  endtime;
  selected: any = [];
  ErrorMessage: string = "";
  showMessage: boolean = false;
  ErrorMessagenoevent: string = "";
  showMessagenoevent: boolean = false;

  //DaysReq: Number = 0;
  DaysReq: any;
  showRecurrence: Boolean = false;
  key: string = 'startTime';
  reverse: boolean = false;
  isEditEndDate: boolean = false;
  editReccurenceEventForm: FormGroup;
  RecEndDate;
  EventName;
  StartRecDate;
  RecID;
  RecRule;
  RecOccurence;
  recurrenceSuccesMsg: string = '';
  endDateTime;
  isRecurrenceSuccess: boolean = false;
  recTitle;
  startDateTime;
  recStartTime;
  recEndTime;
  recEventName;
  selectedEventRepeat;
  endTime = "01-31-2019";
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  data = [];
  Days: string;

  allSelectedDays = [];
  loggedUserName: string;
  errStartTimeMsg: string;
  errEndTime: string;
  EditEndDate: string;
  currentDate: Date;
  recurrenceFailedMsg: string = "";
  isRecurrenceFailed: boolean = false;

  constructor(private _fb: FormBuilder, private _avRoute: ActivatedRoute,
    private http: HttpClient, private _router: Router, private _recurrenceService: RecurrenceService, public datepipe: DatePipe, private fb: FormBuilder,
    private spinner: NgxSpinnerService, private adalSvc: MsAdalAngular6Service) {
    this.resultShow = false;
    this.GetAllRecurenceEvents();
    this.StartTime = '08:00:00';
    this.EndTime = '10:00:00';
  }

  ngOnInit() {
    this.currentDate = new Date();
    //  this.jurisdictionName=localStorage.getItem("JurisdictionName");
    this.CurrentUSername = this.adalSvc.LoggedInUserName;//localStorage.getItem('UserName');
    this.loggedUserName = this.adalSvc.LoggedInUserName;//localStorage.getItem('UserName');

    this.recurrenceEventForm = this._fb.group({
      Name: ['', [Validators.required]],
      EndDate: ['', [Validators.required]],
      EndTime: ['10:00:00', [Validators.required]],
      RecurrenceType: ['', [Validators.required]],
      StartDate: ['', [Validators.required]],
      StartTime: ['08:00:00', [Validators.required]],
      WeekNo: [''],
      DaysOfWeek: [''],
      selectedItems: ['']
    });
    this.editReccurenceEventForm = this.fb.group({
      EndDate: ['', [Validators.required]],
    })

    this.dropdownList = [
      { item_id: 1, item_text: 'Monday' },
      { item_id: 2, item_text: 'Tuesday' },
      { item_id: 3, item_text: 'Wednesday' },
      { item_id: 4, item_text: 'Thursday' },
      { item_id: 5, item_text: 'Friday' }
    ];
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: false
    };
  }

  /** Create recurrence event code */
  onChangeRepeatEvent(RecurrenceType) {
    if (RecurrenceType === "Weekly") {
      this.WeekNo = '';
      this.DaysOfWeek = [''];
      this.recurrenceEventForm.controls['selectedItems'].reset();
      this.selectedItems=[];
    }
    else if (RecurrenceType === "Biweekly") {
      this.WeekNo = '';
      this.DaysOfWeek = [''];
      this.recurrenceEventForm.controls['selectedItems'].reset();
    }
    else if (RecurrenceType === "Monthly") {
     this.WeekNo = '';
     this.DaysOfWeek = [''];
     this.recurrenceEventForm.controls['selectedItems'].reset();
    }
    if (RecurrenceType == '') {
      this.WeekNo = '';
      this.DaysOfWeek = [''];
      this.recurrenceEventForm.controls['selectedItems'].reset();
    }
  }

  /** ng-multiselect dropdown* */
  onItemSelect(items: any) {
    if(this.data.length==5){
      this.data=[];
    }
    this.data.push(items.item_id);
  }

  onSelectAll(allSelectedItems){
   allSelectedItems.map(x => { 
     if(this.data.indexOf(x.item_id)==-1)
    {
      this.data.push(x.item_id);
    } 
    });
  // console.log(this.data);
    }
  onItemDeSelect(items:any){
    //console.log(items);
      var index = this.data.indexOf(items.item_id);
     if(index>=0){
      var res=this.data.splice(index, 1);
     }
     // console.log(this.data);
  }
  unSelectAll(value:any){
    //console.log(value);
  }
  submitrecurrence(inputsData): void {
    if (inputsData.WeekNo == null || inputsData.WeekNo == "" || inputsData.WeekNo == undefined) {
      inputsData.WeekNo = 0;
    }
    else {
      this.WeekNo = inputsData.WeekNo;
    }
    if (inputsData.DaysOfWeek == null || inputsData.DaysOfWeek == "" || inputsData.DaysOfWeek == undefined) {
      this.DaysReq = 0;
      // this.DaysOfWeek = this.selectedItems;
      this.data.sort(function(a, b){return a - b});
      this.DaysOfWeek = this.data;
      this.data = [];
      // if ((this.allItems.length)== 5) {
      //   this.DaysOfWeek = this.allItems;
      //   this.allItems = [];
      // }
    }
    else {
      this.DaysReq = inputsData.DaysOfWeek;
      this.DaysOfWeek = [];
    }

    this.dataParams = {
      DaysOfWeek: this.DaysOfWeek,
      EndDate: inputsData.EndDate,
      EndTime: inputsData.EndTime,
      Name: inputsData.Name,
      RecurrenceType: inputsData.RecurrenceType,
      StartDate: inputsData.StartDate,
      StartTime: inputsData.StartTime,
      WeekNo: inputsData.WeekNo,   
      JurisdictionID: localStorage.getItem("JurisdictionID"),       
      CurrentUser: this.CurrentUSername,
      DaysReq: this.DaysReq,
      RequestType: 'insert'
    };
    this._recurrenceService.submitrecurrence(this.dataParams).subscribe(data => {
      if (data.success == true) {
        this.errMessagestart = "",
        this.ErrorMessage = "Event created successfully";
        this.showMessage = true;
        this.isLoading = true;
        this.clearRecurrenceData();
        this.GetAllRecurenceEvents();
        setTimeout(() => {
          this.ErrorMessage = "";
          this.showMessage = false;
          this.isLoading = false;
        }, 3000);
      }
      else {
        this.ErrorMessagenoevent = "Invalid date selection for Repeat Event selected combination";
        this.showMessagenoevent = true;
        this.clearRecurrenceData();
        setTimeout(() => {
          this.ErrorMessagenoevent = "";
          this.showMessagenoevent = false;
        }, 3000);
      }

    });
  }

  switchBool(setAlertText) {
    this[setAlertText] = !this[setAlertText];
  }
  clearRecurrenceData() {
    this.recurrenceEventForm.controls['RecurrenceType'].reset();
    this.recurrenceEventForm.controls['StartDate'].reset();
    this.recurrenceEventForm.controls['EndDate'].reset();
    this.recurrenceEventForm.controls['Name'].reset();
    this.WeekNo = '';
    this.DaysOfWeek = ['']
    this.RecurrenceType = '';
    // this.recurrenceEventForm.controls['DaysOfWeek'].reset();
    // this.recurrenceEventForm.controls['WeekNo'].reset();
    this.recurrenceEventForm.controls['selectedItems'].reset();

  }
  checkErr(startdate, enddate) {
    this.errMessage = '';
    this.errMessagestart = "";
    var curDate = new Date();
    if (new Date(startdate) >= new Date(enddate)) {
      this.errMessage = 'End Date should be greater than start date';
      this.disableBtnFlag2 = true;
      return false;

    }
    if (new Date(startdate) <= new Date(curDate)) {
      this.errMessagestart = 'Start date should not be before today.';
      this.disableBtnFlag1 = true;
      return false;

    }
    if (startdate == undefined || enddate == undefined) {
      this.disableBtnFlag1 = true;
      this.disableBtnFlag2 = true;
    }
    else {
      this.disableBtnFlag1 = false;
      this.disableBtnFlag2 = false;
    }
    setTimeout(() => {
      this.errMessage = '';
      this.errMessagestart = "";
    }, 2000);
  }
  checkTime(startime, endtime) {
    this.errStartTimeMsg = '';
    this.errEndTime = "";
    //console.log("Start Time" + startime);
    if (startime >= endtime) {
      this.errStartTimeMsg = 'End Time should be greater than start time';
      return false;

    }
  }
  recurrence(data) {
    this.selected = data;
  }
  goback() {
    this.formRecurrence.reset();
    this._router.navigate(['admin-data']);
  }
  Datevalidation(startdate, recurrenceenddate) {
    //this.RecEndDate = null;
    var curDate = new Date();
    if (new Date(startdate) > new Date(recurrenceenddate)) {
      this.validatedate = false;
      this.dateValidationMsg = true;
    }
    else {
      this.validatedate = true;
      this.dateValidationMsg = false;
    }
  }
  sortTableCols(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }
  onChange(newValue) {
   // console.log(newValue);
  }
  errorHandler(error: Response) {
    console.log(error);
    return Observable.throw(error);
  }

  /*---------------------Edit recurrence event------------------------*/
  GetAllRecurenceEvents() {
    this.isLoading = true;
    this.jurisdictionID = localStorage.getItem("JurisdictionID");
    this._recurrenceService.getRecurrenceEvents(this.jurisdictionID).subscribe(data => {
      this.spinner.hide();
      let tempData = data.eventInfoList;
      let arrayIdx = [];
      let singleIdx = [];
      tempData.forEach(a=>{
      let Idx=  tempData.filter(b=> a.eventName == b.eventName && a.startTime == b.startTime && a.endTime == b.endTime)
        if(Idx.length > 1){
          let filterIdx = Idx.filter(x=>x.statusId == 20 || x.statusId == 35 || x.statusId == 40)[0];
          arrayIdx.push(filterIdx)
        }
        else if(Idx.length == 1) {
          singleIdx.push(Idx[0])
        }
      })
      arrayIdx = arrayIdx.filter(x =>{
        if(x){
          return x;
        }
      })
      let filterData = arrayIdx.filter((a,i,v)=> v.findIndex(b=>a.eventName && a.eventName == b.eventName && a.startTime == b.startTime && a.endTime == b.endTime)==i);
      this.eventsList = [...filterData,...singleIdx];
      
      console.log(this.eventsList,filterData,singleIdx);
      this.isLoading = false;
    });
  }
  closeRecurrencePopup() {

  }
  delete() {
    this.recurrenceDelete = {
      RecurrenceId: this.selected,
      CurrentUser: this.CurrentUSername

    }
    this._recurrenceService.delete(this.recurrenceDelete).subscribe(data => {
      if (data.success == true) {
        this.recDeletedAlert = true;
        setTimeout(() => {
          this.showRecurrence = false;
          this.recDeletedAlert = false;
          this.GetAllRecurenceEvents();
        }, 2000);
      }
      else {
        this.recDeletedAlert = false;
      }
    });
  }

  EditPopup(data) {
    let latest_date = this.datepipe.transform(data.endTime, 'yyyy-MM-dd');
    //console.log(latest_date);
    this.endDateTime = latest_date;
    this.recTitle = data.eventName;
    this.startDateTime = data.startTime;
    this.EventName = data.eventName;
    this.StartRecDate = data.startTime;
    this.RecEndDate = data.endTime;
    this.RecID = data.recurrenceId;
    this.RecRule = data.recurrenceRule;
    this.RecOccurence = data.occurrence;
  }
  edit(endDate, data) {
    this.dataParams = {
      CurrentUser: this.CurrentUSername,
      EndTime: endDate,
      RecurrenceId: this.RecID
    };
    this._recurrenceService.edit(this.dataParams).subscribe(data => {
      if (data.success == true) {
        this.recurrenceSuccesMsg = "End Date Updated successfully";
        this.isRecurrenceSuccess = true;
        this.GetAllRecurenceEvents();
        setTimeout(() => {
          this.recurrenceSuccesMsg = "";
          this.isRecurrenceSuccess = false;
        }, 4000);
      }
      else {
        this.recurrenceFailedMsg = "Something went wrong";
        this.isRecurrenceFailed = true;
        this.GetAllRecurenceEvents();
        setTimeout(() => {
          this.recurrenceFailedMsg = "";
          this.isRecurrenceFailed = false;
        }, 4000);
      }
    });

  }
  checkEndDateErr(startdate, enddate) {
    // this.EditEndDate='';
    var curDate = new Date();
    if (new Date(startdate) > new Date(enddate)) {
      this.EditEndDate = "End Date should be greater than start date";
      this.editReccurenceEventForm.controls['EndDate'].setErrors({ 'incorrect': true });
      // this.isEditSave=false;
      return false;
    }
    else {
      this.EditEndDate = '';
    }
  }
}


