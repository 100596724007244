import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ConfigurationService } from '../configuration.service';

@Injectable({
  providedIn: 'root'
})

export class CalendarService {
  private _baseURL: string;
  public objData: string;

  constructor(private http: HttpClient, private configuration: ConfigurationService) {
    this._baseURL = configuration.baseURL;
  }
  public getCalenarEvents(model: any): Observable<any> {
    return this.http.get(this._baseURL + 'CanlenderApi/GetCalendarEvents?jurisdictionID=' + model)
      .pipe(catchError(this.errorHandler));
  }

  errorHandler(error: Response) {
    return Observable.throw(error);
  }
  public saveCalendarEventInfo(model: any): Observable<any> {
    return this.http.post(this._baseURL + 'CanlenderApi/SaveCalenderEventInfo', model)
  }
}
