import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';


@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
 //baseURL: string = 'http://localhost:7465/api/';//local
 //baseURL: string = 'https://jmsocourtqaapi.azurewebsites.net/api/';//qa
 baseURL: string = 'https://jmsocourtapi.azurewebsites.net/api/';//prod
  //azurecertificateurl: string = 'https://docs.google.com/a/umd.edu/viewer?url=https%3a%2f%2fjmsstorageaccountdev.blob.core.windows.net%2fjms%2f';
 // azurecertificateurl: string = 'https://docs.google.com/a/umd.edu/viewer?url=https%3a%2f%2fjmsstorageaccountprod.blob.core.windows.net%2fjms%2f';
  //azurecertificateurl: string = 'https://view.officeapps.live.com/op/view.aspx?src=https%3a%2f%2fjmsstorageaccountdev.blob.core.windows.net%2fjms%2f';   //qa
  azurecertificateurl: string = 'https://view.officeapps.live.com/op/view.aspx?src=https%3a%2f%2fjmsstorageaccountprod.blob.core.windows.net%2fjms%2f';//prod
}
export class CustomInterceptor implements HttpInterceptor {
  constructor(private adalSvc: MsAdalAngular6Service) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var token = this.adalSvc.accessToken;

    req = req.clone({ headers: req.headers.set('Authorization', 'Bearer' + token) });

    req = req.clone({ headers: req.headers.set('Content-Type', 'application/json') });

    req = req.clone({ headers: req.headers.set('Accept', 'application/json') });

    // if (!req.headers.has('Content-Type')) {
    //   req = req.clone({
    //    headers: req.headers.set('Content-Type', 'application/json')
    //   });
    // }

    // req = req.clone({
    //   headers: req.headers.set('Accept', 'application/json')
    // });
    return next.handle(req);
  }
}
