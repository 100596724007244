import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigurationService } from '../configuration.service';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class SearchjurorService {
  private _baseURL: string;

  constructor(private _http: HttpClient, private _configuration: ConfigurationService) {
    this._baseURL = _configuration.baseURL;
  }

  SearchJurors(model: any): Observable<any> {
    return this._http.post(this._baseURL + 'DashboardApi/SearchJurors', model)
      .pipe(catchError(this.errorHandler));
  }

  errorHandler(error: Response) {
    return Observable.throw(error);
  }
}
