import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardServices } from '../dashboard/dashboard.service';

@Component({
  selector: 'app-dashboardjurorsearch',
  templateUrl: './dashboardjurorsearch.component.html',
  styleUrls: ['./dashboardjurorsearch.component.css']
})
export class DashboardjurorsearchComponent {
  jurorSearchResult: any;
  eventsearchlength: any;
  searchTitle: any;
  key: string = 'name';
  reverse: boolean = false;
  NoResultFound: boolean = false;
  NoSearchResultFound: boolean = false;

  constructor(private _dashBoardServices: DashboardServices, private _router: Router) {
    this.dashboardJurorSearch();
    localStorage.setItem('RouterURL', this._router.url);
  }

  dashboardJurorSearch() {
    const searchJurorParam = this._dashBoardServices.ObjData;
    this.eventsearchlength = 0;

    if (searchJurorParam !== undefined) {
      this._dashBoardServices.SearchJurors(searchJurorParam).subscribe(data => {
        this.jurorSearchResult = data.jurorDetailsList;
        if (this.jurorSearchResult.length === 0) {
          if (searchJurorParam.JurorSearch === undefined || searchJurorParam.JurorSearch === '') {
            this.NoResultFound = true;
            this.NoSearchResultFound = false;
          } else {
            this.searchTitle = searchJurorParam.JurorSearch;
            this.NoResultFound = false;
            this.NoSearchResultFound = true;
          }
        } else {
          this.eventsearchlength = this.jurorSearchResult.length;
          this.searchTitle = searchJurorParam.JurorSearch;
          this.NoResultFound = false;
          this.NoSearchResultFound = false;
        }
      });
    } else {
      this.NoResultFound = true;
      this.NoSearchResultFound = false;
    }
  }

  sortTableCols(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }
}
