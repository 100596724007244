import { Injectable } from '@angular/core';
import { ConfigurationService } from 'src/app/configuration.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class Onboardingstep2Service {
 public baseURL=this.configurationservice.baseURL;
  constructor(private configurationservice: ConfigurationService,private http:HttpClient) {

  }
  GetJurisdictionDetails(model: any): Observable<any> {
      return this.http.get(this.baseURL+'OnboardApi/GetJurisdictionDetails?jurisdictionID=' + model.jurisdictionID + '&stepNo=' + model.stepNo)
      .pipe(catchError(this.errorHandler));
  } 
  TableCreateScript(model: any): Observable<any> {
      return this.http.post(this.baseURL+'OnboardApi/TableCreateScript', model)
      .pipe(catchError(this.errorHandler));
  }  
  errorHandler(error: Response) {
    return Observable.throw(error);
  }
}
