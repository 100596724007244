import { Injectable, Inject } from '@angular/core';
import{HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { inject } from '@angular/core/testing';
import{ConfigurationService} from 'src/app/configuration.service'
import { catchError } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class RecurrenceService {
  public baseURL=this.configurationservice.baseURL;
    constructor(private http:HttpClient,private configurationservice:ConfigurationService) {
    }
    //submitData(model: any): Observable<any> {
    //    return this.api.get('AdminApi/Report?EventSearchStartDate=' + model.startdate + '&EventSearchEndDate=' + model.enddate + '&JurisdictionID=' + model.jurisdictionID);

    //}
    submitrecurrence(model: any): Observable<any> {
        return this.http.post(this.baseURL+'AdminApi/CreateRecurrence', model)
        .pipe(catchError(this.errorHandler));
    }
    getRecurrenceEvents(jurisdictionID: number): Observable<any> {
        return this.http.get(this.baseURL+'AdminApi/GetRecurrenceEvent?jurisdictionID=' + jurisdictionID)
        .pipe(catchError(this.errorHandler));
    }   
    edit(model: any): Observable<any> {
        return this.http.post(this.baseURL+'AdminApi/EditRecurrence', model)
        .pipe(catchError(this.errorHandler));
    }
    delete(model: any): Observable<any> {
        return this.http.post(this.baseURL+'AdminApi/DeleteRecurrence', model)
        .pipe(catchError(this.errorHandler));
    }
    errorHandler(error: Response) {
      return Observable.throw(error);
    }
}
