import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Onboardingstep4Service } from './onboardingstep4.service';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';

@Component({
  selector: 'app-onboardingstep4',
  templateUrl: './onboardingstep4.component.html',
  styleUrls: ['./onboardingstep4.component.css']
})
export class Onboardingstep4Component implements OnInit {
  dataParams;
  updatedJurisID;
  isLoading: boolean = false;
  submitAlertMessage: boolean = false;
  addNewUserJurisdiction: FormGroup;
  step4Data: any;
  Fname: any;
  Lname: any;
  UEmail: any;
  loggedUserName: string;
  UserID: any;
  isLoader:boolean;
  submitAlertMsg: string;
  submitAlertWarningMsg: boolean;
  emailDuplicate: boolean;
  EditUserID: any;
  constructor(private _fb: FormBuilder, private _avRoute: ActivatedRoute,
    public http: HttpClient, private _router: Router,
    private _onboardingstep4Service: Onboardingstep4Service,
    private adalSvc: MsAdalAngular6Service) {
    this.loggedUserName = this.adalSvc.LoggedInUserName;//localStorage.getItem('UserName');
    this.UserID = localStorage.getItem("UserID");
  }
  ngOnInit() {
    this.updatedJurisID = this._avRoute.snapshot.params['id'];    

    this.addNewUserJurisdiction = this._fb.group({
      Fname: ['', [Validators.required]],
      Lname: ['', [Validators.required]],
      UEmail: ['', [Validators.required, Validators.pattern(/^[\w.]+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/)]]
    })
    this.loadJurisdictionDetails();
  }
  loadJurisdictionDetails(){
    if (this.updatedJurisID != 0 || this.updatedJurisID != null || this.updatedJurisID != undefined) {
     
      this.dataParams = {
        jurisdictionID: this.updatedJurisID,
        //  stepNo: 4
      };
      if (this.updatedJurisID > 0) {
        this.isLoading = true;
        this.dataParams = {
          jurisdictionID: this.updatedJurisID,
          stepNo: 4
        };
      }
      this.isLoader=true;
      this._onboardingstep4Service.GetJurisdictionDetails(this.dataParams).subscribe(data => {
        if (data['success'] == true) {
          this.isLoader = false;
          this.step4Data = data.listJurisdictions[0];
          console.log(this.step4Data);
          this.Fname = this.step4Data.firstName;
          this.Lname = this.step4Data.lastName;
          this.UEmail = this.step4Data.email;
          this.EditUserID=this.step4Data.userId
        }
      });
      this.isLoading = false;
    }
  }
  FinalSubmit(value: any) {
    if (this.step4Data != undefined) {
      //update
      this.dataParams = {
        JurisdictionID: this.updatedJurisID,
        LoggedUserID: this.loggedUserName,
        UserID: this.EditUserID,
        FirstName: value.Fname,
        LastName:  value.Lname,
        EmailID:  value.UEmail,// new Email ID
        UserName:this.step4Data.email,//Old Username or Email Id
        RequestType: "UPDATE"
      }
    }
    else if (this.step4Data == undefined) {
      this.dataParams = {
        //insert
        FirstName: value.Fname,
        LastName: value.Lname,
        EmailID: value.UEmail,
      //  UserName: value.UEmail,
        JurisdictionID: this.updatedJurisID,
        LoggedUserID: this.loggedUserName,
        RequestType: "INSERT"
      };
    }

    this._onboardingstep4Service.Onboardingfinalstep(this.dataParams).subscribe(data => {
      if (data.success == true) {
        this.submitAlertMessage = true;
        this.submitAlertMsg='Submitted sucessfully'

        setTimeout(() => {
          this.submitAlertMessage = false;
          this.submitAlertMsg='';
          this._router.navigate(['/home/devtools']);
        }, 2000);

      }
      else {
        this.submitAlertWarningMsg=true;
        this.submitAlertMsg='Submit failed';
        setTimeout(() => {
          this.submitAlertWarningMsg = false;
          this.submitAlertMsg='';
        }, 2000);
      }
    });

  }
  backtoStepThree() {
    this._router.navigate(['/home/onboarding-step3', this.updatedJurisID]);
  }
  checkDuplicateEmail(userData) {
    const userEmail = userData.UEmail;
    const jurisdictionId = this.updatedJurisID;

    if ((userEmail == "" || userEmail == undefined || userEmail == null) || (jurisdictionId == "" || jurisdictionId == undefined || jurisdictionId == null)) {
    } else {
      const userDetails = {
        Email: userEmail,
        JurisdictionID: jurisdictionId
      }

      this._onboardingstep4Service.checkDuplicateEmail(userDetails).subscribe(data => {
       if (data != 0) {
         this.emailDuplicate = true;
       }
       else {
         this.emailDuplicate = false;
       }
      });
    }
  }
}
