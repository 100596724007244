import { Injectable } from '@angular/core';
import * as Excel from 'exceljs/dist/exceljs.min.js';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {
  sName: string;
  excelFileName: string = '';
  blobType: string = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  cols = ['JurorID', 'LastName', 'FirstName', 'StatusDescription'];
  reportCols = ['EventID', 'Title ', 'Judge Name ', 'Case Number', 'Defendant', 'Event Date', 'Total Amount', 'Jurors Daily Amount', 'Donation Amount'];
  totalPayAmount: number = 0.0;
  totalDailyAmount: number = 0.0;
  totalDonationAmunt: number = 0.0;
  reportTotalData: any = [];
  constructor() { this.sName = 'SheetTest'; }

  exportToExcel(data) {
    this.excelFileName = 'Jurorlist';
    const workbook = new Excel.Workbook();
    workbook.creator = 'Web';
    workbook.lastModifiedBy = 'Web';
    workbook.created = new Date();
    workbook.modified = new Date();
    workbook.addWorksheet(this.sName, { views: [{ state: 'frozen', ySplit: 3, xSplit: 2, activeCell: 'A1', showGridLines: true }] });
    const sheet = workbook.getWorksheet(1);
    //const head1 = ['Exported Data'];
    //sheet.addRow(head1);
    sheet.addRow('');
    sheet.getRow(1).values = this.cols;
    sheet.columns = [
      { key: 'jurorID' },
      { key: 'lastName' },
      { key: 'firstName' },      
      { key: 'statusDescription' },
    ];
    sheet.addRows(data);
    workbook.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data], { type: this.blobType });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.href = url;
      a.download = this.excelFileName;
      a.click();
    });
  }
  exportExcel(reportData, totalPayAmount, totalDailyAmount, totalDonationAmunt) {
    this.excelFileName = reportData[0].title;
    const workbook = new Excel.Workbook();
    workbook.creator = 'Web';
    workbook.lastModifiedBy = 'Web';
    workbook.created = new Date();
    workbook.modified = new Date();
    workbook.addWorksheet(this.sName, { views: [{ state: 'frozen', ySplit: 3, xSplit: 2, activeCell: 'A1', showGridLines: true }] });
    const sheet = workbook.getWorksheet(1);
    const head1 = ['Exported Data'];
    sheet.addRow(head1);
    sheet.addRow('');
    sheet.getRow(3).values = this.reportCols;
    sheet.columns = [
      { key: 'eventID' },
      { key: 'title' },
      { key: 'judgeName' },
      { key: 'caseNumber' },
      { key: 'defendant' },
      { key: 'startDateTime' },
      { key: 'totalAmount' },
      { key: 'totalDailyPayAmount' },
      { key: 'totalDonationAmount' },
    ];
    sheet.addRows(reportData);
    sheet.addRow('');

    sheet.columns = [
      { key: '' },
      { key: '' },
      { key: '' },
      { key: '' },
      { key: '' },
      { key: 'Total' },
      { key: 'totalPayAmount' },
      { key: 'totalDailyAmount' },
      { key: 'totalDonationAmunt' }
    ];

    this.reportTotalData = [{
      Total: 'Total:',
      totalPayAmount: '$' + totalPayAmount,
      totalDailyAmount: '$' + totalDailyAmount,
      totalDonationAmunt: '$' + totalDonationAmunt
    }];

    sheet.addRows(this.reportTotalData);

    workbook.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data], { type: this.blobType });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.href = url;
      a.download = this.excelFileName;
      a.click();
    });
  }
}
