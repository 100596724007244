import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigurationService } from '../configuration.service';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EventService {
  public ObjData: any;
  private _baseURL: string;

  constructor(private _http: HttpClient, private _configuration: ConfigurationService) {
    this._baseURL = _configuration.baseURL;
  }

  getAllJurorList(model: any): Observable<any> {
    return this._http.get(this._baseURL + 'DashboardApi/EventJurorList?eventID=' + model.eventID + '&jurisdictionID=' + model.JurisdictionID)
      .pipe(catchError(this.errorHandler));
  }

  getEventDetails(model: any): Observable<any> {
    return this._http.get(this._baseURL + 'EventApi/GetEventDetail?eventID=' + model.eventID + '&jurisdictionID=' + model.JurisdictionID)
      .pipe(catchError(this.errorHandler));
  }

  getMilesOfJuror(model: any): Observable<any> {
    return this._http.get(this._baseURL + 'EventApi/GetMilesOfJuror?startAddr=' + model.start + '&endAddr=' + model.end)
      .pipe(catchError(this.errorHandler));
  }

  getJurisdictionDetails(model: any): Observable<any> {
    return this._http.get(this._baseURL + 'EventApi/GetJurisdictionDetails?jurisdictionID=' + model)
      .pipe(catchError(this.errorHandler));
  }

  updateEventDetails(model: any): Observable<any> {
    return this._http.post(this._baseURL + 'EventApi/UpdateEventDetails', model)
      .pipe(catchError(this.errorHandler));
  }

  deleteEvent(model: any): Observable<any> {
    return this._http.delete(this._baseURL + 'EventApi/DeleteEvent?eventID=' + model.EventID + '&loggedInUser=' + model.LoggedInUser)
      .pipe(catchError(this.errorHandler));
  }

  jurorCount(model: any): Observable<any> {
    return this._http.get(this._baseURL + 'BatchEventApi/JurorsLeftCount?jurisdictionID=' + model)
      .pipe(catchError(this.errorHandler));
  }

  getAllBatchEvents(model: any): Observable<any> {
    return this._http.get(this._baseURL + 'EventApi/GetAllBatchEvent?eventID=' + model.eventID + '&jurisdictionID=' + model.JurisdictionID)
      .pipe(catchError(this.errorHandler));
  }

  generateJurors(model: any) {
    return this._http.post(this._baseURL + 'EventApi/GenerateJurors', model)
      .pipe(catchError(this.errorHandler));
  }

  getPaymentDetails(model: any): Observable<any> {
    return this._http.get(this._baseURL + 'EventApi/GetPaymentDetails?jurorID=' + model.jurorID + '&jurisdictionid=' + model.jurisdictionID + '&eventId=' + model.eventID)
      .pipe(catchError(this.errorHandler));
  }

  getJurorEvents(model: any): Observable<any> {
    return this._http.get(this._baseURL + 'EventApi/JurorEvents?JurisdictionID=' + model.jurisdictionID + '&JurorID=' + model.jurorID)
      .pipe(catchError(this.errorHandler));
  }

  getPrintCertificate(model: any): Observable<any> {
    return this._http.get(this._baseURL + 'EventApi/Getlistofdates?eventid=' + model.eventID  + '&jurorid=' + model.jurorID + '&juridictionid=' + model.jurisdictionID)
      .pipe(catchError(this.errorHandler));
  }


  getJurorProfileInfo(jurorID: any): Observable<any> {
    return this._http.get(this._baseURL + 'DashboardApi/GetJurorProfileInfo?JurorID=' + jurorID)
      .pipe(catchError(this.errorHandler));
  }

  GetQuestionnaire(model : any): Observable<any> {
    return this._http.post(this._baseURL + 'EventApi/GetQuestionnaireForAssignedJurors', model)
      .pipe(catchError(this.errorHandler));
  }
  saveJurorStatus(model: any) {
    return this._http.post(this._baseURL + 'EventApi/SetStatusAndPersonalDetails', model)
      .pipe(catchError(this.errorHandler));
  }

  updateStatus(model: any) {
    return this._http.post(this._baseURL + 'EventApi/Updatestatus', model)
      .pipe(catchError(this.errorHandler));
  }

  updateAddress(model: any) {
    return this._http.post(this._baseURL + 'EventApi/UpdateAddress', model)
      .pipe(catchError(this.errorHandler));
  }

  insertJurorPayment(model: any) {
    return this._http.post(this._baseURL + 'EventApi/InsertPayment', model)
      .pipe(catchError(this.errorHandler));
  }

  updateMultiStatus(model: any) {
    return this._http.post(this._baseURL + 'EventApi/UpdateAllStatus', model)
      .pipe(catchError(this.errorHandler));
  }

  moveToReschedule(model: any) {
    return this._http.post(this._baseURL + 'EventApi/RescheduleJurorMultiple', model)
    .pipe(catchError(this.errorHandler));
  }

  rescheduleJuror(model: any) {
    return this._http.post(this._baseURL + 'EventApi/RescheduleJuror', model)
      .pipe(catchError(this.errorHandler));
  }
  printCertificate(model: any): Observable<any> {
    return this._http.post(this._baseURL + 'EventApi/PrintCertificate', model)
      .pipe(catchError(this.errorHandler));
  }
  //printCertificate(model: any): Observable<any> {
  //  return this._http.post(this._baseURL + 'EventApi/PrintCertificate/, model.PrintJuror)
  //    .pipe(catchError(this.errorHandler));
  //}

  deleteUpdatedTemplateVersion(model: any): Observable<any> {
    return this._http.delete(this._baseURL + 'EventApi/DeleteUpdatedTemplateVersion/?updatedtemplate=' + model)
      .pipe(catchError(this.errorHandler));
  }

  printSummons(model: any): Observable<any> {
    return this._http.post(this._baseURL + 'EventApi/PrintSummons', model)
      .pipe(catchError(this.errorHandler));
  }

  printSummonsAll(model: any): Observable<any> {
    return this._http.post(this._baseURL + 'EventApi/PrintSummonsAll', model)
      .pipe(catchError(this.errorHandler));
  }
  ManageQuestionnaire(model: any): Observable<any> {
    return this._http.get(this._baseURL + 'OnboardApi/ManageQuestionaire?jurisdictionID=' + model.JurisdictionID + '&questionID=' + model.QuestionID + '&questionName=' + model.QuestionName + '&optionSetID=' + model.OptionSet_ID + '&queryType=' + model.Query_Type)
      .pipe(catchError(this.errorHandler));
  }
  SaveQuestionnaire(model: any): Observable<any> {
    return this._http.post(this._baseURL + 'OnboardApi/SaveQuestionaire', model)
      .pipe(catchError(this.errorHandler));
  }	
  sendJurorDataToEmail(model: any) {
    return this._http.post(this._baseURL + 'JurorApi/SendJurorDataToEmail?jurorAccountID='+ model.JurorID, model.JurorID)
      .pipe(catchError(this.errorHandler));    
  }


  errorHandler(error: Response) {
    return Observable.throw(error);
  }

}
