/// <reference types="@types/googlemaps"
import { Component, OnInit, NgZone } from '@angular/core';
import { JurorDataService } from './jurordata.service';
import { NgForm, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Router, ActivatedRoute, Data } from '@angular/router';
import { ConfigurationService } from '../configuration.service';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';
declare var $: any;
import * as $ from "jquery";
@Component({
  selector: 'jurorPortal',
  templateUrl: './jurordata.component.html',
  styleUrls: ['./jurordata.component.css']
})
export class JurorDataComponent implements OnInit {
showJurorBasicDetails:boolean = true;
showJurorQues:boolean = false;
JurorName: string; firstName; lastName;
chosenPlace;
Address:string;
City:string;
State:string;
Zipcode:number;
currentStatus: string;
JurorID: string;
jurorAccountNo;
emailPattern; phonePattern; selected; JurisdictionID; result;
  allQuestionnaireSelected = true; age; felony; english; citizen; civil; resident;
  felonyBtn: boolean = true;
excuseRequestedDate;
excuseReason; updateSuccessMessage; updateErrorMessage; infoTextAlert;
email; workPhone; homePhone; jurorAddressInfo;  
  selectedStatus : any ;
  jurorStatusInfo; eventID; JurorAccountID; editaddress; JurisdictionName;
  FileInfo: any;
  jurorStatus: boolean = false;
  excusedReasonFile: any = [];   
  filename: any;  
  fileSize: any;
  OptionsList: any = [];
  listNames: any;
  questionValues: any = [];
  QuestionsList: any = [];
  Ques_list: any = [];
  questionnaireList: any = [];
  optionSetList: any = [];
  DistinctQuesList: any = [];
  jurorAccountID: any;
  optionListLength;  
  toDos: any = [];
  showNone: boolean = false;
  optionsListLength;
  country='';
  
 constructor(private _fb: FormBuilder, private _avRoute: ActivatedRoute, private _router: Router,
    private _JurorService: JurorDataService, private _configuration: ConfigurationService,
   private _sanitizer: DomSanitizer, private _zone: NgZone, private _http: HttpClient)
 {
   this.editaddress = false;
   this.emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
   this.phonePattern = /^[(]{0,1}[0-9]{3}[)\.\- ]{0,1}[0-9]{3}[\.\- ]{0,1}[0-9]{4}$/;
   this.selected = []; 
   this.result = [];
   this.age = "1";
   this.felony = "0";
   this.english = "1";
   this.citizen = "1";
   this.civil = "0";
   this.resident = "1";
   this.selectedStatus = "Potential"
}   
  ngOnInit() {
    if (localStorage.getItem("jurorAccountID") == "" || localStorage.getItem("jurorAccountID") == null) {
      this._router.navigate(['/']);
    }
    else {
      this.jurorAccountNo = localStorage.getItem("jurorAccountID");
      this.getJurorLoginDetails(this.jurorAccountNo);
    } 
  }
 getJurorLoginDetails(jurorAccountNo) {
    const dataParams = {
      jurorAccountID: jurorAccountNo      
    }
   this._JurorService.getJurorDetails(dataParams).subscribe(data => {
     if (data.jurorBasicInfo != null) {
       var result = data.jurorBasicInfo;
       this.JurisdictionID = result.jurisdictionId;
       this.JurorAccountID = result.jurorAccountID;
       this.JurisdictionName = result.jurisdictionName;
       this.JurorName = result.firstName +" "+ result.lastName;
       this.firstName = result.firstName;
       this.lastName = result.lastName;
       this.Address = result.residenceAddress;       
       this.City = result.residenceCity;
       this.State = result.residenceState;
       this.Zipcode = result.residenceZipCode;
       this.eventID = result.eventID;
       this.country = result.county;
       this.currentStatus = "Summons Issued";
     }
     else {
       var errorMsg = data["errorMessage"];
       alert(errorMsg);
     }
   });
  }
  
  PhonePatternCheck(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    else {
      if (event.currentTarget.name == 'homephone') {
        let eles = ((document.getElementById("homephone") as HTMLInputElement).value);
        if (eles != "") {
          let ele = eles.split("-").join("");    // Remove dash (-) if mistakenly entered.
          if (((ele).length) <= 8) {
            let finalVal = (ele).match(/.{1,3}/g).join("-");
            ((document.getElementById("homephone") as HTMLInputElement).value) = finalVal;
          }          
        }
      }     
    //  docu
      else if (event.currentTarget.name == 'workphone') {
        let eles = ((document.getElementById("workphone") as HTMLInputElement).value);
        if (eles != "") {
          let ele = eles.split("-").join("");    // Remove dash (-) if mistakenly entered.
          if (((ele).length) <= 8) {
            let finalVal = (ele).match(/.{1,3}/g).join("-");
            ((document.getElementById("workphone") as HTMLInputElement).value) = finalVal;
          }
         
        }

      }

    }
  }
 
gotoQuestionnaire(email, homePhone, workPhone)
{  
  this.felonyBtn = false;
  this.showJurorBasicDetails = false;
  this.showJurorQues = true;
  this.email = email;
  this.homePhone = homePhone;
  this.workPhone = workPhone;
  this.GetQuestionnaireData(parseInt(this.JurisdictionID));
}
backToBasicDetails()
{
   this.showJurorBasicDetails = true;
   this.showJurorQues = false;
  }
 selectAllQuestionnaire() {
    if (this.allQuestionnaireSelected) {
      this.age = "1";
      this.felony = "0";
      this.english = "1";
      this.citizen = "1";
      this.civil = "0";
      this.resident = "1";

    } else {
      this.age = "";
      this.felony = "";
      this.english = "";
      this.citizen = "";
      this.civil = "";
      this.resident = "";

    }
  }

  isCheckQuestionarie(){
    if(this.selectedStatus === 'Potential'){
    if(((this.age == "1" || this.age == "0") && (this.felony == "0" || this.felony == "1") && (this.english == "1" || this.english == "0") 
    && (this.citizen == "1" || this.citizen == "0") && (this.civil == "1" || this.civil == "0") && (this.resident == "1" || this.resident == "0"))){
      return true;
    }
    else{
      return false;
    }
  }
    else{
      return true;
    }
  }
  hasFelonyChecked(status) {
    if (status === '1') {
      this.felonyBtn = true;
      this.civil = '';
    } else {
      this.felonyBtn = false;
      this.civil = '0';
    }
    this.isCheckQuestionarie();
  }

  civilChecked() {
    this.felonyBtn = false;
  }

  civilBtnchecked() {
    if (this.felony == '1') {
      return false
    }
    else {
      return true
    }
  }
  confirm(requestedDate, reason) {
    this.excuseRequestedDate = requestedDate;
    this.excuseReason = reason;
    //this.selectedStatus = "Rescheduled/Excused";
  }
  getFileDetails(e) {
    this.excusedReasonFile = [];
    this.filename = "";
    this.fileSize = 0;    
    if (e.target.files[0].size > 5242880) {
      alert("File size shouldn't exceed 5Mb");
      e.target.files = null;
      return false;
    }
    else {
      this.excusedReasonFile = e.target.files;
      this.filename = this.excusedReasonFile[0].name;
     // this.fileSize = this.excusedReasonFile[0].size;
    }
  }

  
  confirmSaveDetails(questionnaireList)
    {   
      let statusID;
      let civilRights;
      if (this.civil === "" || this.civil === undefined || this.civil === "0") {
        civilRights = "0";
      } else {
        civilRights = "1";
      }
      // if (this.age == "0" || this.english == "0" || this.citizen == "0" || this.resident == "0" || this.felony =="1"
      //        || civilRights == "0")  {
      //   this.selectedStatus = 'PermanentlyExcused';
      // }
      // if (this.age == "1" && this.english == "1" && this.citizen == "1" && this.resident == "1" 
      //         && this.felony =="1"  && civilRights == "1")  {
      //   this.selectedStatus = 'Potential';
      // } 
      // else  {
      //   this.selectedStatus = 'Potential';
      // } 
      if (this.selectedStatus == null || this.selectedStatus == "") {
        if ((this.age == "0" || this.english == "0" || this.citizen == "0" || this.resident == "0" || this.felony == "1"
        ) && civilRights == "0" || civilRights == "1") {
          this.selectedStatus = 'PermanentlyExcused';
        }
        if ((this.age == "1" && this.english == "1" && this.citizen == "1" && this.resident == "1"
          && this.felony == "1" && civilRights == "1") || civilRights == "0") {
          this.selectedStatus = 'Potential';
        }
      }
   
    if (this.selectedStatus === 'Potential') {
      statusID = 20;
    } else if (this.selectedStatus === 'Rescheduled') {
      statusID = 12;
    } else if (this.selectedStatus === 'PermanentlyExcused') {
      statusID = 13;
    } else {
      statusID = 11;
    }    
    var age = false;
    var citizen = false;
    var resident = false;
    var english= false;
    var felony = false;
    var civilRightsVal = false;

    if(this.age=="1")
    {
       age = true;
    }   
    if(this.citizen=="1")
    {
       citizen = true;
    }
    if (this.resident=="1")
    {
      resident = true;
    }
    if(this.english == "1")
    {
      english = true;
    }
    if(this.felony == "1")
    {
       felony = true;
    } 
    if(civilRights == "1")
    {
      civilRightsVal = true;
    }

    this.jurorStatusInfo = {
      JurorID: this.JurorAccountID,
      FirstName: this.firstName,
      LastName: this.lastName,
      Email: this.email,
      WorkPhone: this.workPhone,
      HomePhone: this.selected.phone,
      StatusId: statusID,
      EventID: this.eventID,
      EventIDs: [this.eventID],
      ResidenceAddress: this.Address,
      ResidenceCity: this.City,
      ResidenceState: this.State,
      ResidenceZipCode: this.Zipcode,
      County: this.country,//this.selected.county,
      ExcuseRequestedDate: this.excuseRequestedDate,
      ExcuseReason: this.excuseReason,
      IsOfEligibleAge: age,
      IsACitizenOfUS: citizen,
      IsAResidentOfCounty: resident,
      IsAbleToCommunicateInEnglish: english,
      HasCommitedFelony: felony,
      CivilRightsRestored: civilRightsVal,
      JurisdictionId: this.JurisdictionID,
      Loggedinuser: null,
      UploadedFileName: this.filename,
      JurisdictionName: this.JurisdictionName      
    };
   
    this._JurorService.saveJurorStatus(this.jurorStatusInfo).subscribe(data => {
      if (data['success'] === true) {
        this.SaveQuestionnaireValue();
        //alert("Data saved sucessfully!");
       this.sendJurorInfoToEmail(this.jurorStatusInfo);
        //localStorage.clear();
        //this._router.navigate(['/']);        
      }
    });
  }
  sendJurorInfoToEmail(dataParams) {
    const frmData = new FormData();
    frmData.append("fileUpload", this.excusedReasonFile[0]);
    var uploadUrl = this._configuration.baseURL + "JurorApi/SendJurorDataToEmail?jurorAccountID=" + dataParams.JurorID ;
    this._http.post(uploadUrl, frmData).subscribe(data => {
      var response = data;
     // this._JurorService.sendJurorDataToEmail(dataParams).subscribe(data => {
    });
  }
  cancelJurorUpdate() {
    this.editaddress = false;
    this.selectedStatus = "Potential";
    this.excuseRequestedDate = '';
    this.excuseReason = '';
    this.selectAllQuestionnaire();
  }
  updateResidenceAddress(chosenPlace) {
  //  if (this.Address == undefined || this.Address == '') {
      if (chosenPlace == undefined || chosenPlace == '') {
        this.infoTextAlert = 'Please enter new address';
        this.updateSuccessMessage = true;
        setTimeout(() => { this.updateSuccessMessage = false; }, 4000);
      } else {

        chosenPlace = chosenPlace.split(',');

        if (chosenPlace[1] === undefined || chosenPlace[1] === '') {
          this.infoTextAlert = 'Please enter a valid address.(ex: Address,city,state,zipcode)';
          this.chosenPlace = '';
          this.updateErrorMessage = true;
          setTimeout(() => { this.updateErrorMessage = false; }, 4000);
        } else if (chosenPlace[2] === undefined || chosenPlace[2] === '') {
          this.infoTextAlert = 'Please enter a valid address.(ex: Address,city,state,zipcode)';
          this.chosenPlace = '';
          this.updateErrorMessage = true;
          setTimeout(() => { this.updateErrorMessage = false; }, 4000);
        }
        else if (chosenPlace[3] === undefined || chosenPlace[3] === '') {
          this.infoTextAlert = 'Please enter a valid address.(ex: Address,city,state,zipcode)';
          this.chosenPlace = '';
          this.updateErrorMessage = true;
          setTimeout(() => { this.updateErrorMessage = false; }, 4000);
        }else {
          const ResidenceAddress = chosenPlace[0];
          const City = chosenPlace[1];
          const State = chosenPlace[2];
          const Zipcode = chosenPlace[3];

          this.jurorAddressInfo = {
            JurorID: this.jurorAccountNo,
            ResidenceAddress: ResidenceAddress,
            ResidenceState: State,
            ResidenceCity: City,
            ResidenceZipCode: Zipcode
          };
          this._JurorService.updateAddress(this.jurorAddressInfo).subscribe(data => {
            if (data['success'] == true) {
              this.chosenPlace = '';
              this.Address = ResidenceAddress;
              this.City = City;
              this.State = State;
              this.Zipcode = Zipcode;
              this.editaddress = false;
            }
          });
        }
      }
   // } else {
      //chosenPlace = this.Address.split(',');

    //  const ResidenceAddress = chosenPlace[0];
    //  const City = this.City;
    //  const State = this.State;

    //  this.jurorAddressInfo = {
    //    JurorID: this.jurorAccountNo,
    //    ResidenceAddress: ResidenceAddress,
    //    ResidenceState: State,
    //    ResidenceCity: City
    //  };

    //  this._JurorService.updateAddress(this.jurorAddressInfo).subscribe(data => {
    //    if (data['success'] == true) {
    //      this.chosenPlace = '';
    //      this.Address = ResidenceAddress;
    //      this.City = City;
    //      this.State = State;         
    //      this.editaddress = false;
    //    }

    //  });
    //}
  }

  editaddressClick() {
    this.editaddress = true;
    this.chosenPlace = this.Address.trim() + ',' + this.City.trim() + ',' + this.State.trim() + ',' + this.Zipcode;
  }

  removeaddress() {
    this.chosenPlace = '';
    this.editaddress = false;
  }

  setAddress(addrObj) {
    //We are wrapping this in a NgZone to reflect the changes to the object in the DOM.
    this._zone.run(() => {
      this.Address = addrObj.formatted_address;
      this.City = addrObj.locality;
      this.State = addrObj.admin_area_l1;
    });
  }
  GetQuestionnaireData(jurisID) {
    var questionnaireDetails = {
      QuestionID: 0,
      JurisdictionID: jurisID,
      QuestionName: null,
      OptionSet_ID: 0,
      Query_Type: 'GET'
    }
    this._JurorService.ManageQuestionnaire(questionnaireDetails).subscribe(data => {
      if (data.success == true && data.statusCode == 0) {
        this.questionnaireList = [];
        this.optionSetList = [];
        this.OptionsList = [];
        this.questionnaireList = data.questionnaireDTOList.filter(x => x.jurisdictionID === jurisID);
        for (var val of this.questionnaireList) {
          //$('#spanNone_' + val.questionID).hide();
          //$('#None_' + val.questionID).hide();
          this.listNames = {
            id: val.questionID,
            name: val.listNames.split('/')
          };
          this.OptionsList.push(this.listNames);
          this.optionsListLength = (this.OptionsList.length) + 1;          
        }
        this.toDos.length = this.questionnaireList.length;
      }
    });   
  }
  onChange(OptionValue, QuestionID) {
    this.questionValues = {
      QuestionaireID: QuestionID,
      Ques_Answer: OptionValue
    };
    if (OptionValue == "None" || OptionValue == "--Select--" || OptionValue == null || OptionValue == undefined) {
    }    
    else {
      this.QuestionsList.push(this.questionValues);
    }
  }
  trackByIndex(index: number, obj: any): any {
        return index;
  }
  SaveQuestionnaireValue() {
    if (this.Ques_list.length > 0) {
      this.DistinctQuesList = this.Ques_list.filter(
        (thing, i, arr) => arr.findIndex(t => t.QuestionaireID === thing.QuestionaireID) === i);
    }
    //else if (this.Ques_list.length == 0)
    //{
    //  this.DistinctQuesList = this.QuestionsList.filter(
    //    (thing, i, arr) => arr.findIndex(t => t.QuestionaireID === thing.QuestionaireID) === i);

    //}
    var quesDetails = {
      JurorID: this.jurorAccountNo,
      EventID: this.eventID,
      JurisdictionID: this.JurisdictionID,
      questionaireDTOs: this.DistinctQuesList
    }
    this._JurorService.SaveQuestionnaire(quesDetails).subscribe(res => {
      if (res.success == true && res.statusCode == 0) {
        alert("Data saved sucessfully!");
        this.sendJurorInfoToEmail(this.jurorStatusInfo);
        localStorage.clear();
        this._router.navigate(['/']); 
      }
    });
  }

  GetQuestionnaireValue(reasonslist) {
    this.Ques_list = [];
    if(this.age == '0' || this.resident == '0' || this.citizen == '0' || this.english == '0'){
      this.selectedStatus = 'PermanentlyExcused';
      }
      if(this.age == "1" && this.felony == "1" && this.english == "1" 
      && this.citizen == "1" && this.civil == "0" && this.resident == "1"){
        this.selectedStatus = 'Commited Felony';
      }
    for (var val of this.questionnaireList) {
      let optionValue = ((document.getElementById("None_" + val.questionID) as HTMLInputElement).value);
      if (optionValue != "") {
        var questionData = {
          QuestionaireID: parseInt(val.questionID),
          Ques_Answer: optionValue
        };
        this.Ques_list.push(questionData); 
      }      
    }
    if (this.QuestionsList.length > 0) {
      for (var val of this.QuestionsList) {
        var questionDropdownData = {
          QuestionaireID: parseInt(val.QuestionaireID),
          Ques_Answer: val.Ques_Answer
        };
        this.Ques_list.push(questionDropdownData); 
      }
      //this.Ques_list.push(this.QuestionsList);
    }      
  } 
   
}





