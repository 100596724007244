import { Injectable } from '@angular/core';

declare var toastr: any;
@Injectable({
  providedIn: 'root'
})
export class ToasterService {

  constructor() { }
  Success(title: String, message?: String) {
    toastr.success(title, message);
  }
  Warning(title: String, message?: String) {
    toastr.warning(title, message);
  }
  Info(title: String, message?: String) {
    toastr.info(title, message);
  }
  Error(title: String, message?: String) {
    toastr.error(title, message);
  }
}
