import { Component, OnInit } from '@angular/core';
import { DashboardServices } from '../dashboard/dashboard.service';

@Component({
  selector: 'app-dashboardsearchevent',
  templateUrl: './dashboardsearchevent.component.html',
  styleUrls: ['./dashboardsearchevent.component.css']
})
export class DashboardsearcheventComponent {
  eventsearchResult: any;
  eventsearchlength: any;
  searchTitle: any;
  key: string = 'name';
  reverse: boolean = false;
  NoResultFound: boolean = false;
  NoSearchResultFound: boolean = false;

  constructor(private _dashBoardServices: DashboardServices) {
    this.dashboardSearchEvent();
  }

  dashboardSearchEvent() {
    const searchJurorParam = this._dashBoardServices.ObjData;
    this.eventsearchlength = 0;

    if (searchJurorParam !== undefined) {
      this._dashBoardServices.SearchEvent(searchJurorParam).subscribe(data => {
        this.eventsearchResult = data.eventInfoList;
        if (this.eventsearchResult.length === 0) {
          if (searchJurorParam.SearchEvtInput === undefined || searchJurorParam.SearchEvtInput === '') {
            this.NoResultFound = true;
            this.NoSearchResultFound = false;
          } else {
            this.searchTitle = searchJurorParam.SearchEvtInput;
            this.NoResultFound = false;
            this.NoSearchResultFound = true;
          }
        } else {
          this.eventsearchlength = this.eventsearchResult.length;
          this.searchTitle = searchJurorParam.SearchEvtInput;
          this.NoResultFound = false;
          this.NoSearchResultFound = false;
        }
      });
    } else {
      this.NoResultFound = true;
      this.NoSearchResultFound = false;
    }
  }

  sortTableCols(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }
}
