import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigurationService } from '../configuration.service';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private _baseURL: string;

  constructor(private _http: HttpClient, private _configuration: ConfigurationService) {
    this._baseURL = _configuration.baseURL;
  }

 getJurorDetails(model: any): Observable<any> {
   return this._http.post(this._baseURL + 'JurorApi/GetJurorDetails?jurorAccountID=' + model.jurorAccountID,
 model)
     .pipe(catchError(this.errorHandler));
}
  getloginDetails(model: any): Observable<any> {
    return this._http.post(this._baseURL + 'LoginApi/LoginDetails', model)
      .pipe(catchError(this.errorHandler));
  }

  getIntialSettingsDetails(jurisdictionID: Number): Observable<any> {
    return this._http.get(this._baseURL + 'LoginApi/GetIntialSettingsDetails?jurisdictionID=' + jurisdictionID)
      .pipe(catchError(this.errorHandler));
  }

  getAllJurisdictions(): Observable<any> {
    return this._http.get(this._baseURL + 'LoginApi/GetAllJurisdictions')
      .pipe(catchError(this.errorHandler));
  }

  newUserRegistration(model: any): Observable<any> {
    return this._http.post(this._baseURL + 'LoginApi/NewUserRegistration', model)
      .pipe(catchError(this.errorHandler));
  }

  checkDuplicateEmail(model: any): Observable<any> {
    return this._http.get(this._baseURL + 'LoginApi/CheckDuplicateEmail?UserEmail=' + model)
      .pipe(catchError(this.errorHandler));
  }

  errorHandler(error: Response) {
    return Observable.throw(error);
  }
}
