import { Component, OnInit } from '@angular/core';
import { NgForm, FormBuilder, FormGroup, Validators, FormControl, ReactiveFormsModule } from '@angular/forms';
import { Router, ActivatedRoute, Data } from '@angular/router';
import { Observable } from 'rxjs'
import { ConfigurationService } from './../../configuration.service';
import { HttpClient } from '@angular/common/http';
import { Onboardstep1Service } from './onboardstep1.service';
import { element } from 'protractor';
declare var $: $;
import $ from "jquery";
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';

@Component({
  selector: 'app-onboardingstep1',
  templateUrl: './onboardingstep1.component.html',
  styleUrls: ['./onboardingstep1.component.css']
})
export class Onboardingstep1Component implements OnInit {
  public appUrl = this.configurationservice.baseURL;
  isCollapsed = true;
  dataParams;
  JurisdictionID
  firstStepLoaderFlag: boolean = false;
  showData: boolean = false;
  StateNameResults: any[] = [];
  CountyNameResults: any[] = [];
  CityNameResults;
  SelectedStateID: number = 0;
  SelectedCountyID: number = 0;
  SelectedCityID: number = 0;
  selectedState; selectedCounty; selectedCity;
  state: string = 'State'; county: string = 'County'; city: string = 'City';
  formstep1; duplicateJurisdiction: boolean = false;
  EmailId: string;/* = localStorage.getItem('UserName')*/
  showStepOne: boolean = true;
  NewJurisdictionID: number = 0;
  step2Data;
  dataValues;
  updatedJurisID: number = 0;
  jurisdName;
  jurisdcode;
  jurisdAddress;
  jurisdZipcode;
  jurisdPhone;
  jurisdFax;
  jurisdEmail;
  element: string;
  finalVal: string;
  emailDuplicate: boolean;
  isLoading: boolean;
  constructor(private _fb: FormBuilder, private _avRoute: ActivatedRoute,
    public http: HttpClient, private _router: Router,
    private _onboardingService: Onboardstep1Service,
    private configurationservice: ConfigurationService,
    private adalSvc: MsAdalAngular6Service) {
    this.firstStepLoaderFlag = false;
    this.updatedJurisID = this._avRoute.snapshot.params['id'];
    this.EmailId = this.adalSvc.LoggedInUserName;
  }
  getJurisData() {
    //this.isLoading = true;
  }
  ngOnInit() {

    this.getAllStates();
    this.formstep1 = this._fb.group({
      JuriName: new FormControl('', [Validators.required]),
      JuriCode: new FormControl('', [Validators.required]),
      JuriAddress: new FormControl('', [Validators.required]),
      JuriZipCode: new FormControl('', [Validators.required, Validators.maxLength(5), Validators.minLength(5), Validators.pattern(/^[0-9]{5,5}(\.[0-9]{5,5})?$/)]),
      JuriPhone: new FormControl('', [Validators.required, Validators.maxLength(12), Validators.minLength(10)]),
      JuriFax: new FormControl('', [Validators.required, Validators.maxLength(12), Validators.minLength(10)]),
      JuriEmail: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')])
    });
    if (this.updatedJurisID == 0||this.updatedJurisID == undefined||this.updatedJurisID == null) {
      this.updatedJurisID = 0;
    }
    if (this.updatedJurisID != 0) {
      this.isLoading = true;
      this._onboardingService.GetJurisDetails(this.updatedJurisID).subscribe(data => {
        if (data.success == true) {
          this.isLoading = false;
          this.jurisdName = data.listJurisdictions[0].jurisdictionName;
          this.jurisdcode = data.listJurisdictions[0].jurisdictionCode;
          this.jurisdAddress = data.listJurisdictions[0].jurisdictionAddress;
          this.jurisdZipcode = data.listJurisdictions[0].jurisdictionZipCode;
          this.jurisdPhone = data.listJurisdictions[0].jurisdictionPhone;
          this.jurisdFax = data.listJurisdictions[0].jurisdictionFax;
          this.jurisdEmail = data.listJurisdictions[0].jurisdictionEmail;
          this.selectedState = data.listJurisdictions[0].stateName;
          this.SelectedStateID = data.listJurisdictions[0].stateId;
          this.SelectedCountyID = data.listJurisdictions[0].countyId;
          this.selectedCounty = data.listJurisdictions[0].countyName;
          this.SelectedCityID = data.listJurisdictions[0].cityId;
          this.selectedCity = data.listJurisdictions[0].cityName;
          this.viewCounty(this.SelectedStateID, this.selectedState);
          this.viewCity(this.SelectedCountyID, this.selectedCounty);
        }
        // this.isLoading = false;
      });
    }
  }

  getAllStates() {
    this._onboardingService.GetAllStates().subscribe(data => {
      this.StateNameResults = data.stateInfoList;
    });
  }

  viewCounty(stateId, stateName) {
    this.selectedState = stateName;
    if (stateId == 0)
      this.SelectedStateID = null;
    else
      this.SelectedStateID = stateId;
    this._onboardingService.GetAllCounty(this.SelectedStateID).subscribe(data => {
      this.CountyNameResults = data.countryInfoList;

      if (this.CountyNameResults.length !== 0) {
        this.showData = true;
      } else {
        this.showData = false;
      }
      $('.dropdown-inverse li > a').click(function (e) {
        $('.state').text(this.innerHTML);
      });
    });
  }

  viewCity(countryid, countryName) {
    this.selectedCounty = countryName;
    if (countryid == 0) {
      this.SelectedCountyID = null;
    }
    else {
      this.SelectedCountyID = countryid;
      this._onboardingService.GetAllCity(this.SelectedCountyID).subscribe(data => {
        this.CityNameResults = data.cityInfoList;

        if (this.CityNameResults.length !== 0) {    // Apply the dynamic height to the dropdown
          this.showData = true;
        } else {
          this.showData = false;
        }
        $('.dropdown-inverse li > a').click(function (e) {
          $('.County').text(this.innerHTML);
        });
      });
    }
  }

  onChangeCity(cityid, cityName) {
    this.selectedCity = cityName;
    if (cityid == 0) {
      this.SelectedCityID = null;
    }
    else {
      this.SelectedCityID = cityid;
    }
  }

  checkDupJurisdiction() {
    const jurisdictionDetail = {
      StateId: this.SelectedStateID,
      CityId: this.SelectedCityID,
      CountyId: this.SelectedCountyID,
      JurisdictionCode: this.jurisdcode
    }

    this._onboardingService.checkDuplicateJurisdictionCode(jurisdictionDetail).subscribe(data => {
      if (data == 1) {
        this.duplicateJurisdiction = true;
      } else {
        this.duplicateJurisdiction = false;
      }
    });
  }

  NewJursubmit(step1Data) {
    if (!this.formstep1.valid || this.SelectedStateID == 0 || this.SelectedCountyID == 0 || this.SelectedCityID == 0) {
      return false;
    }
    else if (this.updatedJurisID == 0) {
      this.JurisdictionID = this.updatedJurisID;
      this.dataParams = {
        JurisdictionId: this.JurisdictionID,
        JurisdictionName: step1Data.JuriName,
        JurisdictionCode: step1Data.JuriCode,
        JurisdictionAddress: step1Data.JuriAddress,
        JurisdictionZipCode: step1Data.JuriZipCode,
        JurisdictionPhone: step1Data.JuriPhone,
        JurisdictionFax: step1Data.JuriFax,
        JurisdictionEmail: step1Data.JuriEmail,
        CountyID: this.SelectedCountyID,
        CityID: this.SelectedCityID,
        StateID: this.SelectedStateID,
        LoggedinUser: this.EmailId,
        RequestType: "INSERT"
      }

    }
    else {
      this.JurisdictionID = this.updatedJurisID;
      this.dataParams = {
        JurisdictionId: this.JurisdictionID,
        JurisdictionName: step1Data.JuriName,
        JurisdictionCode: step1Data.JuriCode,
        JurisdictionAddress: step1Data.JuriAddress,
        JurisdictionZipCode: step1Data.JuriZipCode,
        JurisdictionPhone: step1Data.JuriPhone,
        JurisdictionFax: step1Data.JuriFax,
        JurisdictionEmail: step1Data.JuriEmail,
        CountyID: this.SelectedCountyID,
        CityID: this.SelectedCityID,
        StateID: this.SelectedStateID,
        LoggedinUser: this.EmailId,
        RequestType: "UPDATE"
      }
    }
    this._onboardingService.AddNewJurisdiction(this.dataParams).subscribe(data => {
      if (data.success == true) {
        if (data.countrec != 0) {
          this.NewJurisdictionID = data.countrec;
          //this.showStepOne = false;  
            this._router.navigate(['/home/onboarding-step2', this.NewJurisdictionID]);
          // this._router.navigate(['/home/onboarding-step2']);
          //this.ShowStepTwo = true;
        }
      }
    });
  }
  addHyphen(event) {  
    this.element = ((document.getElementById(event.currentTarget.id) as HTMLInputElement).value);
    if (this.element != "") {
      this.element = this.element.split('-').join('');    // Remove dash (-) if mistakenly entered.
      if (((this.element).length) <= 8) {
        this.finalVal = (this.element).match(/.{1,3}/g).join('-');
      }
      if (((this.element).length) >= 8) {
        this.finalVal = (this.element).match(/.{13,13}/g).join('');
      }
      ((document.getElementById(event.currentTarget.id) as HTMLInputElement).value) = this.finalVal;
        //document.getElementById(element.id).value = finalVal;
    }
  }  
  checkDuplicateEmail(userEmail) {
    if (userEmail == "" || userEmail == undefined) {
    } else {
      this._onboardingService.checkDuplicateEmail(userEmail).subscribe(data => {
        if (data != 0) {
          this.emailDuplicate = true;
        }
        else {
          this.emailDuplicate = false;
        }
      });
    }
  }



}
