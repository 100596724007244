import { Component, OnInit } from "@angular/core";
import {
  NgForm,
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from "@angular/forms";
import { Router, ActivatedRoute, Data } from "@angular/router";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { ConfigurationService } from "src/app/configuration.service";
import { Onboardingstep3Service } from "./onboardingstep3.service";
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
@Component({
  selector: "app-onboardingstep3",
  templateUrl: "./onboardingstep3.component.html",
  styleUrls: ["./onboardingstep3.component.css"]
})
export class Onboardingstep3Component implements OnInit {
  updatedJurisID;
  index;
  dataValues;
  rowData;
  setp3Data: any = [];
  StagingTableName;
  CreateTableScript;
  identity: number = 1;
  TableColumnNameData;
  showDelete: boolean = false;
  selectedItemsList: any = [];
  selectedItems;
  ExcelColumnNames;
  SelectedColumns;
  excelColumnNames: any = [];
  isLoading: boolean = false;
  checkedList: any = [];
  emailID: string = "";
  tblecolumn: any;
  msg: string;
  isDuplicate: boolean = false;

  constructor(
    private _fb: FormBuilder,
    private _avRoute: ActivatedRoute,
    public http: HttpClient,
    private _router: Router,
    private configurationservice: ConfigurationService,
    private _onboardingstep3Service: Onboardingstep3Service,
    private adalSvc: MsAdalAngular6Service
  ) {
    this.rowData = [
      {
        Tablecol: "",
        Excelcol: "",
        Wherecol: "",
        ExcelColumnOrder: this.identity,
        tableColumnName: "",
        ExcelColumnName: "",
        Istarget: false,
        IsTargetTableColumn: false,
        WhereCondition: ""
      }
    ];

    //Dropdown Menu for the Table Column Name
    this.TableColumnNameData = [
      "FirstName",
      "MiddleName",
      "LastName",
      "ResidenceAddress",
      "City",
      "State",
      "ZipCode",
      "ZipCodeExt",
      "County",
      "EmailID",
      "SecondEmailId",
      "HomePhone",
      "WorkPhone",
      "CellPhone",
      "DateOfBirth",
      "ReferenceId",
      "UpdateReferenceId",
      "Fileid"
    ];
  }
  ngOnInit() {
    this.updatedJurisID = this._avRoute.snapshot.params["id"];
    if (this.updatedJurisID != 0 || this.updatedJurisID != null) {
      this.isLoading = true;
      this.index = 0;
      this.dataValues = {
        jurisdictionID: this.updatedJurisID,
        stepNo: 3
      };
      this._onboardingstep3Service
        .GetJurisdictionDetails(this.dataValues)
        .subscribe(response => {
          if (response.listJurisdictions.length != 0) {
            for (var i = 0; i < response.listJurisdictions.length; i++) {
              if (response.listJurisdictions[i].excelColumnName != 0) {
                this.setp3Data.push(response.listJurisdictions[i]);
              }
            }
            this.rowData = this.setp3Data;
          }
          this.isLoading = false;
        });
    }
    this.emailID = this.adalSvc.LoggedInUserName;//localStorage.getItem("UserName");
  }
  addRow() {
    this.identity++;
    //console.log(this.rowData);
    this.rowData.push({
      Tablecol: "",
      Excelcol: "",
      Wherecol: "",
      ExcelColumnOrder: this.identity,
      tableColumnName: "",
      ExcelColumnName: "",
      Istarget: true,
      IsTargetTableColumn: false,
      WhereCondition: ""
    });
    this.index = this.rowData.length;
  }
  deleteRow() {
    this.showDelete = !this.showDelete;
  }
  //Delete the selected row from the Step 3
  remove(index) {
    this.rowData.splice(index, 1);
  }
  backtoStepTwo() {
    this._router.navigate(["/home/onboarding-step2", this.updatedJurisID]);
  }
  saveAll() {
    console.log(this.setp3Data);
    if (this.setp3Data != null) {
      // update
      var obj = this.rowData;
      this.dataValues = {
        JurisdictionID: this.updatedJurisID,
        UserID: this.emailID,
        LstTableScripts: obj,
        RequestType: "UPDATE"
      };
    } else {
      //insert
      var obj = this.rowData;
      this.dataValues = {
        JurisdictionID: this.updatedJurisID,
        UserID: this.emailID,
        LstTableScripts: obj,
        RequestType: "INSERT"
      };
    }
    this._onboardingstep3Service.SaveOBJD3(this.dataValues).subscribe(data => {
      if (data.success == true) {
        this._router.navigate(["/home/onboarding-step4", this.updatedJurisID]);
      }
    });
  }
  tblColumnNameChange(data) {
    this.tblecolumn = this.rowData.filter(x => x.tableColumnName == data);
    console.log("isavailable" + this.tblecolumn.length);
    if (this.tblecolumn.length == 2) {
      this.msg = data + " is already selected";
      this.isDuplicate = true;
      setTimeout(() => {
        this.msg = "";
      }, 4000);
    }else{
        this.isDuplicate = false;
    }    
  }
}
